import { getSessionId } from '../apis/imageAPI';

export default async function beginUpload(event, currentOrganization = '') {
  event.preventDefault();
  const session_id = await getSessionId(currentOrganization);

  if (session_id) {
    const genlink = `baseline:${process.env.REACT_APP_VISOR_API_BASE_URL}+files+${session_id}`;
    location.href = genlink;
  }
}
