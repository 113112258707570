import React from 'react';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import ToolButton from '../../../../../components/buttons/ToolButton';

export default function MarkerVisible({ value = true, onClick }) {
  return (
    <ToolButton
      id={'tool-marker-visible'}
      tooltip={`Markers ${(value && 'Visible') || 'Hidden'}`}
      label="Visibility"
      onClick={() => onClick?.()}
      active={!value}
      Icon={() => (value ? <VisibilityOutlined /> : <VisibilityOffOutlined />)}
    />
  );
}
