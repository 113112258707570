import React from 'react';
import { useBaseline } from '../../../context';

import { Typography } from '@common-ui/ui';

/**
 * Select all images button
 * @returns {JSX.Element}
 */
export default function SelectAll() {
  const {
    baselineImages: { selectAllImages },
  } = useBaseline();

  const handleClick = async () => {
    selectAllImages();
  };

  return (
    <Typography sx={styles.text} onClick={handleClick}>
      Select All
    </Typography>
  );
}

const styles = {
  text: (theme) => ({
    color: theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.light,
      transition: '0.3s',
    },
  }),
};
