import React from 'react';
import { EntryAction } from '../../lib/enum/EntryActionEnum';
import { Edit, DeleteOutline, FolderOpen, InsertChart } from '@mui/icons-material';

const menuItems = [
  {
    id: EntryAction.OPEN_FOLDER,
    name: 'Open Folder',
    icon: <img src="/folder_open.svg" />,
  },
  {
    id: EntryAction.OPEN_ASSET,
    name: 'Open Asset',
    icon: <img src="/folder_open.svg" />,
  },
  {
    id: EntryAction.ADD_FOLDER,
    name: 'Add Folder',
    icon: <FolderOpen />,
  },
  {
    id: EntryAction.ADD_ASSET,
    name: 'Add Asset',
    icon: <InsertChart />,
  },
  {
    id: EntryAction.ADD_TEST_POINT,
    name: 'Add Test Point',
    icon: <img src="/addTestPoint.svg" />,
  },
  {
    id: EntryAction.RENAME,
    name: 'Rename',
    icon: <Edit />,
  },
  {
    id: EntryAction.DELETE,
    name: 'Delete',
    icon: <DeleteOutline />,
  },
];

export default menuItems;
