import React from 'react';

import {
  FieldBackgroundTemperature,
  FieldEmissivity,
  FieldPalette,
  FieldTransmission,
} from './Fields';

import { useSettings, settingsKeys } from '@fluke/irviewer';
import { Typography } from '@common-ui/ui';
import Status from './Status';

export default function EditorTools({ settings = {}, onChange }) {
  const [localSettings, handleUpdate] = useSettings(settings, onChange);

  return (
    <div style={styles.fields}>
      <FieldPalette
        value={localSettings[settingsKeys.PALETTE_SCHEME]}
        paletteDisplayData={settings['paletteDisplayData']}
        onChange={(value) => handleUpdate({ [settingsKeys.PALETTE_SCHEME]: value })}
      />
      <FieldEmissivity
        value={localSettings[settingsKeys.EMISSIVITY]}
        onChange={(value) => handleUpdate({ [settingsKeys.EMISSIVITY]: value })}
      />
      <FieldTransmission
        value={localSettings[settingsKeys.TRANSMISSION]}
        onChange={(value) => handleUpdate({ [settingsKeys.TRANSMISSION]: value })}
      />
      <FieldBackgroundTemperature
        value={localSettings[settingsKeys.BACKGROUND_TEMP]}
        min={localSettings.minDisplayableTempC ?? 0}
        max={localSettings.maxDisplayableTempC ?? 100}
        temperatureUnit={localSettings[settingsKeys.TEMPERATURE_UNITS]}
        onChange={(value) => handleUpdate({ [settingsKeys.BACKGROUND_TEMP]: value })}
      />
      <Typography>Status</Typography>
      <Status
        value={settings[settingsKeys.STATUS]}
        onChange={(value) => handleUpdate({ [settingsKeys.STATUS]: value })}
      />
    </div>
  );
}

const styles = {
  fields: {
    paddingTop: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
};
