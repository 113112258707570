import { getFromLocalStorage } from '../../apis/localStorageAPI';
import { RouterPathEnum } from '../../enums';
import { baselineStoreEnum } from './enums';

/**
 * Removes the specified keys from the given object.
 *
 * @param {Object} obj - The object from which keys will be removed.
 * @param {Array<string>} keysToRemove - An array of keys to be removed from the object.
 * @returns {Object} - A new object with the specified keys removed.
 */
function removeKeys(obj, keysToRemove) {
  const newObj = { ...obj };
  keysToRemove.forEach((key) => {
    delete newObj[key];
  });
  return newObj;
}

/**
 * Compares two query result objects after removing specified keys.
 *
 * @param {Object} [prev={}] - The previous query result object. Defaults to an empty object.
 * @param {Object} [next={}] - The next query result object. Defaults to an empty object.
 * @returns {boolean} - Returns true if the filtered objects are equal, false otherwise.
 */
export function areQueryResultsEqual(prev = {}, next = {}) {
  const keysToRemove = ['elapsed', 'search_id'];

  // Remove the specified keys from both previous and next results
  const prevFiltered = removeKeys(prev, keysToRemove);
  const nextFiltered = removeKeys(next, keysToRemove);

  // Compare the filtered objects
  return JSON.stringify(prevFiltered) === JSON.stringify(nextFiltered);
}

export function isSignedIn(isInitialized = false) {
  const sessionData = getFromLocalStorage(baselineStoreEnum.SESSION);

  return (
    isInitialized === false &&
    sessionData &&
    typeof sessionData === 'object' &&
    Object.keys(sessionData).length > 0
  );
}

/**
 * Validates the structure of a JSON Web Token (JWT).
 *
 * This function checks whether a given token is in the correct JWT format.
 * The token is considered valid if it consists of three parts separated by dots
 * (.), where each part contains Base64 URL-encoded characters. The function can
 * operate in two modes:
 * - **Strict mode**: The token must have specific prefixes for the header and
 *   payload (starting with "eyJ").
 * - **Loose mode**: The token can contain any valid Base64 URL-encoded string.
 *
 * @param {string} token - The JWT token to validate.
 * @param {boolean} [strict=false] - If true, enforces strict validation with
 *   expected prefixes. If false, allows any valid Base64 URL-encoded strings.
 * @returns {boolean} Returns true if the token is valid, otherwise false.
 */
export function isValidJWT(token, strict = false) {
  const jwtRegex = strict
    ? /^(eyJ[a-zA-Z0-9-_]+)\.(eyJ[a-zA-Z0-9-_]+)\.(eyJ[a-zA-Z0-9-_]+)$/
    : /^[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*\.[A-Za-z0-9-_]*$/;

  // Test the token against the regex
  return jwtRegex.test(token);
}

export function checkIfAuthenticated(callback) {
  // Parse the auth response object from local storage with a default fallback
  const authResponseObject = JSON.parse(localStorage.getItem('authResponseObject'));

  // Check if `expiresAt` is a valid number before comparing
  const expiresAt = Number(authResponseObject?.expiresAt);

  // If authResponseObject or expiresAt is invalid, redirect to root
  if (!authResponseObject || isNaN(expiresAt)) {
    callback?.(RouterPathEnum.ROOT);
    return;
  }

  // Check if the token has expired
  if (Date.now() > expiresAt) {
    callback?.(RouterPathEnum.LOGOUT);
    return;
  }

  // Proceed to the intended route
}
