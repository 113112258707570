import React, { useState } from 'react';
import { Typography, Button } from '@common-ui/ui';
import beginUpload from '../../../../utils/beginUpload';
import ResetSessionModal from '../../../../components/modals/ResetSessionModal';
import { Upload, FormatListBulleted } from '@mui/icons-material';
import { UploadImagesEnum } from '../../../../enums';
import styled from '@emotion/styled';
import { useBaseline } from '../../../../context';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.grey[900]};
  align-items: center;
  gap: 0.5em;
  padding: 2em;
`;

const Icon = styled.div`
  background: ${({ theme }) => theme.palette.secondary.main};
  color: white;
  width: 4em;
  height: 4em;
  border-radius: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
`;

/**
 * Display a dialog when there are no images to display
 *
 *  @param {None} None
 *  @returns {JSX.Element}
 */
export default function NoImagesDialog() {
  const [showModal, setShowModal] = useState(false);
  const { baselineStore } = useBaseline();
  const handleUpload = (e) => {
    setShowModal(true);
    beginUpload(e, baselineStore.currentOrganization);
  };

  return (
    <Container>
      <ResetSessionModal showModal={showModal} setShowModal={setShowModal} />
      <Icon>
        <FormatListBulleted fontSize="large" />
      </Icon>
      <Typography variant="h6" color="white">
        {UploadImagesEnum.TITLE}
      </Typography>
      <Typography color="gray">{UploadImagesEnum.BODY}</Typography>
      <Button
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<Upload />}
        onClick={handleUpload}
      >
        {UploadImagesEnum.BUTTON}
      </Button>
    </Container>
  );
}
