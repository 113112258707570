export const EntryAction = Object.freeze({
  OPEN: 'open',
  RENAME: 'rename',
  OPEN_ASSET: 'openAsset',
  OPEN_FOLDER: 'openFolder',
  ADD_FOLDER: 'addFolder',
  ADD_ASSET: 'addAsset',
  ADD_TEST_POINT: 'addTestPoint',
  DELETE: 'delete',
  SHOW_ALL: 'assetsShowAll',
  SHOW_DELETED: 'assetsDeleted',
});
