import React, { useMemo } from 'react';
import { LinearProgress, Typography } from '@mui/material';

/**
 * ProgressBar Component
 *
 * Displays a linear progress bar with dynamic status text indicating the number of settled keys
 * out of the total keys in a payload. The progress is calculated based on the given order and payload.
 *
 * @param {Object} props - Component properties.
 * @param {Array} props.order - An array representing the order of keys to track progress.
 * @param {Object} props.payload - An object containing key-value pairs, where truthy values indicate "settled" keys.
 *
 * @returns {JSX.Element} A progress bar with status text or null if progress is 100%.
 */
export default function ProgressBar({ label = 'Loading Images', settled = 0, total = 0 }) {
  const progress = useMemo(
    () => Math.max(0, Math.min(100, (settled / total) * 100)),
    [settled, total],
  );

  const showProgressBar = useMemo(() => {
    return total > 0 && progress < 100;
  }, [progress, total]);

  return (
    <>
      {showProgressBar && (
        <div style={styles.container}>
          <div style={styles.statusLabel}>
            <Typography variant="body2" color="textSecondary">
              {label}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`${settled} / ${total}`}
            </Typography>
          </div>
          <LinearProgress variant="determinate" value={progress} sx={styles.linearProgress} />
        </div>
      )}
    </>
  );
}

const styles = {
  container: {
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    gap: 8,
  },
  statusLabel: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linearProgress: {
    backgroundColor: 'lightgrey',
  },
};
