import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// enums
import { ReportEnum } from '../../enums';

// components
import TitleAndSearch from './TitleAndSearch';
import ReportsList from './DataGrid/ReportsList';
import GridColumns from './DataGrid/GridColumns';
import { ThemeProvider, createTheme } from '@common-ui/ui';
import { Tabs, Tab } from '@common-ui/ui';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useBaseline } from '../../context';

const lightTheme = createTheme({
  palette: {
    mode: 'light',
  },
});

/**
 * This is the main component for the reports screen.
 * @param {*} props - The pathname of the current location
 *
 * @returns {JSX.Element}
 */
export default function Reports() {
  const navigate = useNavigate();
  const [value, setValue] = useState(ReportEnum.OVERVIEW);

  const {
    baselineReports: {
      allReports: { draft = [], finalized = [] } = {},
      refreshReports,
      setActiveReportById,
    },
  } = useBaseline();

  // fetch all reports if user performs a hard refresh
  useEffect(() => {
    setActiveReportById(); // clear active item
    refreshReports();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const goToReport = ({ id }) => {
    if (!id) return;
    navigate({ pathname: `/report/${id}` });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <div style={styles.container}>
        <TitleAndSearch />
        <div style={styles.main}>
          <TabContext value={value}>
            <div style={styles.tabs}>
              <Tabs value={value} onChange={handleChange}>
                <Tab value={ReportEnum.OVERVIEW} label={ReportEnum.OVERVIEW} />
                <Tab value={ReportEnum.DRAFT_REPORTS} label={ReportEnum.DRAFT_REPORTS} />
                <Tab value={ReportEnum.COMPLETED_REPORTS} label={ReportEnum.COMPLETED_REPORTS} />
              </Tabs>
            </div>
            <TabPanel sx={styles.panel} value={ReportEnum.OVERVIEW}>
              <div style={styles.overview}>
                <ReportsList
                  rows={draft}
                  title={ReportEnum.DRAFT_REPORTS}
                  onRowDoubleClick={goToReport}
                />
                <ReportsList
                  rows={finalized}
                  title={ReportEnum.COMPLETED_REPORTS}
                  onRowDoubleClick={goToReport}
                />
              </div>
            </TabPanel>
            <TabPanel sx={styles.panel} value={ReportEnum.DRAFT_REPORTS}>
              <ReportsList
                rows={draft}
                columns={GridColumns}
                title={ReportEnum.DRAFT_REPORTS}
                onRowDoubleClick={goToReport}
              />
            </TabPanel>
            <TabPanel sx={styles.panel} value={ReportEnum.COMPLETED_REPORTS}>
              <ReportsList
                rows={finalized}
                columns={GridColumns}
                title={ReportEnum.COMPLETED_REPORTS}
                onRowDoubleClick={goToReport}
              />
            </TabPanel>
          </TabContext>
        </div>
      </div>
    </ThemeProvider>
  );
}

const styles = {
  container: {
    backgroundColor: '#f0f0f0',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    height: '100%',
  },
  main: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
  },
  panel: {
    flex: 1,
    padding: '1em 0',
  },
  overview: {
    height: '100%',
    display: 'grid',
    gridAutoRows: '1fr',
    gap: '1em',
  },
};
