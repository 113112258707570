import { protectedSectionIds } from '@fluke/predator-report-lib';
/**
 * Constructs sections for a report, including specific content for each image
 * and the order of sections with default entries for Cover Page and Table of Contents.
 *
 * @param {Object} reportInfo - The information about the report, including images and their options.
 *
 * @returns {Object} An object containing:
 *  - {Object} data - A mapping of image IDs to their corresponding image data.
 *  - {Array} order - An array of section order, with each section represented as an object containing:
 *      - {string} id - The unique identifier for the section.
 *      - {string} type - The type of section (e.g., 'coverPage', 'imageSection').
 *      - {string} [name] - The name of the section, usually pulled from the template.
 *
 * The order of sections will always include a Cover Page and a Table of Contents at the beginning,
 * followed by image sections based on the order specified in reportInfo.
 */
export default function buildSectionOrder(reportInfo = {}) {
  const imageOrder = reportInfo?.images.map((imageId) => ({
    id: imageId,
    variantId: 'imageSection',
    name: reportInfo['image_options'][imageId]?.name,
  }));

  return [
    {
      id: 'coverPage',
      variantId: 'coverPage',
      name: 'Cover Page',
    },
    {
      id: protectedSectionIds.TABLE_OF_CONTENTS,
      variantId: protectedSectionIds.TABLE_OF_CONTENTS,
      name: 'Table of Contents',
    },
    ...imageOrder,
  ];
}
