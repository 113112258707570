import React, { useEffect, useState } from 'react';

// context + utilities
import { useBaseline } from '../../../context';
import { buildImagePreviews } from '../utils';

// components
import ImageItem from './ImageItem';
import RelativeOverlay from '../../../components/Overlays/RelativeOverlay';
import NoImagesOverlay from './NoImagesOverlay';
import { SelectAll, Clear, ImageCount } from '../ActionBar';
import { Box, ListSubheader } from '@common-ui/ui';

/**
 * Display images grouped by month
 * @returns {JSX.Element}
 */
export default function ImagePreviews() {
  const {
    baselineImages: { isLoading = true, imagePreviews = [] },
  } = useBaseline();
  const [orderedImages, setOrderedImages] = useState([]);

  const scrollToTop = () => {
    document?.querySelector('#imagePreviews')?.scrollTo(0, 0);
  };

  useEffect(() => {
    //  fetch on image results update
    const images = buildImagePreviews(imagePreviews);
    setOrderedImages(images);
    scrollToTop();
  }, [imagePreviews]);

  if (isLoading) return <RelativeOverlay />;

  return (
    <div style={styles.container}>
      {orderedImages.length ? (
        <Box sx={styles.floatingButtons}>
          <ImageCount />
          <SelectAll />
          <Clear />
        </Box>
      ) : null}
      <Box id="imagePreviews" sx={styles.gridArea}>
        {orderedImages.length ? (
          orderedImages.map(({ header, images = [] }, index) => (
            <span key={`row-${index}-${header}`}>
              <ListSubheader sx={styles.header}>{header}</ListSubheader>
              <Box sx={styles.grid}>
                {images.map((imageItem, index) => (
                  <ImageItem imageItem={imageItem} key={index} />
                ))}
              </Box>
            </span>
          ))
        ) : (
          <NoImagesOverlay />
        )}
      </Box>
    </div>
  );
}
const styles = {
  container: {
    overflowY: 'hidden',
    position: 'relative',
    height: '100%',
  },
  gridArea: (theme) => ({
    height: '100%',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column',
    gridArea: 'content',
    background: theme.palette.background.default,
  }),
  floatingButtons: (theme) => ({
    position: 'absolute', // Position absolute within the container
    top: 10,
    right: 20,
    padding: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(3),
    zIndex: 1000,
    justifyContent: 'end',
    alignItems: 'center',
  }),

  header: {
    height: '62px',
    alignItems: 'center',
    fontSize: '1.25rem',
    fontWeight: 300,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: {
      xs: '1fr',
      sm: 'repeat(auto-fill, minmax(150px, 1fr))',
      md: 'repeat(auto-fill, minmax(190px, 1fr))',
      lg: 'repeat(auto-fill, minmax(240px, 1fr))',
      xl: 'repeat(auto-fill, minmax(350px, 1fr))',
    },
    gap: '0.5em',
    padding: '1em',
  },
};
