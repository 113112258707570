import React, { useState } from 'react';

import { reportActions } from '../../enums';
import { useReportsData } from '../../hooks/useReportsData';

// components
import MenuDropdown from '../MenuDropdown';
import NavText from './NavText';
import { Box, IconButton, ListItem } from '@common-ui/ui';
import { DeleteOutline, DriveFileRenameOutline, Edit } from '@mui/icons-material';

/**
 * NavRow component.
 *
 * This component represents a navigation button with optional features such as a thumbnail image,
 * metadata display, and interaction capabilities like click, edit, and delete actions.
 * It includes support for custom icons, inline styles, and indentation levels,
 * as well as an optional dropdown menu for additional actions.
 *
 * @component
 * @param {string} id - Unique identifier for the item.
 * @param {boolean} [active=false] - Indicates if the item is currently active.
 * @param {string} [name='Loading...'] - Name of the item to display.
 * @param {string} [thumbnail] - URL of the thumbnail image.
 * @param {string} [datetime=''] - Date and time associated with the item.
 * @param {JSX.Element} [Icon] - Optional icon component to display.
 * @param {Object} [style={}] - Inline styles to apply to the list item.
 * @param {number} [indent=1] - Indentation level for the item.
 * @param {boolean} [showMenu=false] - Determines if the dropdown menu should be displayed.
 * @param {function} [onIconClick=null] - Callback function triggered when the icon is clicked.
 * @param {function} [onClick=null] - Callback function triggered when the component is clicked.
 * @returns {JSX.Element} Rendered NavRow component.
 */
export default function NavRow({
  id,
  name = 'Loading...',
  active = false,
  thumbnail,
  datetime = '',
  Icon,
  style = {},
  indent = 1,
  showMenu = false,
  onIconClick = null,
  onClick = null,
}) {
  const { handleAction } = useReportsData();

  const [isEditing, setIsEditing] = useState(false);

  const handleCallback = (e, action, value) => {
    e.stopPropagation();

    switch (action) {
      case 'navClick':
        handleAction?.(reportActions.NAV_CLICK, id);
        onClick?.();
        break;
      case 'edit':
        handleAction?.(reportActions.EDIT, id);
        break;

      case 'editName-start':
        setIsEditing(true);
        break;

      case 'editName-end':
        setIsEditing(false);
        handleAction?.(reportActions.EDIT_NAME, id, value);
        break;

      case 'delete':
        handleAction?.(reportActions.DELETE, id);
        break;
    }
  };

  const menuDropdownItems = [
    {
      label: 'Edit',
      icon: <Edit />,
      onClick: (e) => handleCallback(e, 'edit'),
    },
    {
      label: 'Rename Image',
      icon: <DriveFileRenameOutline />,
      onClick: (e) => handleCallback(e, 'editName-start'),
    },
    {
      label: 'Delete',
      icon: <DeleteOutline />,
      onClick: (e) => handleCallback(e, 'delete'),
    },
  ];

  const styles = {
    container: (theme) => ({
      display: 'flex',
      gap: theme.spacing(1),
      alignItems: 'center',
      width: '100%',
      backgroundColor: active ? theme.palette.primary.light : 'transparent',
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
    }),

    listitem: (theme) => ({
      width: '100%',
      backgroundColor: active ? theme.palette.primary.light : 'transparent',
      padding: 0,
      paddingLeft: `${indent}em`,
      paddingRight: theme.spacing(1),
      gap: theme.spacing(1),
      cursor: 'pointer',
      alignContent: 'center',
      ...style,
    }),
  };

  return (
    <Box sx={styles.container} onClick={(e) => handleCallback(e, 'navClick')}>
      <ListItem sx={styles.listitem}>
        {thumbnail && (
          <span>
            <img src={thumbnail} width="50" alt="Thumbnail" />
          </span>
        )}
        <NavText
          primary={name}
          secondary={datetime}
          isEditing={isEditing}
          onChange={(e, name) => handleCallback?.(e, 'editName-end', name)}
        />
        {Icon && (
          <IconButton onClick={(e) => onIconClick?.(e)}>
            <Icon />
          </IconButton>
        )}
        {showMenu && <MenuDropdown menuItems={menuDropdownItems} />}
      </ListItem>
    </Box>
  );
}
