import React from 'react';
// components
import { Typography } from '@fluke/predator-report-lib';
import TitleContent from './TitleContent';
import { useReportsData } from '../../hooks/useReportsData';

export default function UserNotes({ sectionId = '', contentId, settings = {} }) {
  const { data = [] } = useReportsData(sectionId, contentId);

  const { count, columns = 1, showUsername = true, showDate = true } = settings[contentId] ?? {};

  if (data.length === 0 || count === 0) return null;

  const filtered = data.length && count != null ? data.slice(0, count) : data;

  const chunkedNotes = [];

  for (let i = 0; i < filtered.length; i += columns) {
    chunkedNotes.push(filtered.slice(i, i + columns));
  }

  return (
    <div id={`${sectionId}-userNotes`} style={styles.container}>
      <TitleContent contentId={contentId} settings={settings} />
      <table style={styles.table}>
        <tbody>
          {chunkedNotes.map((noteGroup, rowIndex) => (
            <tr key={`note-${sectionId}-${rowIndex}`}>
              {noteGroup.map((entry, colIndex) => (
                <td key={`note-${sectionId}-${rowIndex}-${colIndex}`} style={styles.cell}>
                  <div style={styles.noteHeader}>
                    {showUsername && <Typography style={styles.notesUser}>{entry.user}</Typography>}
                    {showDate && <Typography variant="caption">{entry.date}</Typography>}
                  </div>
                  <div>{entry.text}</div>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  table: {
    width: '100%',
    borderCollapse: 'separate',
    borderSpacing: '8px', // Adjust spacing between columns
  },
  cell: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  noteHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  notesUser: {
    fontWeight: 'bold',
    color: 'rgba(0, 0, 0, 0.54)',
  },
};
