import React from 'react';
// enums -->
import { EntryType } from '../../lib/enum/EntryTypeEnum';
import { EntryAction } from '../../lib/enum/EntryActionEnum';
// mui -->
import { Menu, MenuItem, ListItemIcon } from '@common-ui/ui';
// components -->
import menuItems from './ContextMenuItems';

/**
 * ContextMenu is a functional component that renders a context (right-click) menu at a
 * given position. This menu will close either when an item is clicked or when the menu
 * itself is clicked if an onClose function is provided.
 *
 * The ContextMenu component utilizes Menu and MenuItem components from '@mui/material'.
 *
 * @param {Object} position - The x and y coordinates where the context menu should open.
 * @param {function} [onClose=null] - Optional function to be called when the menu or a menu item
 *                                    is clicked. If this function is provided, it will receive
 *                                    the id of the clicked menu item as an argument.
 *
 * @returns {JSX.Element} A JSX component rendering a context menu with items mapped from
 *                        the imported 'menuItems'.
 */
export default function ContextMenu({ position, onClose = () => {}, variant }) {
  const exclusionSet = new Set();

  // Populate exclusionSet based on the variant
  switch (variant) {
    case EntryType.FILE:
      exclusionSet.add(EntryAction.ADD_FOLDER);
      exclusionSet.add(EntryAction.OPEN_FOLDER);
      exclusionSet.add(EntryAction.ADD_ASSET);
      break;
    case EntryType.FOLDER:
      exclusionSet.add(EntryAction.ADD_FOLDER);
      exclusionSet.add(EntryAction.ADD_TEST_POINT);
      exclusionSet.add(EntryAction.OPEN_ASSET);
      break;
    default:
      exclusionSet.add(EntryAction.RENAME);
      exclusionSet.add(EntryAction.DELETE);
      exclusionSet.add(EntryAction.ADD_TEST_POINT);
      exclusionSet.add(EntryAction.OPEN_ASSET);
      exclusionSet.add(EntryAction.OPEN_FOLDER);
      break;
  }
  // Filter dropdown items based on the exclusionSet
  const dropdownItems = menuItems.filter((item) => !exclusionSet.has(item.id));

  return (
    <Menu
      id="right-click-menu"
      anchorReference="anchorPosition"
      anchorPosition={position}
      keepMounted
      open={Boolean(position)}
      onClose={onClose}
      MenuListProps={{ sx: styles.menu }}
      disableAutoFocusItem={true}
    >
      {dropdownItems.map(({ id, name, icon }) => (
        <MenuItem
          key={`menu-item_${id}`}
          onClick={() => onClose(id)}
          divider
          sx={{ '&.MuiMenuItem-root:hover': styles.menuItem }}
        >
          <ListItemIcon>{icon}</ListItemIcon> {name}
        </MenuItem>
      ))}
    </Menu>
  );
}

const styles = {
  menu: {
    padding: 0,
  },
  menuItem: {
    backgroundColor: '#EDF6FF',
  },
};
