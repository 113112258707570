import React, { useState } from 'react';

// enums
import secondaryNavTabsEnum from '../../../../enums/secondaryNavTabsEnum';

// componenets
import InfoTable from './InfoTable';
import MarkerInfo from './MarkerInfo';
import PanelNotes from './PanelNotes';
import { Tab, Tabs } from '@common-ui/ui';

export default function PanelImageInfo({ firstImage = {}, settings = {}, setSettings }) {
  const [currentTab, setCurrentTab] = useState(secondaryNavTabsEnum.INFO);
  const navTabs = Object.keys(secondaryNavTabsEnum).filter((navTab) => navTab !== 'BATCHEDIT');

  const styles = {
    tabContainer: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };

  const checkIsTabDisabled = (test = '') => ({ display: currentTab === test ? 'block' : 'none' });

  return (
    <div style={styles.tabContainer}>
      <Tabs activekey={currentTab} value={currentTab}>
        {navTabs.map((navTab, index) => (
          <Tab
            key={index}
            role="tabpanel"
            label={secondaryNavTabsEnum[navTab]}
            onClick={() => setCurrentTab(secondaryNavTabsEnum[navTab])}
            value={secondaryNavTabsEnum[navTab]}
          />
        ))}
      </Tabs>

      <div style={{ width: '100%' }}>
        <div style={checkIsTabDisabled(secondaryNavTabsEnum.INFO)}>
          <InfoTable imageData={firstImage} settings={settings} />
        </div>
        <div style={checkIsTabDisabled(secondaryNavTabsEnum.MARKERS)}>
          <MarkerInfo imageData={firstImage} settings={settings} setSettings={setSettings} />
        </div>
        <div style={checkIsTabDisabled(secondaryNavTabsEnum.NOTES)}>
          <PanelNotes imageData={firstImage} settings={settings} setSettings={setSettings} />
        </div>
      </div>
    </div>
  );
}
