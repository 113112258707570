const assetFormEnum = Object.freeze({
  IMAGE_ID: 'image_id',
  ASSET_TAG_ID: 'asset_tag_id',

  NAME: 'name',
  SITE_ID: 'site_id',
  DATE_TAKEN: 'date_taken',
  EQUIPMENT: 'equipment',
  TYPE: 'type',

  STATUS: 'status',
  ACTION: 'action',
  SEVERITY: 'severity',

  RECOMMENDATION: 'recommendation',
  STATUS_CODE: 'status_code',
  STATUS_TEXT: 'status_text',
  REASON_CODE: 'reason_code',
  REASON_TEXT: 'reason_text',
});

export default assetFormEnum;
