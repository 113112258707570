import React from 'react';
import { Typography } from '@fluke/predator-report-lib';
import { useReportsData } from '../../hooks/useReportsData';

export default function SectionTitle({ sectionId }) {
  const { name = '' } = useReportsData(sectionId);

  return (
    <div id={sectionId} style={styles.title}>
      <Typography variant="title">{name}</Typography>
    </div>
  );
}

const styles = {
  title: {
    display: 'flex',
  },
};
