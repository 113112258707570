import {
  clearBrowserStorage,
  getFromLocalStorage,
  getFromSessionStorage,
  updateLocalStorage,
  updateToSessionStorage,
} from '../../apis/localStorageAPI';
import { fetchUserStore, updateUserStore } from '../../apis/userAPI';
import { baselineStoreEnum, sessionKeyStoreDefaults, userKeyStoreDefaults } from './enums';

const TABLE = {
  localStorage: {
    [baselineStoreEnum.USER]: userKeyStoreDefaults,
    [baselineStoreEnum.SESSION]: sessionKeyStoreDefaults,
  },

  sessionStorage: {
    [baselineStoreEnum.SESSION]: { [baselineStoreEnum.SELECTED_IMAGES]: [] },
  },
};

export default class BaselineManager {
  constructor(dispatch) {
    this.dispatch = dispatch;
    this.data = {};
  }

  setDispatch(dispatch) {
    this.dispatch = dispatch;
  }

  // Method to find which store a key belongs to
  getKeyLocationInTable(key) {
    for (const [storeType, keyStore] of Object.entries(TABLE)) {
      // Loop through nested key stores (e.g., baselineStoreEnum.USER, SESSION)
      for (const storeKey in keyStore) {
        // Check if the key is in the nested key store
        if (key in keyStore[storeKey]) {
          return { storeType, storeKey };
        }
      }
    }
    return null; // Return null if the key is not found in any store
  }

  categorizeKeysByStore(modifiedKeys = {}) {
    const modifiedValuesInTable = {};

    for (const [key, value] of Object.entries(modifiedKeys)) {
      const location = this.getKeyLocationInTable(key);

      if (location) {
        const { storeType, storeKey } = location;

        // Initialize storeType and storeKey only when they don't exist
        modifiedValuesInTable[storeType] = modifiedValuesInTable[storeType] || {};
        modifiedValuesInTable[storeType][storeKey] =
          modifiedValuesInTable[storeType][storeKey] || {};

        // Directly assign the key-value pair
        modifiedValuesInTable[storeType][storeKey][key] = value;
      } else {
        console.warn(`Key "${key}" does not belong to any registered store or storage type.`);
      }
    }

    return modifiedValuesInTable;
  }

  async initializeStorage() {
    let store = {};

    // Initialize both localStorage and sessionStorage
    for (const [storageType, storageTable] of Object.entries(TABLE)) {
      for (const [storeKey, initialStore] of Object.entries(storageTable)) {
        let localData;

        // Handle different storage types
        if (storageType === 'localStorage') {
          localData = getFromLocalStorage(storeKey);
        } else if (storageType === 'sessionStorage') {
          localData = getFromSessionStorage(storeKey);
        }

        const mergedData = { ...initialStore, ...localData };
        store = { ...store, ...mergedData };

        // Update the correct storage type
        if (storageType === 'localStorage') {
          updateLocalStorage(storeKey, mergedData);
        } else if (storageType === 'sessionStorage') {
          updateToSessionStorage(storeKey, mergedData);
        }
      }
    }

    // Initialize user session and merge with the store
    const userStore = await fetchUserStore();
    store = { ...store, ...userStore };
    updateLocalStorage(baselineStoreEnum.USER, userStore);

    // Final update with the entire store
    this.updateData(store);
  }

  updateFromStorage() {
    try {
      const nextSession = getFromLocalStorage(this.localStorageKey);
      this.data = { ...this.initKeyTable, ...nextSession };
      return true;
    } catch (error) {
      console.error('Error loading store:', error);
    }
    return false;
  }

  // Method to update multiple keys across stores
  update(modifiedKeys = {}) {
    this.updateData(modifiedKeys);

    // Categorize keys by store and storage type
    const storeUpdates = this.categorizeKeysByStore(modifiedKeys);

    // Define the mapping for storage update functions
    const updateFunctions = {
      localStorage: updateLocalStorage,
      sessionStorage: updateToSessionStorage,
    };

    // Iterate through store updates and apply the corresponding update function
    Object.entries(storeUpdates).forEach(([storeType, storeData]) => {
      const updateFn = updateFunctions[storeType];
      Object.entries(storeData).forEach(([storeKey, updatedKeys]) => {
        try {
          updateFn(storeKey, updatedKeys);
        } catch (error) {
          console.error(error.message);
        }
      });
    });

    // Sync with API if baseline user store was updated
    const baselineUserUpdates = storeUpdates.localStorage?.[baselineStoreEnum.USER];
    if (baselineUserUpdates) {
      updateUserStore(baselineUserUpdates);
    }
  }

  updateData(newData = {}) {
    this.data = { ...this.data, ...newData };

    // Sync to external state management
    this._syncStorage();
  }

  getData() {
    return { ...this.data };
  }

  _syncStorage() {
    this.dispatch?.(this.data);
  }

  flush() {
    clearBrowserStorage();
  }
}
