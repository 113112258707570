import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import PrimaryNavigation from '../components/header/PrimaryNavigation/index';
import useDocumentTitle from '../hooks/useDocumentTitle';
import routerPathEnum from '../enums/routerPathEnum';
import { Box, ThemeProvider } from '@common-ui/ui';
import DarkTheme from '../styles/DarkTheme';

const includedUrls = [
  routerPathEnum.IMAGES,
  routerPathEnum.ASSETS,
  routerPathEnum.REPORTS,
  routerPathEnum.REPORT,
  routerPathEnum.IMAGE_EDITOR,
  routerPathEnum.MERLIN
];

export const Layout = ({ children }) => {
  useDocumentTitle();
  const location = useLocation();

  const shouldDisplayNavigation = useMemo(() => {
    return includedUrls.some((url) => location.pathname.startsWith(url));
  }, [location.pathname]);

  return (
    <Box sx={styles.layout}>
      {shouldDisplayNavigation && (
        <ThemeProvider theme={DarkTheme}>
          <PrimaryNavigation />
        </ThemeProvider>
      )}
      {children}
    </Box>
  );
};

const styles = {
  layout: (theme) => ({
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    background: theme.palette.background.default,
  }),
};
