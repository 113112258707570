import React, { useRef } from 'react';
import { Edit, Delete } from '@mui/icons-material';
import { Overlay } from '../../../../components/Overlays';

import { Typography } from '@common-ui/ui';
import MenuDropdown from '../MenuDropdown';

const text = {
  select: 'Select Logo',
  edit: 'Edit',
  delete: 'Delete',
};

export default function AvatarLoadingControls({
  handleUpload,
  handleDelete,
  hasOrgLogo,
  disabled = false,
}) {
  const inputFileRef = useRef(null);

  const dropdownItems = [
    {
      label: text.edit,
      icon: <Edit />,
      onClick: () => inputFileRef.current.click(),
    },
    {
      label: text.delete,
      icon: <Delete />,
      onClick: () => handleDelete?.(),
    },
  ];

  const handleUploadClick = (files) => {
    handleUpload?.(files);
  };

  return (
    <Overlay style={{ ...styles.uploadOverlay, display: disabled ? 'none' : 'flex' }}>
      <input
        hidden
        id="upload-avatar-click"
        ref={inputFileRef}
        accept="image/jpeg, image/png"
        type="file"
        onClick={(e) => (e.target.value = '')}
        onChange={(e) => handleUploadClick(e.target.files)}
      />

      {!hasOrgLogo ? (
        <div
          id="edit-avatar-btn"
          style={styles.selectImageBtn}
          onClick={() => inputFileRef.current.click?.()}
        >
          <Typography>{text.select}</Typography>
        </div>
      ) : (
        <div id="edit-avatar-dropdown" style={styles.dropdown}>
          <MenuDropdown menuItems={dropdownItems} style={styles.menu} />
        </div>
      )}
    </Overlay>
  );
}

const styles = {
  uploadOverlay: {
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  selectImageBtn: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '35%',
    color: '#1976d2',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  dropdown: {
    position: 'absolute',
    top: '0px',
    right: '0px',
  },
  menu: {
    icon: {
      color: 'white',
      backgroundColor: '#00000020',
    },
  },
};
