import { imageAPI, imageArchive, reportAPI } from '@fluke/baseline-client-apis';
import { ReportEnum } from '../enums';

/**
 * This function sorts reports by date.
 *
 * @param {Object} allReports - The reports to be sorted.
 *
 * @property {Array} draft - The draft reports.
 * @property {Array} finalized - The finalized reports.
 *
 * @returns {Object} - The sorted reports.
 */
function sortReports(allReports = {}) {
  const { draft = [], finalized = [] } = { ...allReports };

  function dateComparator(v1, v2) {
    const dateA = new Date(v1);
    const dateB = new Date(v2);
    return dateA - dateB;
  }

  function sortReportsByDate(reports = [], field, descending = true) {
    return reports
      .slice()
      .sort((a, b) => dateComparator(a[field], b[field]) * (descending ? -1 : 1));
  }

  const sortedDrafts = sortReportsByDate(draft, ReportEnum.SORTED_BY_PROPERTY);
  const sortedFinalized = sortReportsByDate(finalized, ReportEnum.SORTED_BY_PROPERTY);

  return {
    draft: sortedDrafts ?? [],
    finalized: sortedFinalized ?? [],
  };
}

/**
 * This function fetches all reports from the API.
 *
 * @param {string} currentOrganization - The organization currently being handled.
 *
 * @returns {Object} - The reports fetched from the report API, or an empty object if the API call fails or there are no reports.
 */
export async function fetchAllReports(currentOrganization = '') {
  const allReports = await reportAPI.fetchAllReports(currentOrganization);
  const sortedReports = sortReports(allReports);
  return sortedReports ?? {};
}

/**
 * This function fetches report information by report ID.
 *
 * @param {string} reportId - The ID of the report to be fetched.
 * @param {string} currentOrganization - The organization currently being handled.
 *
 * @returns {Object} - The report information fetched from the report API, or an empty object if the API call fails or the report does not exist.
 */
export async function fetchReportById(reportId, currentOrganization = '') {
  try {
    const reportInfo = await reportAPI.fetchReportById(reportId, currentOrganization);
    return reportInfo ?? {};
  } catch (error) {
    return {};
  }
}

/**
 * This function saves report information.
 *
 * @param {Object} reportInfo - The details of the report being saved.
 * @param {string} currentOrganization - The organization currently being handled.
 *
 * @returns {Object} - The response from the report API, or an error object if the API call fails.
 */
export async function saveReportInfo(reportInfo = {}, currentOrganization = '') {
  const response = await reportAPI.saveReportInfo(reportInfo, currentOrganization);

  return response;
}

/**
 * This function saves images to a report.
 *
 * @param {Object} reportInfo - The details of the report where images are being saved.
 * @param {Array} imageList - The list of image IDs to be saved.
 * @param {string} currentOrganization - The organization currently being handled.
 *
 * @returns {Object} `reportInfo` - The updated report information after adding the images.
 */
export async function saveImagesToReport(
  reportInfo = {},
  imageList = [],
  currentOrganization = '',
) {
  let { images = [], image_options = {} } = reportInfo;
  images.push(...imageList);

  const newReportInfo = {
    ...reportInfo,
    images,
    image_options,
  };

  await saveReportInfo(newReportInfo, currentOrganization);

  return newReportInfo;
}

/**
 * Deletes a report by its ID using the reportAPI.
 *
 * @param {string} reportId - The ID of the report to delete.
 * @returns {boolean} True if the report is successfully deleted, false if an error occurs or no ID is provided.
 */
export async function deleteReportById(reportId) {
  if (!reportId) return false;

  try {
    await reportAPI.deleteById(reportId);
    return true;
  } catch (error) {
    console.error('Error deleting report:', error);
    return false;
  }
}

/**
 * Save the company logo to a report.
 *
 * @param {string} reportId - The unique identifier of the report.
 * @param {Blob|null} imageBlob - The image file as a Blob, or null if no file is provided.
 * @param {object} currentOrganization - The organization context for the API call.
 * @returns {Promise<string|null>} - A URL string representing the saved logo, or null if operation fails.
 */
export async function saveCompanyLogoToReport(reportId, imageBlob, currentOrganization) {
  if (!reportId) {
    console.warn('Failure saving company logo. Invalid or null report ID passed.');
    return null;
  }

  if (!imageBlob) {
    console.warn('Failure saving company logo. Invalid or null image passed.');
    return null;
  }

  try {
    const response = await reportAPI.store(
      reportId,
      { company_logo: imageBlob },
      currentOrganization,
    );

    if (response.status === 200) {
      // Return a URL representing the uploaded logo
      return URL.createObjectURL(imageBlob);
    }

    console.warn('Unexpected response status:', response.status);
    return null;
  } catch (error) {
    console.error('Failed to save the company logo:', error);
    return null;
  }
}

/**
 * Retrieves images by their IDs from the report information or archive.
 *
 * @param {string[]} imageIdList - The list of unique identifiers for the images.
 * @param {object} [reportInfo={}] - The report information object (optional).
 * @param {string} [organization=''] - The organization context for the API call (optional).
 * @returns {Promise<object[]>} - The list of image data retrieved from the archive or API.
 */
export async function getReportImages(imageIdList = [], reportInfo = {}, organization = '') {
  if (!imageIdList || imageIdList.length === 0) {
    return []; // Return an empty array if no image IDs are provided
  }
  let imageUrlList;
  const isFinalized = reportInfo.options?.finalized === true;

  if (isFinalized === false) {
    // Fetch image keys from the API for non-finalized reports
    imageUrlList = await imageAPI.fetchByMultipleIds(
      imageIdList,
      organization,
      true, // includeDeletedImages
    );
  } else {
    // Retrieve image keys from the archived images for finalized reports
    imageUrlList = imageIdList.map((imageId) => {
      const archive = reportInfo.archivedImages?.[imageId] || {};
      return {
        s3_keys: { is2u: archive.url },
      };
    });
  }

  // Fetch each image using the image keys
  const imageResults = await Promise.all(
    imageUrlList.map(async (imageKeys = {}) => {
      const s3URL = imageKeys.s3_keys?.is2u;
      const name = imageKeys.sort_info?.origin_path;

      return await imageArchive.fetchImageArchiveByUrl(s3URL, name);
    }),
  );

  return imageResults;
}

/**
 * Saves the name of an image in the system by calling the image API.
 *
 * @async
 * @param {string} id - The unique identifier of the image. Must not be falsy.
 * @param {string} name - The new name for the image. Must not be falsy.
 * @param {string} [currentOrganization=''] - Optional. The organization context for the image update.
 * @returns {Promise<boolean>} - Resolves to `true` if the update succeeds, or `false` if it fails.
 */
export async function saveImageName(id, name, currentOrganization = '') {
  // Validate inputs
  if (!id || !name) {
    console.error('Invalid id or name provided for saveImageName.');
    return false;
  }

  try {
    // Call the image API to update the image name
    await imageAPI.storeUpdatedThermalImage(
      id,
      {
        origin_path: name,
      },
      currentOrganization,
    );

    return true; // Return true on successful update
  } catch (error) {
    console.error('Failed to update image name:', error);
    return false; // Return false on error
  }
}
