import React from 'react';
import { useBaseline } from '../../../context';
import { Typography } from '@common-ui/ui';

const CountsEnum = Object.freeze({
  IMAGE: 'Image',
  IMAGES: 'Images',
  SELECTED: 'Selected',
});

/**
 * Counts components for images selected
 *
 * @returns {JSX.Element}
 */
export default function ImageCount() {
  const {
    baselineImages: { selectedImageIds = [] },
  } = useBaseline();
  const imageCount = selectedImageIds.length;

  // Build the string
  const displayText =
    imageCount > 0
      ? `${imageCount} ${imageCount === 1 ? CountsEnum.IMAGE : CountsEnum.IMAGES} ${
          CountsEnum.SELECTED
        }`
      : '';

  return <Typography sx={styles.text}>{displayText}</Typography>;
}

const styles = {
  text: (theme) => ({
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    color: theme.palette.text.primary,
  }),
};
