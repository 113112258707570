import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';

const MOUNT_NODE = document.getElementById('root');
const root = createRoot(MOUNT_NODE);

window.BASELINE = {
  REACT_APP_ENV: process.env.REACT_APP_ENV,
  REACT_APP_PENDO_API_KEY: process.env.REACT_APP_PENDO_API_KEY,
};

root.render(<App />);

reportWebVitals();

export { default as Logout } from './screens/AuthScreens/Logout';
export { default as Loading } from './screens/AuthScreens/Loading';
export { default as ImageManagement } from './screens/ImageManagement/index';
export { default as MerLin } from './screens/MerLin/index';
export { default as ImageEditor } from './screens/ImageEditor';
export { default as Assets } from './screens/Assets';
export { default as Reports } from './screens/Reports';
export { default as ReportView } from './screens/ReportView';
export { default as Error } from './screens/Error';
export { default as ErrorBoundary } from './screens/Error/ErrorBoundary';
