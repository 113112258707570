import React from 'react';
import { DataTable } from '@fluke/predator-report-lib';
import TitleContent from './TitleContent';
import { useReportsData } from '../../hooks/useReportsData';

const imageInfoTableEnum = {
  'Background Temperature': 'backgroundTemperature',
  'Calibration Range': 'calibrationRange',
  'Date & Time': 'datetime',
  Emissivity: 'emissivity',
  'Camera Manufacturer': 'manufacturer',
  'Camera Model': 'modelNumber',
  'IR Sensor Size': 'sensorSize',
  'Camera Serial': 'serialNumber',
  Transmission: 'transmission',
};

export default function ImageInfo({ sectionId, contentId, settings = {} }) {
  const { data = {} } = useReportsData(sectionId, contentId);

  const options = settings[contentId] ?? {};

  if (!Object.keys(data).length) return null;

  // Filter rows based on the first cell's value in each row
  const filteredInfoRows = data.rows.filter((row) => {
    const cellLabel = row[0];
    const cellId = imageInfoTableEnum[cellLabel];
    return options[cellId] !== false;
  });

  const table = {
    header: [],
    rows: filteredInfoRows,
  };

  return (
    <div id={`${sectionId}-imageInfo`} style={styles.container}>
      <TitleContent contentId={contentId} settings={settings} />
      <DataTable data={table} />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
};
