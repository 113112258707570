import React from 'react';

// components
import { DataTable } from '@fluke/predator-report-lib';
import TitleContent from './TitleContent';
import { useReportsData } from '../../hooks/useReportsData';

export default function MarkerInfo({ sectionId, contentId, settings = {} }) {
  const { data = {} } = useReportsData(sectionId, contentId);

  if ((data?.rows ?? []).length === 0) return null;

  return (
    <div id={`${sectionId}-markerInfo`} style={styles.container}>
      <TitleContent contentId={contentId} settings={settings} />
      <DataTable data={data} />
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
};
