import { useState } from 'react';
import {
  fetchAllReports,
  fetchReportById,
  saveImagesToReport,
  saveReportInfo,
  deleteReportById,
} from '../../../apis/reportAPI';

export default function useReports() {
  const [allReports, setAllReports] = useState({});
  const [activeReportItem, setActiveReportItem] = useState({});

  const refreshReports = async () => {
    const allReports = (await fetchAllReports()) ?? {};
    setAllReports(allReports);
  };

  const setActiveReportById = async (imageId) => {
    let report = {};

    if (imageId) {
      report = (await fetchReportById(imageId)) ?? {};
      setActiveReportItem(report);
    } else {
      // clear active item
      setActiveReportItem({});
    }

    return report;
  };

  const updateReportInfo = async (reportInfo = {}) => {
    const updatedReportInfo = await saveReportInfo(reportInfo);
    refreshReports();

    return updatedReportInfo;
  };

  const updateReportImages = async (reportInfo = {}, images = []) => {
    const updatedReportInfo = await saveImagesToReport(reportInfo, images);
    refreshReports();

    return updatedReportInfo;
  };

  const deleteReport = (reportId = '') => {
    const { draft = [], finalized = [] } = { ...allReports };

    deleteReportById(reportId);

    const removeReportById = (list = [], reportId) => {
      return list.filter((report = {}) => report.report_id !== reportId);
    };

    const updatedReports = {
      draft: removeReportById(draft, reportId),
      finalized: removeReportById(finalized, reportId),
    };

    setAllReports(updatedReports);
  };

  const clearActiveReport = () => {
    setActiveReportItem({});
  };

  return {
    allReports,
    activeReportItem,
    refreshReports,
    setActiveReportById,
    updateReportInfo,
    updateReportImages,
    deleteReport,
    clearActiveReport,
  };
}
