import { userKeyStoreEnum } from './userKeyStoreEnum';

export const sessionKeyStoreEnum = Object.freeze({
  ACTIVE_QUERY: 'activeQuery',
  SEARCH_TEXT: 'searchText',
  SORTED_BY: 'sort_field',
  SORTED: 'sort_direction',
  SEARCH_ID: 'search_id',
  PREVIEW_PAGE_SIZE: userKeyStoreEnum.PREVIEW_PAGE_SIZE, // not entirely a member
  PREVIEW_PAGE_INDEX: 'page',
  PREVIEW_PAGE_COUNT: 'previewPageCount',

  SELECTED_MONTH: 'selectedMonth',
  SELECTED_YEAR: 'selectedYear',
  ACTIVE_HEADER: 'activeHeader',
  ACTIVE_CHILD: 'activeChild',
  SIDEBAR_DATES: 'sidebarDates', // exception
});

const sessionKeyStoreDefaults = new Object({
  [sessionKeyStoreEnum.ACTIVE_QUERY]: null,
  [sessionKeyStoreEnum.SEARCH_TEXT]: '',
  [sessionKeyStoreEnum.SORTED]: 'desc',
  [sessionKeyStoreEnum.SORTED_BY]: 'date_taken',
  [sessionKeyStoreEnum.SEARCH_ID]: Date.now(),
  [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: 0,
  [sessionKeyStoreEnum.PREVIEW_PAGE_COUNT]: 1,
  [sessionKeyStoreEnum.SIDEBAR_DATES]: [],
  [sessionKeyStoreEnum.SELECTED_MONTH]: null,
  [sessionKeyStoreEnum.SELECTED_YEAR]: null,
  [sessionKeyStoreEnum.ACTIVE_HEADER]: null,
  [sessionKeyStoreEnum.ACTIVE_CHILD]: null,
});

export default sessionKeyStoreDefaults;
