import React, { useState } from 'react';
import { ImageListItem, ImageListItemBar, IconButton } from '@mui/material';
import imageStatusIconEnum from '../../../enums/imageStatusIconEnum';
import { getDateTimeFromMillisecondsTZ } from '../../../utils/getDateFormat';
import styled from '@emotion/styled';
import VisibilitySensor from 'react-visibility-sensor';
import { useBaseline } from '../../../context';

/**
 * Display an image item
 * @param {Object} imageItem
 * @returns {JSX.Element}
 */
export default function ImageItem({ imageItem }) {
  const { image_id, sort_info, s3_keys, status } = imageItem;

  const [visible, setVisible] = useState(false);
  const {
    baselineImages: { selectedImageIds = [], addSelectedImages },
  } = useBaseline();

  const handleSelectedImageIds = () => {
    addSelectedImages([image_id]);
  };

  const Container = styled(ImageListItem)`
    border: 2px solid transparent;
    cursor: pointer;
    border-color: ${({ theme }) =>
      selectedImageIds.includes(image_id) ? theme.palette.primary.main : 'transparent'};
  `;

  return (
    <VisibilitySensor
      partialVisibility
      onChange={(visibilityState) => {
        if (!visible && visibilityState) {
          setVisible(visibilityState);
        }
      }}
    >
      <Container onClick={handleSelectedImageIds}>
        <img
          src={visible ? s3_keys.preview : ''}
          width="360"
          height="270"
          style={{ opacity: visible ? 1 : 0 }}
        />

        <ImageListItemBar
          title={sort_info?.origin_path}
          subtitle={getDateTimeFromMillisecondsTZ(sort_info?.date_taken, sort_info?.user_timezone)}
          actionIcon={
            <IconButton aria-label={`info about ${sort_info?.origin_path}`}>
              <img src={imageStatusIconEnum[status?.status]} />
            </IconButton>
          }
        />
      </Container>
    </VisibilitySensor>
  );
}
