import React from 'react';
import { Box, Typography } from '@common-ui/ui';

export default function LoadingScreen() {
  return (
    <Box sx={styles.container}>
      <Typography variant="h5">Loading...</Typography>
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: 'grey.100',
    background: theme.palette.grey[900],
  }),
};
