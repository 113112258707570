import React from 'react';
import ShowAvatar from './Avatar';
import routerPathEnum from '../../../enums/routerPathEnum';
import UploadImages from './Actions/UploadImages';
import Downloader from './Actions/Downloader';
import Tab from '../../buttons/TabButton/index';
import Settings from './Actions/Settings';
import { Link } from '@common-ui/ui';
import { useNavigate } from 'react-router-dom';
import { useBaseline } from '../../../context';

/**
 * The primary navigation bar for the main Baseline flow
 *
 * @returns {JSX.Element} - The primary navigation bar
 */

export default function BaselineFlow() {
  const { baselineStore = {} } = useBaseline();
  const { merlinSmartSearch = false } = baselineStore;

  const navigate = useNavigate();

  const tabs = [
    {
      text: 'Images',
      path: routerPathEnum.IMAGES,
      hidden: false,
    },
    {
      text: 'Assets',
      path: routerPathEnum.ASSETS,
      hidden: process.env.REACT_APP_ENV !== 'development',
    },
    {
      text: 'Reports',
      path: routerPathEnum.REPORTS,
      hidden: false,
    },
    {
      text: 'MerLin',
      path: routerPathEnum.MERLIN,
      hidden: merlinSmartSearch === false,
    },
  ];

  return (
    <div style={container}>
      <Link sx={logoContainer} onClick={() => navigate(routerPathEnum.IMAGES)}>
        <img src={'/baseline.svg'} alt="Logo" style={logo} />
      </Link>
      <div style={content}>
        <div style={navigation}>
          {tabs
            .filter((tab) => !tab.hidden)
            .map((tab, index) => (
              <Tab key={index} text={tab.text} path={tab.path} />
            ))}
        </div>
        <div style={actions}>
          <Downloader />
          <UploadImages />
          {false && <Settings />}
          <ShowAvatar />
        </div>
      </div>
    </div>
  );
}

const container = {
  display: 'flex',
  background: '#252525',
};

const content = {
  display: 'flex',
  justifyContent: 'space-between',
  flexGrow: 1,
};

const navigation = {
  display: 'flex',
};

const actions = {
  display: 'flex',
  alignItems: 'center',
  gap: 10,
};

const logoContainer = {
  minWidth: '245px',
  width: '245px',
  display: 'flex',
  justifyContent: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
};

const logo = {
  gridColumn: '1 / 3',
  height: '60px',
  alignSelf: 'center',
  width: '121px',
};
