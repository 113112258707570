import React, { createContext, useContext, useEffect, useState } from 'react';
import { getBaselineJWT } from '@fluke/baseline-client-apis';
import initializePendoSession from '../../utils/analytics/pendoAnalytics';
import { useLocation } from 'react-router-dom';

// stores
import BaselineManager from './BaselineManager';
import useReports from './hooks/useReports';
import useImages from './hooks/useImages';

import { checkIfAuthenticated, isSignedIn, isValidJWT } from './utils';
import { clearBaselineJWTFromLocalStorage } from '@fluke/baseline-client-apis/apis/libJWT';

// routes
import { useNavigate } from 'react-router-dom';
import { RouterPathEnum } from '../../enums';

export const BaselineContext = createContext();

export default function BaselineProvider({ children }) {
  const [baselineManager] = useState(new BaselineManager());

  const location = useLocation();
  const navigate = useNavigate();

  const [baselineStore, setBaselineStore] = useState({});
  const [isInitialized, setIsInitialized] = useState(false);

  const redirectOnAuthFail = () => {
    checkIfAuthenticated((redirect) => navigate(redirect));
  };

  const updateBaselineStore = (modifiedKeys = {}, skipCheck = false) => {
    baselineManager.update(modifiedKeys);
    setBaselineStore(baselineManager.getData());

    // check if images should update
    if (skipCheck === false) {
      baselineImages.refreshImagesIfQueryChanged(modifiedKeys);
    }
  };

  // hooks
  const baselineImages = useImages(baselineManager, updateBaselineStore);
  const baselineReports = useReports(baselineManager);

  const initializeBaseline = async (props = {}) => {
    let jwt = await getBaselineJWT();

    if (isValidJWT(jwt) === false) {
      clearBaselineJWTFromLocalStorage();
      jwt = await getBaselineJWT();
      console.warn('Failed to fetch valid token.');
    }

    await initializePendoSession();
    await baselineManager.initializeStorage();
    setBaselineStore(baselineManager.getData());

    baselineImages.refreshImages();
    baselineReports.refreshReports();

    setIsInitialized(true);
    props.onDone?.();
  };

  useEffect(() => {
    const route = `/${location.pathname.split('/').find(Boolean) || ''}`;

    if (
      // exclusion list
      [
        RouterPathEnum.ROOT,
        RouterPathEnum.LOGIN,
        RouterPathEnum.LOGOUT,
        RouterPathEnum.REGISTER,
        RouterPathEnum.VERIFY_EMAIL,
        RouterPathEnum.FORGOT_PASSWORD,
        RouterPathEnum.FOUR_OH_FOUR,
        RouterPathEnum.FIVE_OH_FIVE,
        RouterPathEnum.FORGOT_PASSWORD_VERIFICATION,
        RouterPathEnum.FORGOT_PASSWORD_CONFORMATION,
      ].includes(route) === false
    ) {
      redirectOnAuthFail();
    }

    if (route === RouterPathEnum.LOADING) {
      initializeBaseline({
        onDone: () => navigate(RouterPathEnum.IMAGES),
      });
    }
  }, [location]);

  useEffect(() => {
    // attach listeners to window
    document.addEventListener('visibilitychange', redirectOnAuthFail);
    window.addEventListener('focus', redirectOnAuthFail);

    // On mount, check if the user is signed in and initialize the app if necessary
    if (isSignedIn(isInitialized)) {
      initializeBaseline();
    }
    return () => {
      document.removeEventListener('visibilitychange', redirectOnAuthFail);
      window.removeEventListener('focus', redirectOnAuthFail);
    };
  }, []);

  const getBaselineData = () => {
    return baselineManager.getData();
  };

  const values = {
    // store
    initializeBaseline,
    baselineStore,
    updateBaselineStore,
    getBaselineData,
    flushBaselineStorage: () => baselineManager.flush(),
    baselineImages: { ...baselineImages, selectedImageIds: baselineStore.selectedImageIds ?? [] },
    baselineReports,
  };

  return <BaselineContext.Provider value={values}>{children}</BaselineContext.Provider>;
}

export function useBaseline() {
  const values = useContext(BaselineContext);

  return values;
}
