// utilities
import {
  getReportImages,
  saveCompanyLogoToReport,
  saveReportInfo,
  saveImageName,
} from '../../apis/reportAPI';
import { storeUpdatedThermalImage } from '../../apis/imageAPI';
import { getDataFromImage, buildSectionOrder } from './utils';
import { getCurrentDate } from '../../utils/getDateFormat';

// enums
import { reportEvents, reportInfoKeys } from './enums';
import temperatureUnitsEnums from '../../enums/temperatureUnitsEnum';
import filterImageData from './utils/filterImageData';

import DEFAULT_TEMPLATE from './template.json';

const DEFAULT_DATA = { [reportInfoKeys.CREATED_ON]: getCurrentDate() };

const DEFAULT_INFO = {
  [reportInfoKeys.DATA]: DEFAULT_DATA,
  [reportInfoKeys.IMAGES]: [],
  [reportInfoKeys.IMAGE_OPTIONS]: {},
};

export default class ReportManager {
  constructor() {
    this.currentOrganization = '';

    this.reportInfo = { ...DEFAULT_INFO };
    this.template = { ...DEFAULT_TEMPLATE };

    this.filteredImages = {};
    this.images = {};

    this.sections = [];
    this.temperatureUnits = temperatureUnitsEnums.CELSIUS;

    // Initialize default no-op callbacks
    this.callbackAddressBook = {};
  }

  // Register a callback for a specific event name
  setCallback(eventName, callback) {
    // Check if the callback is a valid function
    if (typeof callback !== 'function') {
      console.warn(`Invalid callback for event: ${eventName}`);
      return;
    }

    // Check if eventName is valid
    if (!Object.values(reportEvents).includes(eventName)) {
      console.warn(`Invalid callback event: ${eventName}`);
      return;
    }

    // Register the callback for the event
    this.callbackAddressBook[eventName] = callback;
  }

  // Fire the callback for the given event name
  callback(eventName, data) {
    const callback = this.callbackAddressBook[eventName];
    if (!callback) {
      console.warn(`No callback registered for event: ${eventName}`);
      return;
    }

    switch (eventName) {
      case reportEvents.REPORT_INFO:
        data = this.getReportInfo();
        this.updateMetadata(); // Side-effect update
        break;
      case reportEvents.METADATA:
        // No additional data needed
        break;
      case reportEvents.SECTION_ORDER:
        data = buildSectionOrder(this.reportInfo);
        break;
      case reportEvents.IMAGES:
        data = { ...this.filteredImages };

        break;
      case reportEvents.TEMPLATE:
        data = this.getTemplate();
        break;
      default:
        console.warn(`Unhandled callback event: ${eventName}`);
        return;
    }

    // Invoke the registered callback with the prepared data
    callback?.(data);
  }

  getImageOrder() {
    return this.reportInfo[reportInfoKeys.IMAGES];
  }

  reset() {
    this.reportInfo = { ...DEFAULT_INFO };
    this.template = { ...DEFAULT_TEMPLATE };

    this.filteredImages = {};
    this.images = {};

    this.callback(reportEvents.REPORT_INFO);
  }

  setOrganization(currentOrganization = '') {
    this.currentOrganization = currentOrganization;
  }

  setTemperatureUnits(temperatureUnits = temperatureUnitsEnums.CELSIUS) {
    this.temperatureUnits = temperatureUnits;
  }

  setTemplate(template = {}) {
    this.template = template;

    // Need to update the images object with the new template settings
    this.filteredImages = filterImageData(this.images, template.settings);

    this.callback(reportEvents.TEMPLATE);
    this.callback(reportEvents.IMAGES);
  }

  getTemplate() {
    return { ...this.template };
  }

  syncImageName(imageId, payloadName = null) {
    // get name record from reportInfo image options
    const options = this.reportInfo[reportInfoKeys.IMAGE_OPTIONS][imageId] || {};
    const { name } = options;

    if (payloadName && this.isReportFinalized() === false) {
      // record image name if valid payloadName has changed in draft
      if (payloadName !== name) {
        this.reportInfo[reportInfoKeys.IMAGE_OPTIONS][imageId] = {
          ...options,
          name: payloadName,
        };
      }
    } else {
      // fallback if payload name is null (finalized reports)
      this.updateImagesPayload(imageId, { name });
    }
  }

  updatePayloadImageName(imageId, newName) {
    const image = this.filteredImages[imageId]; // safe op

    // Validate inputs
    if (!imageId || !newName) {
      console.error('Invalid imageId or newName provided.');
      return;
    }

    // Check if the image exists and the name has updated
    if (!image || image.name === newName) {
      console.error(`Image not found! Id: ${imageId}`);
      return;
    }

    // update local payload
    image.name = newName;
    this.callback(reportEvents.IMAGES);

    // save back to image
    saveImageName(imageId, newName, this.currentOrganization);
    // save back to reportInfo as fallback
    this.syncImageName(imageId, newName);
    this.save();
  }

  updateImagesPayload(imageId, payload = {}) {
    if (!imageId) return;

    this.filteredImages[imageId] = {
      ...this.filteredImages[imageId],
      ...payload,
    };

    // Need to make sure the unfilteredImages object is updated as well
    // so that updated information is available when the template is changed
    this.images[imageId] = {
      ...this.images[imageId],
      ...payload,
    };

    this.callback(reportEvents.IMAGES);
  }

  unpackImage(image = {}) {
    const imageId = image.imageId;
    if (imageId == null) return;

    const unpacked = getDataFromImage(imageId, image, this.reportInfo, this.temperatureUnits);
    const payload = {
      _fetchedImage: image,
      ...unpacked,
    };

    this.images[imageId] = {
      ...this.images[imageId],
      ...payload,
    };

    // When initializing the image payload, we need to filter the data
    // based on the template settings. Subsequent filterings will be done
    // when the template is changed
    this.filteredImages = {
      ...this.filteredImages,
      ...filterImageData({ [imageId]: payload }, this.template.settings),
    };

    this.syncImageName(imageId, image.name);
    this.callback(reportEvents.IMAGES);
  }

  async fetchImages(chunkSize = 50) {
    // fetch sequentially
    const imageOrder = this.getImageOrder();

    for (let i = 0; i < imageOrder.length; i += chunkSize) {
      const chunk = imageOrder.slice(i, i + chunkSize);

      const imageResults = await getReportImages(chunk, this.reportInfo, this.currentOrganization);

      imageResults.forEach((image = {}) => {
        this.unpackImage(image);
      });
    }

    // after fetch, save report
    this.save();
  }

  set(reportInfo = {}) {
    this.reportInfo = reportInfo;
  }

  updateMetadata() {
    const {
      [reportInfoKeys.DATA]: data = {},
      [reportInfoKeys.OPTIONS]: options = {},
      [reportInfoKeys.COMPANY_LOGO]: company_logo = null,
    } = this.reportInfo;

    const isFinalized = options.finalized === true;
    const hasCompanyLogo = options.hasCompanyLogo === true;

    this.callback(reportEvents.METADATA, {
      ...data,
      finalized: isFinalized,
      company_logo: hasCompanyLogo ? company_logo : null,
    });
  }

  loadReport(reportInfo = {}) {
    this.set(reportInfo);
    this.fetchImages();

    this.callback(reportEvents.REPORT_INFO);
    this.callback(reportEvents.TEMPLATE);
    this.callback(reportEvents.SECTION_ORDER);
  }

  updateInfoData(next = {}) {
    const { DATA } = reportInfoKeys;

    const prev = this.getInfoData();
    this.reportInfo[DATA] = { ...prev, ...next };
  }

  updateImageOptions(id, next = {}) {
    const { IMAGE_OPTIONS } = reportInfoKeys;

    const imageOptions = this.getImageOptions();
    const prev = this.getImageOptionsById(id);

    this.reportInfo[IMAGE_OPTIONS] = {
      ...imageOptions,
      [id]: { ...prev, ...next },
    };
  }

  updateOptions(next = {}) {
    const { OPTIONS } = reportInfoKeys;

    const prev = this.getOptions();

    this.reportInfo[OPTIONS] = {
      ...prev,
      ...next,
    };
  }

  /**
   * Update the asset tag ID for a given image ID
   * in both the Report Manager and the API
   * @param {*} id - Id for the provided image
   * @param {*} next - New Asset Info Table object to update
   */
  async updateAssetInfo(id, next = {}) {
    if (!id) return;

    const prev = this.getImageOptionsById(id);
    this.updateImageOptions(id, { ...prev, ...next });

    this.updateImagesPayload(id, {
      assetInfo: {
        data: {
          ...next,
        },
      },
    });

    if (
      next?.asset_tag_id != null &&
      next?.asset_tag_id !== prev?.asset_tag_id // Only update if the asset tag ID has changed
    ) {
      await storeUpdatedThermalImage(id, {
        asset_field: next?.asset_tag_id,
      });
    }
  }

  async handleFieldUpdate(id, type, attrs = {}) {
    if (!type) return;

    const { DATA, ASSET_INFO, COMPANY_LOGO } = reportInfoKeys;

    switch (type) {
      case DATA:
        this.updateInfoData(attrs);
        break;

      case ASSET_INFO:
        this.updateAssetInfo(id, attrs);
        break;

      case COMPANY_LOGO:
        await this.saveCompanyLogo(attrs); // Wait for the logo update to complete
        break;

      default:
        console.warn(`Error in field update. Unhandled type: ${type}`);
        return;
    }

    // Save changes and trigger callback after all updates
    this.save();
  }

  isReportFinalized() {
    return this.reportInfo[reportInfoKeys.OPTIONS]['finalized'] === true;
  }

  setFinalized(finalized = true) {
    this.updateOptions({ finalized });
    this.save();
  }

  deleteImageById(imageId) {
    if (!this.reportInfo || !imageId) return false;

    const { IMAGE_OPTIONS, IMAGES = [] } = reportInfoKeys;

    const reportImages = this.reportInfo[IMAGES].filter((id) => id !== imageId);
    delete this.filteredImages[imageId];
    delete this.images[imageId];

    const imageOptions = { ...this.reportInfo[IMAGE_OPTIONS] };
    delete imageOptions[imageId];

    if (
      reportImages.length === this.reportInfo[IMAGES].length &&
      Object.keys(imageOptions).length === Object.keys(this.reportInfo[IMAGE_OPTIONS]).length
    ) {
      return false; // Deletion failed
    }

    this.reportInfo = {
      ...this.reportInfo,
      [IMAGES]: reportImages,
      [IMAGE_OPTIONS]: imageOptions,
    };

    this.callback(reportEvents.SECTION_ORDER);
    this.save();

    return true; // Deletion successful
  }

  async saveCompanyLogo(imageBlob) {
    try {
      const reportId = this.reportInfo?.options?.id;

      if (!this.reportInfo || !reportId) {
        console.warn('Missing report information or report ID.');
        return;
      }

      // Update hasCompanyLogo based on the presence of imageBlob
      const hasCompanyLogo = imageBlob != null;
      this.reportInfo.options.hasCompanyLogo = hasCompanyLogo;

      let company_logo = null;

      if (hasCompanyLogo) {
        // Save the new company logo if imageBlob is provided
        company_logo = await saveCompanyLogoToReport(reportId, imageBlob, this.currentOrganization);
        this.reportInfo.company_logo = company_logo;
      }
    } catch (error) {
      console.error('Failed to save logo', error);
      return error;
    }
  }

  save(currentOrganization) {
    if (!this.reportInfo) return;

    currentOrganization = currentOrganization || this.currentOrganization;
    saveReportInfo(this.reportInfo, currentOrganization);

    this.callback(reportEvents.REPORT_INFO);
  }

  getReportInfo() {
    return { ...this.reportInfo };
  }

  getInfoData() {
    const { DATA } = reportInfoKeys;

    return { ...this.reportInfo[DATA] };
  }

  getImageList() {
    const { IMAGES } = reportInfoKeys;

    return this.getReportInfo()?.[IMAGES] || [];
  }

  getSections() {
    return this.sections.slice();
  }

  getImageOptions() {
    const { IMAGE_OPTIONS } = reportInfoKeys;

    return { ...(this.reportInfo[IMAGE_OPTIONS] || {}) };
  }

  getOptions() {
    const { OPTIONS } = reportInfoKeys;

    return { ...(this.reportInfo[OPTIONS] || {}) };
  }

  getImageOptionsById(id) {
    const imageOptions = this.getImageOptions()?.[id] || {};
    return { ...imageOptions };
  }
}
