const emissivityCommonValues = Object.freeze({
  Aluminum: 30,
  Copper: 78,
  Iron: 70,
  Lead: 44,
  Brick: 93,
  Concrete: 92,
  Wood: 95,
  Glass: 94,
  Water: 96,
  Snow: 83,
});

export default emissivityCommonValues;
