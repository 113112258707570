import React, { useEffect, useState } from 'react';

// components
import { Button, Checkbox, Typography, Divider } from '@common-ui/ui';
import ImageThumbnail from '../../ImageThumbnail';

/**
 * This component will show the images that can be selected in the Image Editor screen
 *
 * @param {Array} selectedImageIds - The selected image ids to be added to a report
 * @param {Array} setSelectedImageIds - The hook to set the selected image ids state
 * @returns
 */
export default function ImagesGrid({ imageState = {}, onChange }) {
  const { order = [], images = {} } = imageState;

  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    onChange?.(selectedImages);
  }, [selectedImages]);

  const handleClearAll = () => {
    setSelectedImages([]);
  };

  const handleSelectAll = () => {
    if (order.length > 0) {
      let nextSelectedImages = [];

      order.forEach((id) => {
        nextSelectedImages.push(id);
      });
      setSelectedImages(nextSelectedImages);
    }
  };

  const handleImageClick = (id) => {
    setSelectedImages((prev) => {
      if (prev.includes(id)) {
        // Remove the ID from the array and remove any null positions
        const updatedData = prev.filter((item) => item !== id).filter(Boolean);
        return updatedData;
      } else {
        // Append the ID to the array
        return [...prev, id];
      }
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.column}>
        <Typography align="center" variant="h5">
          Select Images
        </Typography>
        <Divider />
        <div style={styles.row}>
          <Typography>Select the images you want to add to a report</Typography>
          <div style={styles.row}>
            <Button
              color="secondary"
              onClick={handleSelectAll}
              disabled={selectedImages.length === order.length}
            >
              Select All
            </Button>
            <Button
              color="secondary"
              onClick={handleClearAll}
              disabled={selectedImages.length === 0}
            >
              Clear All
            </Button>
          </div>
        </div>

        <div style={styles.images}>
          {order.map((id) => (
            <div
              key={id}
              style={{
                position: 'relative',
              }}
            >
              <div>
                <Checkbox
                  checked={selectedImages.includes(id)}
                  onChange={() => handleImageClick(id)}
                  style={styles.checkbox}
                />

                <ImageThumbnail imageData={images[id]} style={styles.image} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
    overflowY: 'auto',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  images: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: 8,
    overflowY: 'auto',
    maxHeight: '400px',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
  checkbox: {
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 999,
  },
};
