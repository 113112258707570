// api
import { imageAPI } from '@fluke/baseline-client-apis';
import { saveImageObjects } from '@fluke/irviewer';
import { responseEnum } from '../enums';

/**
 * This function handles saving the group of images.
 *
 * @param {*} imageState - the object of imageObjects to save
 * @param {*} imageMetadata - the object containing assset and sort info for each image
 * @param {*} currentOrganization the current organization
 */
export default async function handleImageSave(
  imageObjects = {},
  imageMetadata = {},
  currentOrganization = '',
  delay = 300,
) {
  let response;
  await saveImageObjects(imageObjects, async (id, file) => {
    if (id && file) {
      await imageAPI
        .storeUpdatedThermalImage(
          id,
          {
            origin_path: imageMetadata[id]?.sort_info?.origin_path ?? '',
            asset_field: imageMetadata[id]?.asset_info?.assetTagId ?? '',
            is2u: new Blob([file], { type: 'application/octet-stream' }),
          },
          currentOrganization,
        )
        .catch((error) => console.error(`Failed to update thermal image for ID ${id}:`, error));
      new Promise((resolve) => setTimeout(resolve, delay));
    } else {
      response = responseEnum.ERROR_SAVING_IMAGES;
    }
  });
  return response;
}
