import React, { createContext, useState } from 'react';
import { Outlet } from 'react-router-dom'; // https://stackoverflow.com/a/75653758
import AlertEnum from '../components/modals/Alert/enums/AlertEnum';
import ModalEnum from '../components/modals/enums/ModalEnum';
import ModalAlert from '../components/modals/Alert/components/ModalAlert';
import AddToReport from '../components/modals/AddToReport';
import { useBaseline } from './BaselineProvider';

/**
 * Child components will be able to use this context to access the modal state
 *
 * @returns {JSX.Element} - The modal context
 */
export const ModalContext = createContext(ModalEnum.DEFAULT);

/**
 * The ModalProvider is wrapped around the entire application
 * to allow for the modal to be opened from anywhere
 *
 * @param {Object} param.children - The children components
 * @returns {JSX.Element} - The modal provider
 */
export default function ModalProvider() {
  const {
    baselineStore: { selectedImageIds = [] },
  } = useBaseline();

  const [modal, setModal] = useState(ModalEnum.DEFAULT);
  const [modalData, setModalData] = useState({});
  const [alert, setAlert] = useState(AlertEnum.DEFAULT);

  function RenderChild() {
    switch (modal.id) {
      case ModalEnum.IMAGE_MANAGEMENT_REPORT_MODAL.id:
        return <AddToReport selectedImageIds={selectedImageIds} theme="dark" />;
      case ModalEnum.REPORTS_CREATE_NEW_REPORT_MODAL.id:
        return <AddToReport selectedImageIds={[]} showBothOptions={false} theme="light" />;
      case ModalEnum.DEFAULT.id:
        return <div />;
    }
  }

  const handleAlert = (newAlert) => {
    setAlert(newAlert);
  };

  const handleModal = (newModal) => {
    setModal(newModal);
  };

  const handleModalData = (data) => {
    setModalData(data);
  };

  const closeModal = () => {
    setModal(ModalEnum.DEFAULT);
  };

  return (
    <ModalContext.Provider
      value={{ modal, modalData, handleModalData, closeModal, handleModal, handleAlert }}
    >
      <RenderChild />
      <ModalAlert alert={alert} setAlert={setAlert} />
      <Outlet />
    </ModalContext.Provider>
  );
}
