/**
 * This function builds image previews grouped by month and year.
 *
 * @param {Array} imageList - The list of images to be grouped. Each image should have a sort_info property with a date_taken value.
 *
 * @returns {Array} `result` - The grouped image previews. Each group has a header (month and year) and an images array.
 */
export function buildImagePreviews(imageList = []) {
  const getDate = (imageDate) => new Date(imageDate);
  const getMonth = (imageDate) => getDate(imageDate).toLocaleString('default', { month: 'long' });
  const getYear = (imageDate) => getDate(imageDate).getFullYear();

  // Initialize an empty array to store the results
  const result = [];

  imageList.forEach((image) => {
    const dateTaken = image?.sort_info?.date_taken;
    if (!dateTaken) return; // Skip if date_taken is missing

    const header = `${getMonth(dateTaken)} ${getYear(dateTaken)}`;

    // Find if an entry with the same header already exists
    let group = result.find((item) => item.header === header);

    // If not found, create a new entry
    if (!group) {
      group = { header, images: [] };
      result.push(group);
    }

    // Add the image to the found or newly created group
    group.images.push(image);
  });

  return result;
}
