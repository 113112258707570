import { columbiaAPI, userStoreAPI } from '@fluke/baseline-client-apis';
import { userKeyStoreDefaults } from '../context/BaselineProvider/enums';

/**
 * fetch current organization from the API.
 *
 * @returns {Promise<string>} - The ID of the current organization, or an empty string if the API call fails or there is no organization.
 */
export async function getCurrentOrganization() {
  try {
    const data = await columbiaAPI.user.getOrganizations();

    const currentOrganization = data?.[0]?.id ?? '';
    return currentOrganization;
  } catch (error) {
    console.warn('Error fetching current organization:', error);
    return '';
  }
}

/**
 * fetch user store data from the API.
 *
 * @returns {Promise<Object>} - fetche user store data, or default object.
 */
export async function fetchUserStore() {
  try {
    const [nextUser, currentOrganization] = await Promise.all([
      userStoreAPI.get(),
      getCurrentOrganization(),
    ]);

    const nextStore = { ...(nextUser ?? userKeyStoreDefaults), currentOrganization };
    return nextStore;
  } catch (error) {
    console.error('Error initializing user store:', error);
    return { ...userKeyStoreDefaults, currentOrganization: '' };
  }
}

/**
 * update user store data.
 *
 * @param {Object} modifiedKeys - The keys to be updated in the user store.
 *
 * @returns {Promise<void>}
 */
export async function updateUserStore(modifiedKeys = {}) {
  try {
    // Create an array of promises for the set operations
    const promises = Object.entries(modifiedKeys).map(([key, value]) => {
      return userStoreAPI.set(key, value); // Assume set is an async operation
    });

    // Await all promises to resolve
    await Promise.all(promises);
  } catch (error) {
    console.error('Error updating user store:', error);
  }
}

/**
 * delete key from the user store.
 *
 * @param {string} key - The key to be deleted from the user store.
 *
 * @returns {Promise<void>}
 */
export async function deleteFromUserStore(key = '') {
  try {
    await userStoreAPI.delete(key);
  } catch (error) {
    console.error('Error deleting from store:', error);
  }
}
