import React from 'react';
import { Typography } from '@common-ui/ui';

import { useBaseline } from '../../../context';

/**
 * Clear the selected images
 *
 * @param {None} None
 * @returns {JSX.Element}
 */
export default function Clear() {
  const {
    baselineImages: { selectedImageIds = [], clearSelectedImages },
  } = useBaseline();
  const disabled = selectedImageIds.length === 0;

  const handleClick = () => {
    if (!disabled) {
      clearSelectedImages();
    }
  };

  const styles = {
    text: (theme) => ({
      color: disabled ? 'grey' : theme.palette.text.primary,
      cursor: disabled ? 'default' : 'pointer',
      '&:hover': !disabled && {
        color: theme.palette.secondary.light,
        transition: '0.3s',
      },
    }),
  };

  return (
    <Typography sx={styles.text} onClick={handleClick}>
      Clear Selection
    </Typography>
  );
}
