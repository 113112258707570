import React from 'react';

import { FlashAuto } from '@mui/icons-material';
import ToolButton from '../../../../../components/buttons/ToolButton';

export default function ToolAutoScale({ value, onClick }) {
  return (
    <div style={styles.flexBox}>
      <ToolButton
        id={'tool-autoscale'}
        tooltip={`Autoscale ${value ? '(active)' : ''}`}
        label="Autoscale"
        onClick={() => onClick?.()}
        active={value}
        Icon={(props) => <FlashAuto {...props} />}
      />
    </div>
  );
}

const styles = {
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
};
