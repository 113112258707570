import React, { useEffect, useState } from 'react';
import { useBlocker } from 'react-router-dom';

// components
import { Modal, Typography, Button, IconButton } from '@common-ui/ui';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { responseEnum } from '../../enums';

const saveModalEnum = {
  TITLE: 'Save Changes',
  PROMPT: 'Do you want to save all changes before exiting the editor?',
  DECLINE: 'Decline',
  ACCEPT: 'Save',
};

const checkTransition = (transition = {}) => {
  const { currentLocation, nextLocation } = transition;
  // block if redirecting
  return currentLocation.pathname !== nextLocation.pathname;
};

/**
 * This component displays a prompt when the user attempts to leave the Image Editor.
 * The prompt asks the user whether they want to keep their recent changes or not.
 *
 * @param {function} onAccept - A function that is invoked when the user accepts to keep the changes.
 * @returns {JSX.Element} - A prompt that appears when the user attempts to navigate away from the Image Editor.
 */
export default function SaveImagesPrompt({ onAccept }) {
  const [showPrompt, setShowPrompt] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const blocker = useBlocker(checkTransition);

  // only block the router when they choose to move to one of the following paths
  useEffect(() => {
    setShowPrompt(blocker.state === 'blocked');
  }, [blocker.state]);

  const handle = {
    cancel: () => {
      blocker.reset();
    },
    decline: () => {
      blocker.proceed();
    },
    accept: async () => {
      setLoading(true);
      const response = await onAccept?.();

      if (response !== responseEnum.ERROR_SAVING_IMAGES) {
        blocker.proceed();
      } else {
        setError(response);
        setLoading(false);
      }
    },
  };

  return (
    <Modal open={showPrompt} onClose={handle.cancel}>
      <div style={styles.container}>
        <div style={styles.content}>
          <Typography variant="h5">{saveModalEnum.TITLE}</Typography>
          <IconButton onClick={handle.cancel}>
            <CloseIcon />
          </IconButton>
        </div>
        <div style={styles.body}>
          <Typography pt={1}>{saveModalEnum.PROMPT}</Typography>
          <Typography color="error">{error}</Typography>
        </div>
        <div style={styles.buttons}>
          <Button
            variant="outlined"
            color="secondary"
            style={{ ...styles.button, ...styles.decline }}
            onClick={handle.decline}
          >
            {saveModalEnum.DECLINE}
          </Button>
          <LoadingButton
            loading={loading}
            style={styles.button}
            sx={styles.loadingIcon}
            variant="contained"
            color="secondary"
            onClick={handle.accept}
          >
            {saveModalEnum.ACCEPT}
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );
}

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30em',
    height: '10em',
    minHeight: '104px',
    maxHeight: '220px',
    boxShadow: 24,
    opacity: 1,
    padding: '24px',
    background: '#232323',
    border: '2px solid #000',
    color: 'white',
  },

  decline: { color: 'white', border: '1px solid rgba(255, 255, 255, 0.5)' },

  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  body: {
    borderTop: '1px solid rgba(255, 255, 255, 0.12)',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },

  button: {
    width: '7rem',
  },

  buttons: {
    display: 'flex',
    gap: 5,
    position: 'absolute',
    bottom: '1.5em',
    right: '1.5em',
  },
  loadingIcon: {
    '.MuiLoadingButton-loadingIndicator': {
      color: 'white',
    },
  },
};
