import React, { useState } from 'react';

// components
import CarouselThumbnail from './CarouselThumbnail';
import SidebarDrawer from '../SidebarDrawer';
import { Typography, Button } from '@common-ui/ui';
import ProgressBar from '../../../../components/ProgressBar';
import { useBaseline } from '../../../../context';

export default function Carousel({ imageState = {}, imageMetadata = {}, onChange }) {
  const {
    baselineImages: { selectedImageIds: totalImageList = [] },
  } = useBaseline();

  const { order = [], images = {}, selectedImageIds = [] } = imageState;

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  // Handler for when an image is clicked (resets to only that image)
  const handleImageClick = (id) => {
    onChange?.([id]);
  };

  // Handler for checkbox change (adds/removes the image ID)
  const handleCheckboxChange = (id) => {
    const isSelected = imageState.selectedImageIds.includes(id);

    const newSelected = isSelected
      ? selectedImageIds.filter((selectedId) => selectedId !== id) // Remove ID if already selected
      : [...selectedImageIds, id]; // Add ID if not already selected

    onChange?.(newSelected);
  };

  const handleSelectAll = () => {
    const newSelection = new Set(selectedImageIds);
    order.forEach((id) => newSelection.add(id));
    onChange?.(Array.from(newSelection));
  };

  const handleDrawerToggle = () => {
    setIsDrawerOpen((prev) => !prev);
  };

  return (
    <SidebarDrawer isOpen={isDrawerOpen} onChange={handleDrawerToggle} width={200} closeWidth={100}>
      <div style={styles.container}>
        {/* SELECTED IMAGE COUNT */}
        <div style={styles.carouselHeader(isDrawerOpen)}>
          <ProgressBar label="Processing" settled={order.length} total={totalImageList?.length} />
          <Typography>{`Selected: ${selectedImageIds.length || 1}`}</Typography>
          <Button
            style={{ width: '100%' }}
            variant="outlined"
            onClick={handleSelectAll}
          >{`Select All (${order.length})`}</Button>
        </div>
        <div style={styles.imagesList}>
          {/* IMAGE PREVIEW LIST */}
          {order.map((id, index) => (
            <CarouselThumbnail
              key={`carousel-image-${index}-${id}`}
              imageData={images?.[id]}
              imageMetadata={imageMetadata?.[id]}
              isPrimary={selectedImageIds[0] === id}
              isBatch={selectedImageIds.includes(id)}
              onPrimary={() => handleImageClick(id)}
              onSecondary={() => handleCheckboxChange(id)}
              disableOverlay={!isDrawerOpen}
            />
          ))}
        </div>
      </div>
    </SidebarDrawer>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '250px',
    overflow: 'hidden',
    padding: '8px',
  },
  carouselHeader: (isDrawerOpen = true) => ({
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    display: isDrawerOpen ? 'flex' : 'none',
  }),
  imagesList: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    marginTop: '8px',
    flex: '1 1 auto', // This makes the imagesList take up any remaining space
  },
  label: {
    whiteSpace: 'nowrap',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    padding: '5px 10px',
    border: '2px solid white',
    borderRadius: '10px', // Adjust the value to control the roundness
  },
};
