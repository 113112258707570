import React from 'react';

import {
  Adjust,
  CircleOutlined,
  GpsFixedOutlined,
  HorizontalRuleOutlined,
  RectangleOutlined,
} from '@mui/icons-material';
import ButtonPopover from './ButtonPopover';
import { List, ListItemText, ListItemIcon, ListItemButton, Divider } from '@common-ui/ui';

/**
 * Button dropdown that displays the available USERDEFINED marker tools for IRVIEWER
 *
 * @param {*} onSelect - callback when dropdown selection is made
 *
 * @returns {JSX.Element} - Dropdown button for USERDEFINED marker shapes
 */
export default function UserdefinedMarkers({ onSelect }) {
  const customMarkers = [
    {
      value: 'Circle',
      label: 'Point',
      Icon: (props) => <Adjust {...props} />,
      disabled: false,
    },
    {
      value: 'Line',
      label: 'Line',
      Icon: (props) => <HorizontalRuleOutlined {...props} />,
      disabled: false,
    },
    {
      value: 'Rect',
      label: 'Box',
      Icon: (props) => <RectangleOutlined {...props} />,
      disabled: false,
    },
    {
      value: 'Ellipse',
      label: 'Ellipse',
      Icon: (props) => <CircleOutlined {...props} />,
      disabled: false,
    },
  ];

  return (
    <ButtonPopover label="Markers" tooltip={'Custom Markers'} Icon={<GpsFixedOutlined />}>
      <List dense>
        <ListItemButton
          sx={styles.button}
          key={`marker-tool-marker-done`}
          onClick={() => onSelect?.('')}
        >
          <ListItemText primary={'Done'} />
        </ListItemButton>
        <Divider component="li" />
        {customMarkers.map(({ value, label, Icon }) => (
          <ListItemButton
            sx={styles.button}
            key={`draw-shape-${value}`}
            onClick={() => onSelect?.(value)}
          >
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
            <ListItemText primary={label} />
          </ListItemButton>
        ))}
      </List>
    </ButtonPopover>
  );
}

const styles = {
  button: { '&:hover': { backgroundColor: 'primary.main', '> *': { color: 'black' } } },
};
