import React, { useState } from 'react';

// enums
import notesContainerTabsEnum from '../../../../enums/notesContainerTabsEnum';

// components
import { Tabs, Tab } from '@common-ui/ui';
import UserNotes from './UserNotes';
import PhotoNotes from './Photonotes';

function TabDiv({ children, display = true }) {
  return <div style={{ display: display === true ? 'block' : 'none' }}>{children}</div>;
}
export default function PanelNotes({ imageData = {}, settings = {}, setSettings }) {
  const [notesContainerTab, setNotesContainerTab] = useState(notesContainerTabsEnum.PHOTO_NOTES);

  return (
    <div style={styles.container}>
      <div style={styles.tabs}>
        <Tabs id="navTabs" activekey={notesContainerTab} value={notesContainerTab}>
          {Object.values(notesContainerTabsEnum)?.map((navTab, index) => (
            <Tab
              label={navTab}
              key={index}
              onClick={() => setNotesContainerTab(navTab)}
              value={navTab}
            />
          ))}
        </Tabs>
      </div>

      <div style={styles.panel}>
        <TabDiv display={notesContainerTab === notesContainerTabsEnum.PHOTO_NOTES}>
          <PhotoNotes
            imageData={imageData}
            settings={settings}
            setSettings={(value) => setSettings(value)}
          />
        </TabDiv>
        <TabDiv display={notesContainerTab === notesContainerTabsEnum.NOTES}>
          <UserNotes settings={settings} setSettings={(value) => setSettings(value)} />
        </TabDiv>
      </div>
    </div>
  );
}

const styles = {
  container: {
    margin: '12px 0',
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid rgb(50,50,50)',
    borderRadius: '12px',
  },
  tabs: {
    display: 'flex',
    justifyContent: 'center',
  },
  panel: {
    padding: '12px',
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
};
