import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// utilities
import { printDocument } from '@fluke/predator-report-lib/lib/utils';
import ExportToWord from '@fluke/predator-report-lib/lib/components/_special/ExportToWord';

// enums
import { RouterPathEnum, ReportEnum } from '../../../enums';

// components
import MarkAsCompleteModal from '../../../components/modals/MarkAsCompleteModal';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Typography,
  Button,
} from '@common-ui/ui';
import {
  AddCircleOutline,
  Edit,
  ArrowBack,
  KeyboardArrowDown,
  PictureAsPdf,
  Description,
  Delete,
} from '@mui/icons-material';
import ButtonTextIcon from '../../../components/buttons/ButtonTextIcon';

/**
 * Secondary Navigation component for the Report screen
 * This component contains the breadcrumb, export, and action buttons
 *
 * @param metadata - The metadata for the report
 * @param onFinalize - The function to be called when the report is finalized
 * @param openTemplateEditor - The function to be called when the template editor is opened
 * @param sectionData - The data for the sections in the report (images)
 * @param sectionOrder - The order of the sections in the report
 * @param template - The template for the report
 *
 * @returns {JSX.Element}
 */
export default function SecondaryNavigation({
  metadata: { report_name = '', finalized = false },
  onFinalize,
  openTemplateEditor,
  sectionData,
  sectionOrder,
  template,
}) {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  return (
    <Box id="secondary-navigation" sx={styles.container}>
      <Box id="breadcrumb-report" sx={styles.group}>
        <Button
          sx={styles.button}
          id="breadcrumb-back-button"
          onClick={() => navigate(RouterPathEnum.REPORTS)}
        >
          <ArrowBack fontSize="small" />
        </Button>
        <Typography id="breadcrumb-return-text" variant="body2">
          {ReportEnum.BACK_TO_REPORTS}
        </Typography>
        <Typography id="breadcrumb-divider" sx={styles.breadcrumbSlash}>
          /
        </Typography>
        <Typography id="breadcrumb-report-name-text" variant="body2">
          {report_name}
        </Typography>
      </Box>
      <Box id="secondary-navigation-buttons" sx={styles.group}>
        <ButtonTextIcon
          id="add-images-button"
          sx={styles.button}
          startIcon={<AddCircleOutline />}
          onClick={() => {
            navigate(RouterPathEnum.IMAGES);
          }}
          disabled={finalized}
          size="small"
        >
          {ReportEnum.ADD_IMAGES}
        </ButtonTextIcon>
        {process.env.REACT_APP_ENV === 'development' && (
          <ButtonTextIcon
            id="report-template-editor-button"
            sx={styles.button}
            onClick={() => openTemplateEditor?.()}
            startIcon={<Edit />}
            size="small"
          >
            {ReportEnum.EDIT_REPORT}
          </ButtonTextIcon>
        )}
        <ButtonTextIcon
          id="report-export-menu-button"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
          sx={styles.button}
          startIcon={<KeyboardArrowDown />}
          size="small"
        >
          {ReportEnum.EXPORT_REPORT}
        </ButtonTextIcon>
        <Menu
          id="report-export-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            setAnchorEl(null);
          }}
        >
          <MenuItem id="report-export-pdf" onClick={() => printDocument(report_name)} dense>
            <ListItemIcon>
              <PictureAsPdf />
            </ListItemIcon>
            <ListItemText>{ReportEnum.EXPORT_PDF}</ListItemText>
          </MenuItem>
          <Divider />
          {process.env.REACT_APP_ENV !== 'production' && (
            <MenuItem id="report-export-word" dense>
              <ListItemIcon>
                <Description />
              </ListItemIcon>
              <ExportToWord
                sectionData={{ order: sectionOrder, data: sectionData }}
                template={template}
                style={{
                  button: styles.exportWordButton,
                }}
                text={ReportEnum.EXPORT_WORD}
              />
            </MenuItem>
          )}
        </Menu>
        {false && (
          <ButtonTextIcon
            id="report-delete-button"
            sx={styles.button}
            startIcon={<Delete />}
            size="small"
          >
            {ReportEnum.DELETE}
          </ButtonTextIcon>
        )}
        {false && (
          <Button color="secondary" variant="outlined" disabled={finalized} size="small">
            {ReportEnum.SAVE_AS_DRAFT_REPORT}
          </Button>
        )}
        <MarkAsCompleteModal finalized={finalized} onAccept={() => onFinalize?.()} />
      </Box>
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: theme.palette.grey[300],
    borderBottom: `1px solid ${theme.palette.grey[500]}`,
    flexBasis: theme.spacing(6),
    padding: `0 ${theme.spacing(1)}`,
  }),
  group: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  }),
  breadcrumbSlash: (theme) => ({
    color: theme.palette.grey[500],
  }),
  button: (theme) => ({
    color: theme.palette.grey[800],
  }),
  exportWordButton: {
    margin: 0,
    fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em',
    display: 'block',
    background: 'none',
    color: 'rgba(0, 0, 0, 0.87)',
    padding: 0,
  },
};
