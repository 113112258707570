import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { ModalContext, useBaseline } from '../../../context';

import AlertEnum from '../Alert/enums/AlertEnum';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  MenuItem,
  Select,
  Typography,
} from '@common-ui/ui';
import { ExpandMore } from '@mui/icons-material';

/**
 * The AddToExistingReport component represents the second step of the report modal.
 * This component allows the user to add selected images to an existing report.
 * @param {string} modalState - The current state of the modal, default is 'AddToReport'.
 * @param {Function} setModalState - Function to update the state of the modal.
 * @param {Array} selectedImageIds - Array of selected image ids that will be added to the report.
 *
 * @returns {JSX.Element} - The AddToExistingReport component, wrapped in an Accordion component.
 * This component consists of a Select drop-down for choosing an existing report, and a Button for adding the selected images to the chosen report.
 */
export default function AddToExistingReport({ modalState, setModalState, selectedImageIds = [] }) {
  const navigate = useNavigate();
  const {
    baselineReports: {
      allReports: { draft = [] } = {},
      setActiveReportById,
      updateReportImages,
      clearActiveReport,
    },
  } = useBaseline();

  const { closeModal, handleAlert } = useContext(ModalContext);
  const [isButtonDisabled, setIsButtonDisabled] = useState(draft.length === 0);

  const [reportId, setReportId] = useState(draft[0]?.report_id ?? '');

  const handleChangeDraftReport = ({ target }) => {
    setReportId(target.value);
  };

  const addImages = async () => {
    setIsButtonDisabled(true);

    const reportInfo = await setActiveReportById(reportId);
    await updateReportImages(reportInfo, selectedImageIds);

    handleAlert(AlertEnum.REPORT_UPDATED);
    closeModal();

    clearActiveReport();
    navigate(`/report/${reportId}`);
  };

  const formEnum = {
    TITLE: 'Add To An Existing Draft Report',
    ADD: 'Add To Report',
  };

  return (
    <Accordion
      expanded={modalState === 'AddToReport'}
      onChange={() => setModalState?.('AddToReport')}
      sx={styles.container}
      square
    >
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Typography variant="h6">{formEnum.TITLE}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.details}>
        <div>
          <Select sx={styles.select} value={reportId} onChange={handleChangeDraftReport}>
            {draft.map((report) => (
              <MenuItem key={report.report_id} value={report.report_id}>
                {report.report_name}
              </MenuItem>
            ))}
          </Select>
          <Button
            variant="contained"
            color="secondary"
            sx={styles.button}
            onClick={addImages}
            disabled={isButtonDisabled}
          >
            {formEnum.ADD}
          </Button>
        </div>
      </AccordionDetails>
    </Accordion>
  );
}

const styles = {
  select: {
    width: 1,
  },
  container: {
    '::before': {
      display: 'none',
    },
    boxShadow: 'none',
    width: 1,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    float: 'right',
    marginY: 2,
  },
};
