import React from 'react';
import TitleContent from './TitleContent';
import { useReportsData } from '../../hooks/useReportsData';

export default function Photonotes({ sectionId = '', contentId = '', settings = {} }) {
  const { data = [] } = useReportsData(sectionId, contentId);

  const { count } = settings[contentId] ?? {};

  const filtered = data.length && count != null ? data.slice(0, count) : data;

  if (filtered.length === 0) return false;

  return (
    <div id={`${sectionId}-photonotes`} style={styles.container}>
      <TitleContent contentId={contentId} settings={settings} />
      <div style={styles.grid}>
        {filtered.map((image, index) => (
          <img
            src={image.src}
            alt={`Image ${index}`}
            key={`photonotes_${sectionId}_${index}`}
            style={styles.image}
          />
        ))}
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 0,
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, minmax(0, 1fr))',
    gap: '10px',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'cover',
  },
};
