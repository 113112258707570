import React, { useState } from 'react';
import { MoreHoriz } from '@mui/icons-material';
import { Menu, MenuItem, ListItemText, ListItemIcon, IconButton } from '@common-ui/ui';

/**
 * A dropdown menu component triggered by an icon button.
 *
 * Props:
 * @param {Array} menuItems - List of menu items with:
 *   - {React.Element} icon (optional): Menu item icon.
 *   - {string} label: Menu item label.
 *   - {function} onClick (optional): Callback on item click.
 * @param {string} align - Horizontal alignment of the menu ('left', 'center', 'right'). Default: 'left'.
 * @param {object} style - Custom styles for the icon button and menu items.
 */
export default function MenuDropdown({ menuItems = [], align = 'left', style = {} }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e, callback) => {
    e.stopPropagation();
    setAnchorEl(null);
    callback?.(e);
  };

  const anchorOrigin = { vertical: 'bottom', horizontal: align };
  const transformOrigin = { vertical: 'top', horizontal: align };

  const styles = {
    icon: {
      ...(style.icon ?? {}),
    },
    listItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
      ...(style.listItem ?? {}),
    },
  };

  return (
    <div className="noprint">
      <IconButton id="dropdown-edit-button" style={styles.icon} onClick={handleClick}>
        <MoreHoriz />
      </IconButton>

      <Menu
        id="dropdown-edit-menu"
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        sx={{ minWidth: '96px' }}
        open={open}
        onClose={(e) => handleClose(e)}
      >
        {menuItems.map(({ icon, label, onClick }, index) => (
          <MenuItem
            key={`dropdown-edit-menu-item_${index}`}
            id={`dropdown-edit-menu-item_${index}`}
            style={styles.listItem}
            onClick={(e) => handleClose(e, onClick)}
            disableRipple
          >
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText>{label}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
