import React, { useState } from 'react';
import { IconButton } from '@common-ui/ui';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

export default function SidebarDrawer({ children, width = 250, closeWidth = 0, isOpen, onChange }) {
  const [drawerState, setDrawerState] = useState({ isOpen: isOpen || true, drawerWidth: width });

  const toggleDrawer = () => {
    setDrawerState((prevState) => {
      const newState = {
        isOpen: !prevState.isOpen,
        drawerWidth: !prevState.isOpen ? width : closeWidth,
      };
      if (onChange) {
        onChange(newState);
      }
      return newState;
    });
  };

  return (
    <div style={{ ...styles.sidebar, ...styles.dynamicWidth(drawerState.drawerWidth) }}>
      <IconButton onClick={toggleDrawer} style={styles.button}>
        {drawerState.isOpen ? <ChevronLeft /> : <ChevronRight />}
      </IconButton>
      <div style={styles.container}>{children}</div>
    </div>
  );
}

const styles = {
  sidebar: {
    position: 'relative',
    backgroundColor: 'rgba(255, 255, 255, 0.05)',
  },
  dynamicWidth: (width = 0) => ({
    width,
    transition: 'width 0.3s',
  }),
  button: {
    position: 'absolute',
    right: '-30px',
  },
  container: {
    overflow: 'hidden',
    display: 'flex',
    height: '100%',
  },
};
