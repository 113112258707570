import React, { useEffect, useState } from 'react';
import { Autocomplete, TextField, Pagination as PaginationComponent } from '@mui/material';

import pageSizeOptionsEnum from '../../../enums/pageSizeOptionsEnum';
import { userKeyStoreEnum } from '../../../context/BaselineProvider/enums/userKeyStoreEnum';
import { sessionKeyStoreEnum } from '../../../context/BaselineProvider/enums/sessionKeyStoreEnum';
import { useBaseline } from '../../../context';
import { InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material';
import SelectImageType from '../../../components/header/PrimaryNavigation/Actions/SelectImageType';
/**
 * Pagination component for the Image Management screen
 * Used to navigate between pages of images
 * Also allows the user to change the number of images displayed per page
 *
 * @returns {JSX.Element}
 */
export default function Pagination() {
  const { baselineStore, updateBaselineStore } = useBaseline();
  const [searchText, setSearchText] = useState('');

  const {
    [sessionKeyStoreEnum.PREVIEW_PAGE_SIZE]: previewPageSize,
    [sessionKeyStoreEnum.PREVIEW_PAGE_COUNT]: previewPageCount,
    [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: previewPageIndex,
  } = baselineStore;

  const pageSizeOptionAsString = [...Object.values(pageSizeOptionsEnum)];
  const defaultPageIndex = 0;

  const updatePage = (newPageIndex) => {
    const newIndex = newPageIndex - 1;
    if (previewPageIndex == newIndex) {
      return;
    }
    updateBaselineStore({ [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: newIndex });
  };

  const updatePageSize = (newPageSize) => {
    if (newPageSize === null) return;

    const newPageSizeNumber = Number(newPageSize);

    if (previewPageSize !== newPageSizeNumber) {
      updateBaselineStore({
        [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: defaultPageIndex,
        [userKeyStoreEnum.PREVIEW_PAGE_SIZE]: newPageSizeNumber,
      });
    }
  };

  const updateSearchText = () => {
    if (searchText !== baselineStore[sessionKeyStoreEnum.SEARCH_TEXT]) {
      updateBaselineStore({ [sessionKeyStoreEnum.SEARCH_TEXT]: searchText });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' || e.key === 'Tab') {
      updateSearchText();
    }
  };

  useEffect(() => {
    setSearchText(baselineStore[sessionKeyStoreEnum.SEARCH_TEXT]);
  }, [baselineStore[sessionKeyStoreEnum.SEARCH_TEXT]]);

  return (
    <div style={styles.content}>
      {process.env.REACT_APP_ENV === 'development' && <SelectImageType />}
      <TextField
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={handleKeyPress}
        placeholder="Keyword Search"
        size="small"
        sx={styles.input}
        color="primary"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" sx={styles.pointer}>
              <Search />
            </InputAdornment>
          ),
        }}
        onBlur={updateSearchText}
      />

      <Autocomplete
        options={pageSizeOptionAsString}
        value={`${previewPageSize}`}
        onChange={(e, value) => updatePageSize(value)}
        renderInput={(params) => (
          <TextField
            {...params}
            color="primary"
            size="small"
            label="Number of Images Displayed"
            sx={styles.input}
          />
        )}
      />

      <PaginationComponent
        showFirstButton
        showLastButton
        boundaryCount={1}
        siblingCount={0}
        count={previewPageCount || 1}
        page={(previewPageIndex || 0) + 1}
        onChange={(e, value) => updatePage(value)}
        color="primary"
        shape="rounded"
        sx={styles.pagination}
      />
    </div>
  );
}

const styles = {
  content: { display: 'flex', alignItems: 'center', gap: '0.75em' },
  pointer: { '& :hover': { cursor: 'pointer' } },
  input: { width: '12em' },
  pagination: { minWidth: '12em' },
};
