import React, { useEffect, useState } from 'react';
import { IconButton } from '@common-ui/ui';
import { Close, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Overlay } from '../../../../components/Overlays';
import { TextField } from '@mui/material';

export default function ImageModal({
  selectedImageIndex = 0,
  images = [],
  open = false,
  ocrText = [],
  onChange,
  onClose,
}) {
  if (selectedImageIndex < 0 || !images || !Array.isArray(images) || images.length === 0) return;

  const [currentImageIndex, setCurrentImageIndex] = useState(selectedImageIndex);

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  useEffect(() => {
    setCurrentImageIndex(selectedImageIndex);
  }, [selectedImageIndex]);

  return (
    <Overlay open={open} onClose={() => onClose?.(currentImageIndex, ocrText)}>
      <div style={styles.modal}>
        <div style={styles.modalContent}>
          <div style={styles.closeIconContainer}>
            <IconButton
              style={{ border: '2px solid white' }}
              onClick={() => onClose?.(currentImageIndex, ocrText)}
            >
              <Close />
            </IconButton>
          </div>
          <div style={styles.imageContainer}>
            <img src={images[currentImageIndex].image} alt="Modal Image" style={styles.image} />
          </div>
          <TextField
            value={ocrText[currentImageIndex] || ''}
            onChange={(e) => onChange(currentImageIndex, e.target.value)}
            multiline
          />
          <div style={styles.navRow}>
            <IconButton style={styles.arrowContainer} onClick={handlePrevImage}>
              <NavigateBefore />
            </IconButton>
            <div>{`${currentImageIndex + 1} / ${images.length}`}</div>
            <IconButton style={styles.arrowContainer} onClick={handleNextImage}>
              <NavigateNext />
            </IconButton>
          </div>
        </div>
      </div>
    </Overlay>
  );
}

const styles = {
  modal: {
    color: 'white',
    fontFamily: 'Roboto',
    display: 'flex',
    alignItems: 'center',
    top: 0,
    left: 0,
    background: '#252525',
    zIndex: 9999,
    minWidth: '300px',
    maxWidth: '90%',
    maxHeight: '90%',
    borderRadius: '25px',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    gap: '8px',
    padding: '8px',
  },
  imageContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
    borderRadius: '4px',
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'end',
  },
  arrowContainer: {
    cursor: 'pointer',
  },
  navRow: {
    display: 'flex',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
