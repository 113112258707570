import React, { useCallback } from 'react';
import SortIcon from '@mui/icons-material/Sort';
import { SortEnum } from '.';
import { sessionKeyStoreEnum } from '../../../../context/BaselineProvider/enums/sessionKeyStoreEnum';
import ButtonTextIcon from '../../../../components/buttons/ButtonTextIcon';
import { useBaseline } from '../../../../context';
import { styled } from '@mui/system';

const { ASCENDING, DESCENDING, SORT } = SortEnum;

/**
 * Sort Component used to sort images in ascending or descending order
 *
 * @returns {JSX.Element}
 */
export default function Sort() {
  const {
    baselineStore: { [sessionKeyStoreEnum.SORTED]: sorted },
    updateBaselineStore,
  } = useBaseline();

  const isDescending = sorted === DESCENDING;

  const checkSortedOrder = useCallback(() => {
    const newSorted = isDescending ? ASCENDING : DESCENDING;
    updateBaselineStore({ [sessionKeyStoreEnum.SORTED]: newSorted });
  }, [sorted]);

  const Icon = styled(SortIcon)`
    transform: ${isDescending ? 'scaleY(-1)' : 'initial'};
  `;

  return (
    <ButtonTextIcon icon={<Icon />} onClick={checkSortedOrder} tooltip={SORT}>
      {SortEnum.SORT}
    </ButtonTextIcon>
  );
}
