import React, { useCallback } from 'react';

// context
import { useBaseline } from '../../context';
import { sessionKeyStoreEnum } from '../../context/BaselineProvider/enums/sessionKeyStoreEnum';

// components
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@common-ui/ui';
import { ExpandMore } from '@mui/icons-material';

const {
  SELECTED_YEAR,
  SELECTED_MONTH,
  ACTIVE_HEADER,
  ACTIVE_QUERY,
  ACTIVE_CHILD,
  SEARCH_ID,
  PREVIEW_PAGE_COUNT,
  PREVIEW_PAGE_INDEX,
} = sessionKeyStoreEnum;

const DEFAULT_SIDEBAR = [
  {
    count: 0,
    name: 'All Images',
    children: [],
  },
  {
    count: 0,
    name: 'Last Uploaded',
    children: [],
  },
];

/**
 * Sidebar component for the Image Management screen
 * @returns {JSX.Element}
 */
export default function SideBar() {
  let {
    baselineStore: {
      [sessionKeyStoreEnum.SIDEBAR_DATES]: sidebarDates = [],
      [sessionKeyStoreEnum.SELECTED_YEAR]: selectedYear,
      [sessionKeyStoreEnum.SELECTED_MONTH]: selectedMonth,
    },
    updateBaselineStore,
  } = useBaseline();

  if (!sidebarDates || sidebarDates.length === 0) {
    sidebarDates = DEFAULT_SIDEBAR;
  }

  const handleNextQuery = useCallback(
    (e, period = {}, isYear = true) => {
      e.stopPropagation();

      const updatedStore = {
        [ACTIVE_HEADER]: period.name,
        [ACTIVE_QUERY]: period.query,
        [ACTIVE_CHILD]: period.count,
        [SEARCH_ID]: Date.now(),
        [PREVIEW_PAGE_COUNT]: 1,
        [PREVIEW_PAGE_INDEX]: 0,
      };

      if (isYear) {
        updatedStore[SELECTED_YEAR] = period.name;
        updatedStore[SELECTED_MONTH] = null;
      } else {
        updatedStore[SELECTED_MONTH] = period.name;
      }

      updateBaselineStore(updatedStore);
    },
    [updateBaselineStore],
  );

  const isSelectedStyles = useCallback(
    (theme, isSelected) => ({
      backgroundColor: isSelected ? theme.palette.primary.main : '',
      color: isSelected ? 'black' : '',
      cursor: 'pointer',
      '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
        color: isSelected ? 'black' : '',
      },
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'black',
        transition: '250ms',
        '& .MuiAccordionSummary-expandIconWrapper .MuiSvgIcon-root': {
          color: 'black',
        },
      },
    }),
    [],
  );

  return (
    <div style={styles.container}>
      {sidebarDates.map((year = {}, index) => (
        <Accordion
          key={index}
          expanded={year.name === selectedYear}
          onClick={(e) => handleNextQuery(e, year, true)}
          sx={styles.accordion}
        >
          <AccordionSummary
            sx={(theme) => isSelectedStyles(theme, selectedYear === year.name)}
            expandIcon={<ExpandMore />}
          >
            <div style={styles.dateItem}>
              <Typography>{year.name}</Typography>
              <Typography>{year.count}</Typography>
            </div>
          </AccordionSummary>

          {/* months */}
          {(year.children ?? []).map((month = {}, index) => (
            <AccordionDetails
              key={index}
              sx={(theme) => isSelectedStyles(theme, selectedMonth === month.name)}
              onClick={(e) => handleNextQuery(e, month, false)}
            >
              <div style={styles.dateItem}>
                <Typography>{month.name}</Typography>
                <Typography>{month.count}</Typography>
              </div>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </div>
  );
}

const styles = {
  container: {
    gridArea: 'sidebar',
    minHeight: '100%',
    overflowY: 'auto',
  },
  accordion: {
    background: '#343434',
    position: 'relative',
    top: '-1px',
  },
  dateItem: {
    height: '100%',
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
};
