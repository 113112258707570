import React, { useMemo } from 'react';

// utils
import {
  settingsKeys,
  flatMarkerArray,
  valueToDisplayTemperature,
  deltaToDisplayTemperature,
} from '@fluke/irviewer';

// components
import { TableCell, Paper, Table, TableHead, TableRow, TableBody } from '@mui/material';
import { Radio } from '@common-ui/ui';

export default function MarkerInfo({ imageData = {}, settings, setSettings }) {
  const { metadata = {} } = imageData;
  const {
    [settingsKeys.REFERENCE_MARKER_ID]: referenceMarkerId,
    [settingsKeys.TEMPERATURE_UNITS]: temperatureUnits,
  } = settings;

  const markerArray = flatMarkerArray(metadata.MARKERS ?? {});

  const getMarkerById = (id = '', markersArray = []) => {
    return markersArray.find((marker) => marker.id === id) ?? {};
  };

  const markerTableHeaders = ['Marker Name', 'Max Temp', 'Delta Temp', 'Reference Temp', 'Action'];

  const referenceTemp =
    useMemo(() => {
      const referenceMarker = getMarkerById(referenceMarkerId, markerArray);
      const { data: { characteristics = {} } = {} } = referenceMarker;
      const { markerTempC_Max, markerTempC_Average } = characteristics;
      const temperature = markerTempC_Max ?? markerTempC_Average ?? 0;
      return temperature;
    }, [referenceMarkerId, markerArray]) ?? 0;

  const handleLevelSpanTemp = (min, max) => {
    const numericMin = Number(min);
    const numericMax = Number(max);

    if (isNaN(numericMin) || isNaN(numericMax)) {
      console.error('Invalid input: min and max must be numbers.');
      return;
    }

    setSettings({
      [settingsKeys.PALETTE_RANGE_MIN]: numericMin,
      [settingsKeys.PALETTE_RANGE_MAX]: numericMax,
    });
  };

  return (
    <Paper>
      <div style={styles.table}>
        <Table size="small">
          <TableHead style={styles.header}>
            <TableRow>
              {markerTableHeaders.map((header, index) => (
                <TableCell key={index}>{header}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {markerArray.map(({ id, data = {} }, index) => {
              const {
                characteristics: {
                  markerName = '',
                  markerTempC_Min,
                  markerTempC_Max,
                  markerTempC_Average,
                  markerEnabledFlag = false,
                } = {},
              } = data;

              const isReference = id === referenceMarkerId;
              const deltaTemperature = isReference
                ? null
                : (markerTempC_Max ?? markerTempC_Average) - referenceTemp;

              return (
                <TableRow
                  id={`row-${index}-${id}`}
                  key={`row-${index}-${id}`}
                  style={{ display: markerEnabledFlag === true ? 'table-row' : 'none' }}
                >
                  <TableCell>{markerName}</TableCell>
                  <TableCell>
                    {valueToDisplayTemperature(
                      markerTempC_Max ?? markerTempC_Average,
                      temperatureUnits,
                    )}
                  </TableCell>
                  <TableCell sx={styles.delta(deltaTemperature)}>
                    {isReference || referenceMarkerId === ''
                      ? '---'
                      : deltaToDisplayTemperature(deltaTemperature, temperatureUnits)}
                  </TableCell>
                  <TableCell>
                    <Radio
                      checked={isReference}
                      onChange={() => setSettings?.({ referenceMarkerId: id })}
                      size="small"
                    />
                  </TableCell>
                  <TableCell>
                    {!data.markerPixelIndex ? (
                      <button onClick={() => handleLevelSpanTemp(markerTempC_Min, markerTempC_Max)}>
                        Set Span
                      </button>
                    ) : null}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </div>
    </Paper>
  );
}

const styles = {
  table: {
    height: '400px',
    overflow: 'auto',
  },
  header: {
    position: 'sticky',
    top: '0',
    background: 'rgb(18, 18, 18)',
    zIndex: 10,
  },
  buttonDelete: {
    fontSize: '14px',
    color: 'white',
    p: 0,
  },
  delta: (deltaTemperature) => ({
    color:
      deltaTemperature > 0
        ? '#1EEEE1' // Positive
        : deltaTemperature < 0
        ? '#FF994F' // Negative
        : null, // Indifferent
  }),
};
