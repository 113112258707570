import React from 'react';

// utilities
import { useSettings, settingsKeys } from '@fluke/irviewer';

// components
import { ToolFusion, ToolPip, ToolAutoScale, ToolRotate, ToolMarkerVisible } from './Tools';
import StandardMarkers from './StandardMarkers';
import UserdefinedMarkers from './UserdefinedMarkers';

// import Annotations from './Annotations';

export default function Toolbar({ settings = {}, setSettings }) {
  const [localSettings, handleUpdate] = useSettings(settings, setSettings);

  return (
    <div id="toolbar-container" style={styles.center}>
      <div id="toolbar" style={styles.toolbar}>
        <ToolFusion
          value={localSettings[settingsKeys.ALPHA_LEVEL]}
          onChange={(value) => handleUpdate({ [settingsKeys.ALPHA_LEVEL]: value })}
        />
        <ToolPip
          value={localSettings.pipMode}
          onClick={() => handleUpdate({ [settingsKeys.PIP_MODE]: localSettings.pipMode ^ 1 })}
        />
        <ToolAutoScale
          value={localSettings.autoscale}
          onClick={() => handleUpdate({ [settingsKeys.AUTOSCALE]: !localSettings.autoscale })}
        />
        <ToolRotate
          value={localSettings[settingsKeys.ROTATION]}
          onChange={(value) => handleUpdate({ [settingsKeys.ROTATION]: value })}
        />
        {/* <Annotations onClick={onActiveShapeUpdate} /> */}
        <UserdefinedMarkers onSelect={(value) => handleUpdate({ currentDrawShape: value })} />
        <StandardMarkers
          value={localSettings[settingsKeys.STANDARD_MARKERS]}
          options={['Hotpoint', 'Coldpoint', 'Centerpoint', 'Centerbox']}
          onSelect={(value) => handleUpdate({ [settingsKeys.STANDARD_MARKERS]: value })}
        />
        <ToolMarkerVisible
          value={localSettings.globalMarkerVisible}
          onClick={() =>
            handleUpdate({
              [settingsKeys.GLOBAL_MARKER_VISIBLE]: !localSettings.globalMarkerVisible,
            })
          }
        />
      </div>
    </div>
  );
}

const styles = {
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  separator: {
    display: 'flex',
    height: '24px',
    width: '2px',
    borderLeft: '1px solid white',
  },
};
