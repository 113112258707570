import React, { useState } from 'react';
import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import routerPathEnum from './enums/routerPathEnum';
import signInEnum from './enums/signInEnum';
import {
  Assets,
  Logout,
  Loading,
  ImageManagement,
  ImageEditor,
  Reports,
  ReportView,
  Error,
  ErrorBoundary,
  MerLin,
} from '.';
import DarkTheme from './styles/DarkTheme';
import LightTheme from './styles/LightTheme';
import {
  ThemeProvider,
  lightTheme,
  CreateAccount,
  Verification,
  ForgotPassword,
  SignIn,
} from '@common-ui/ui';

import { BaselineProvider, ModalProvider } from './context';

import { SSOComponent, signInWithUsername, signInWithEmail, signUp, resetPassword } from 'fc-sso';
import { Layout } from './screens/Layout';
import { initApiConfiguration as initBaselineClientApis } from '@fluke/baseline-client-apis';
import { baseURLEnum } from '@fluke/baseline-client-apis';
import logger from './utils/logger';

export default function App() {
  const [isSSOReady, setIsSSOReady] = useState(false);
  const [responseObject, setResponseObject] = useState(null);

  let baseURLS = {};
  baseURLS[baseURLEnum.USER_STORE] = process.env.REACT_APP_USER_STORE_URL;
  baseURLS[baseURLEnum.IMAGE_ACCESS] = process.env.REACT_APP_BASE_API_URL;
  baseURLS[baseURLEnum.REPORT_ACCESS] = process.env.REACT_APP_REPORT_API_URL;
  baseURLS[baseURLEnum.VISOR_API] = process.env.REACT_APP_VISOR_API_BASE_URL;
  baseURLS[baseURLEnum.COLUMBIA] = process.env.REACT_APP_COLUMBIA_API_BASEURL;
  baseURLS[baseURLEnum.COLUMBIA_INFO] = process.env.REACT_APP_BASELINE_COLUMBIA_INFO_API_URL;
  baseURLS[baseURLEnum.BASELINE_JWT_SERVICE] = process.env.REACT_APP_BASELINE_JWT_URL;

  initBaselineClientApis(
    process.env.REACT_APP_REGION_COLUMBIA,
    process.env.REACT_APP_USER_POOL_ID_COLUMBIA,
    process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID_COLUMBIA,
    baseURLS,
    logger,
  );

  // when the SSO component is ready
  const handleInitDone = async (responseObject) => {
    setIsSSOReady(true);
    setResponseObject(responseObject);
  };

  // when the SSO component encounters an error, log it
  const handleError = (error) => {
    console.error('The SSO component resulted in the following error: ', error);
  };

  // when the SSO component times out, return to logout
  const handleTimeout = () => {
    const { ROOT, LOGIN, LOGOUT, LOADING } = routerPathEnum;
    const { pathname = '' } = window.location;

    if (![ROOT, LOGIN, LOGOUT, LOADING].includes(pathname)) {
      // if not logging in, return to logout
      // window.location.replace(routerPathEnum.LOGOUT);
    }
  };

  let modeProperty = {};
  if (process.env.REACT_APP_ENV === 'production') {
    modeProperty = { Mode: 'SSO' };
  }

  const routes = [
    {
      path: routerPathEnum.ROOT,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={lightTheme}>
            <SignIn
              product={signInEnum.BASELINE}
              description={signInEnum.APPLICATION_INFO}
              createAccountPath={routerPathEnum.REGISTER}
              forgotPasswordPath={routerPathEnum.FORGOT_PASSWORD}
              onSignInRedirect={routerPathEnum.LOADING}
              responseObject={responseObject}
              onResponseObjectRedirect={routerPathEnum.LOADING}
              signInWithUsername={signInWithUsername}
              signInWithEmail={signInWithEmail}
              signUp={signUp}
              isSSOReady={isSSOReady}
              Mode={'SSO'}
              {...modeProperty}
            />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },

    //Unfound path redirects to the root.
    {
      path: '*',
      element: <Navigate to={routerPathEnum.ROOT} replace />,
    },
    {
      path: routerPathEnum.IMAGES,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={DarkTheme}>
            <ImageManagement />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    process.env.REACT_APP_ENV === 'development' && {
      path: routerPathEnum.ASSETS,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={LightTheme}>
            <Assets />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },

    {
      path: routerPathEnum.REPORTS,
      element: (
        <ErrorBoundary>
          <Reports />
        </ErrorBoundary>
      ),
    },

    {
      path: routerPathEnum.MERLIN,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={DarkTheme}>
            <MerLin />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    {
      path: `${routerPathEnum.REPORT}/:id`,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={LightTheme}>
            <ReportView />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.LOADING,
      element: (
        <ErrorBoundary>
          <Loading />
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.LOGOUT,
      element: (
        <ErrorBoundary>
          <Logout />
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.REGISTER,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={lightTheme}>
            <CreateAccount
              product={signInEnum.BASELINE}
              signInPath={'/'}
              signUp={signUp}
              isSSOReady={isSSOReady}
              Mode={'SSO'}
            />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.VERIFY_EMAIL,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={lightTheme}>
            <Verification product={signInEnum.BASELINE} />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.FORGOT_PASSWORD,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={lightTheme}>
            <ForgotPassword resetPassword={resetPassword} signInPath={routerPathEnum.ROOT} />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.IMAGE_EDITOR,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={DarkTheme}>
            <ImageEditor />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.FOUR_OH_FOUR,
      element: (
        <ErrorBoundary>
          <ThemeProvider theme={lightTheme}>
            <Error />
          </ThemeProvider>
        </ErrorBoundary>
      ),
    },
    {
      path: routerPathEnum.FIVE_OH_FIVE,
      element: (
        <ThemeProvider theme={lightTheme}>
          <Error />
        </ThemeProvider>
      ),
    },
  ];

  // https://stackoverflow.com/a/75653758
  const contextWrappedRoutes = [
    {
      element: (
        <BaselineProvider>
          <Layout>
            <ModalProvider />
          </Layout>
        </BaselineProvider>
      ),
      children: [...routes],
    },
  ];

  const router = createBrowserRouter(contextWrappedRoutes);

  return (
    <SSOComponent onInitDone={handleInitDone} onError={handleError} onTimeOut={handleTimeout}>
      <ThemeProvider theme={LightTheme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </SSOComponent>
  );
}
