import { ImageType } from '../../../enums';
export const userKeyStoreEnum = Object.freeze({
  CURRENT_ORGANIZATION: 'currentOrganization',
  SHOW_ORGANIZATION: 'showOrganization',
  TEMPERATURE_UNITS: 'temperatureUnits',
  PREVIEW_PAGE_SIZE: 'previewPageSize',
  IMAGE_TYPE: 'imageType',
  MERLIN_SMART_SEARCH: 'merlinSmartSearch',
});

const userKeyStoreDefaults = new Object({
  [userKeyStoreEnum.CURRENT_ORGANIZATION]: '',
  [userKeyStoreEnum.SHOW_ORGANIZATION]: 'false',
  [userKeyStoreEnum.TEMPERATURE_UNITS]: 'F',
  [userKeyStoreEnum.PREVIEW_PAGE_SIZE]: 10,
  [userKeyStoreEnum.IMAGE_TYPE]: ImageType.THERMAL,
  [userKeyStoreEnum.MERLIN_SMART_SEARCH]: false,
});

export default userKeyStoreDefaults;
