import moment from 'moment';
import isoLanguageCodeEnum from '../enums/isoLanguageCodeEnum';

export const DateFormats = Object.freeze({
  LOCAL: 'L',
  YMD: 'YYYY-MM-DD',
  YMD_HMS: 'YYYY-MM-DD hh:mm:ss A',
});

const DateTypes = Object.freeze({
  SHORT: 'short',
  NUMERIC: 'numeric',
  LONG: 'long',
});

const DateTimeZones = Object.freeze({
  UTC: 'UTC',
  PST: 'America/Los_Angeles',
});

function getClientLocale() {
  if (typeof Intl !== 'undefined') {
    try {
      return Intl.NumberFormat().resolvedOptions().locale;
    } catch (err) {
      return navigator.languages && navigator.languages.length
        ? navigator.languages[0]
        : navigator.language;
    }
  }
}

export function getCurrentDate() {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 because getMonth() returns zero-based month index
  const day = String(currentDate.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const timeFormatter = (timezone) => {
  return new Intl.DateTimeFormat(getClientLocale(), {
    timeStyle: DateTypes.LONG,
    dateStyle: DateTypes.SHORT,
    timeZone: timezone,
  });
};

const dateOnlyFormatter = (timezone) => {
  return new Intl.DateTimeFormat(getClientLocale(), {
    dateStyle: DateTypes.SHORT,
    timeZone: timezone,
  });
};

export const getDateTimeFromMillisecondsTZ = (ms, timezone) => {
  const date = new Date(ms || 0);
  if (timezone === undefined || timezone === null) {
    timezone = DateTimeZones.UTC;
  }
  return timeFormatter(timezone).format(date);
};

export const getDateWithTimeZone = (ms, timezone) => {
  const date = new Date(ms);
  if (timezone === undefined || timezone === null) {
    timezone = DateTimeZones.UTC;
  }
  return dateOnlyFormatter(timezone).format(date);
};

export function formatDateTimeWithTimeZone(timestamp, timezone = DateTimeZones.PST) {
  if (timestamp == null) return '';

  const date = new Date(timestamp);
  const options = {
    year: DateTypes.NUMERIC,
    month: DateTypes.NUMERIC,
    day: DateTypes.NUMERIC,
    hour: DateTypes.NUMERIC,
    minute: DateTypes.NUMERIC,
    second: DateTypes.NUMERIC,
    hour12: true,
    timeZone: timezone,
    timeZoneName: DateTypes.SHORT,
  };

  // D/M/YYYY, hh:mm:ss AM PST
  const formattedDate = new Intl.DateTimeFormat(isoLanguageCodeEnum.EN_US, options).format(date);
  return formattedDate;
}
/**
 * Get the Date in the format specified
 * @param {*} format
 * @param {*} date
 * @returns date in the format DD/MM/YYYY
 */
export const getDate = (format, date) => {
  try {
    const formattedDate = date ? moment.utc(date).format(format) : moment.utc().format(format);
    return formattedDate;
  } catch (error) {
    console.error('Error formatting date:', error.message);
    return '';
  }
};
