import React, { useMemo } from 'react';

import { emissivityCommonValues } from '../../../../enums';
import { getNormalizedValueClamped, round } from '@fluke/irviewer';

import { TextField, Select, Typography, InputAdornment, MenuItem, Box } from '@common-ui/ui';

const CHAR_EPSILON = 'ε';

export default function IRImageEmissivity({ value = 1, onChange = null }) {
  const handleChange = (evt) => {
    const inputValue = evt.target.value;
    const normalizedValue = getNormalizedValueClamped(inputValue);

    onChange?.(normalizedValue);
  };

  const commonEmissivityValues = useMemo(() =>
    Object.entries(emissivityCommonValues)
      .map(([label, value]) => ({ label, value }))
      .sort((a, b) => b.value - a.value),
  );

  return (
    <TextField
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 0.1 }}
      type="number"
      id="emissivity_input"
      label="Emissivity"
      value={round(value * 100, 3)}
      onChange={handleChange}
      sx={styles.input}
      InputProps={{
        startAdornment: <InputAdornment position="start">{`${CHAR_EPSILON} =`}</InputAdornment>,
        endAdornment: (
          <InputAdornment position="end">
            <Box sx={styles.endAdornmentBox}>
              <Typography variant="subtitle1" style={styles.percentAdornment}>
                %
              </Typography>
              <Select
                value=""
                onChange={handleChange}
                variant="standard"
                renderValue={() => null}
                sx={styles.dropdown}
                MenuProps={styles.menu}
              >
                {commonEmissivityValues.map(({ label, value }) => (
                  <MenuItem key={label} value={value}>
                    <div style={styles.menuItem}>
                      <Typography fontWeight="bold">{label}</Typography>
                      <Typography>{value}</Typography>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
}

const styles = {
  endAdornmentBox: {
    display: 'flex',
    alignItems: 'center',
  },
  percentAdornment: {
    padding: '14px',
    color: 'rgba(255, 255, 255, 0.7)',
  },
  input: {
    '& .MuiInputBase-root': {
      justifyContent: 'space-between',
    },
  },
  dropdown: {
    borderLeft: '1px dashed grey',
    paddingLeft: 2,
    '& .MuiSelect-select': {
      backgroundColor: 'transparent',
      padding: '0',
    },
    '&:hover, &.Mui-focused': {
      border: '1px solid white',
      borderRadius: '4px',
    },
    '&:before, &:after': {
      display: 'none',
    },
  },

  menu: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    PaperProps: {
      style: {
        width: '200px',
      },
    },
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
};
