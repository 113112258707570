/**
 * Filter marker info data based on template settings.
 *
 * @param {Object} markerInfo image marker info to filter
 * @property {string} name name of the marker info section
 * @property {Object} data data to filter
 * @property {Object} data.header header data for marker info table
 * @property {Array<Array<string>>} data.rows rows of data for marker info table
 * @param {Object} settings Template settings object
 */
function filterMarkerInfoData(markerInfo, markerInfoSettings) {
  // Association of column names to their respective settings key in the settings object
  const markerInfoColumnSettingsMap = {
    ΔT: 'showDelta',
    Reference: 'showReferenceTemperature',
  };

  const { count, name } = markerInfoSettings;
  const { data } = markerInfo;
  const hiddenFields = Object.keys(markerInfoColumnSettingsMap).filter(
    (field) => markerInfoSettings[markerInfoColumnSettingsMap[field]] === false,
  );


  const headers = (data?.header ?? []).filter((header) => !hiddenFields.includes(header));
  const rows = (data?.rows ?? []).map((row) =>
    row.filter((_, index) => !hiddenFields.includes(data.header[index])),
  );

  const filteredData = {
    header: headers,
    rows: count != null ? rows.slice(0, count) : rows,
  };

  const newMarkerInfo = {
    name,
    data: filteredData,
  };

  return newMarkerInfo;
}

/**
 * Filter images based on template settings. This will return a deep clone of the images object with its data filtered.
 *
 * @param {Object} images Object containing image data (keyed by image ID)
 * @param {Object} settings Template settings object
 */
export default function filterImageData(images, settings = {}) {
  // 1. Make deep clone of images object to avoid mutating the original object
  const imagesDeepClone = JSON.parse(JSON.stringify(images));

  // 2. Iterate over each image
  Object.keys(imagesDeepClone).forEach((key) => {
    const image = imagesDeepClone[key];

    // 3. Get filtered data
    const filteredMarkerInfo = filterMarkerInfoData(
      image?.markerInfo ?? {},
      settings?.markerInfo ?? {},
    );

    // 4. Update image object with filtered data
    image.markerInfo = filteredMarkerInfo;
  });

  return imagesDeepClone;
}
