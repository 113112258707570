import { useContext } from 'react';
import { ReportContext } from '..';

/**
 * Custom hook to get report data
 * This hook is used only for the sidebar and document components within report view
 * to avoid prop drilling
 *
 * @param {*} sectionId - image object key used to identify each page as a section
 * @param {*} contentId - content type of the section (assetInfo, imageVL, markerInfo, etc)
 * @returns {Object}
 */
export function useReportsData(sectionId, contentId) {
  const { reportInfo = {}, images = {}, ...rest } = useContext(ReportContext);

  const getContent = () => {
    if (sectionId && contentId) {
      const { data } = images[sectionId]?.[contentId] ?? {};
      return data;
    }
  };

  const getName = () => {
    return images[sectionId]?.name;
  };

  const getReportFinalized = () => {
    return reportInfo.options?.finalized === true;
  };

  return {
    reportInfo,
    images,
    name: getName(),
    data: getContent(),
    disabled: getReportFinalized(),
    ...rest,
  };
}
