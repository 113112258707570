import React, { useEffect, useState } from 'react';

// utilities
import { DateFormats, getDate } from '../../../../utils/getDateFormat';
import { useReportsData } from '../../hooks/useReportsData';

// enums
import imageStatusNamesEnum from '../../../../enums/imageStatusNamesEnum';
import {
  reportInfoKeys,
  assetFormKeys,
  tableAssetKeys,
  tableAssetStateKeys,
  reportActions,
} from '../../enums';

// components
import { DataTable, FieldInput, FieldTextArea } from '@fluke/predator-report-lib';

// mui
import {
  CheckCircle,
  ErrorOutline,
  CancelOutlined,
  SkipNextOutlined,
  RadioButtonUnchecked,
} from '@mui/icons-material';
import TitleContent from './TitleContent';

const getStatus = (status) => {
  const response = Object.keys(imageStatusNamesEnum)[status];
  if (response === tableAssetStateKeys.NO_STATE) return tableAssetStateKeys.DESCRIPTION;
  else return response;
};

const StatusIcon = ({ status }) => {
  let color = getStatus(status);

  switch (color) {
    case '1':
      return <CheckCircle style={{ color: '#28a745' }} />;
    case '2':
      return <ErrorOutline style={{ color: '#ffc107' }} />;
    case '3':
      return <CancelOutlined style={{ color: '#dc3545' }} />;
    case '4':
      return <SkipNextOutlined style={{ color: '#007bff' }} />;
    default:
      return <RadioButtonUnchecked style={{ color: 'gray' }} />;
  }
};

const DEFAULT_VALUES = {
  // [assetFormKeys.IMAGE_ID]: '', // careful, this could clear important info
  // [assetFormKeys.NAME]: '', // careful, this could clear important info
  [assetFormKeys.DATE_TAKEN]: '',
  [assetFormKeys.SITE_ID]: '',
  [assetFormKeys.EQUIPMENT]: '',
  [assetFormKeys.RECOMMENDATION]: '',
  [assetFormKeys.STATUS_CODE]: 0,
  [assetFormKeys.STATUS_TEXT]: '',
  [assetFormKeys.REASON_TEXT]: '',
  [assetFormKeys.ASSET_TAG_ID]: '',
};

export default function AssetInfo({ sectionId, contentId, settings = {} }) {
  const {
    data = { ...DEFAULT_VALUES },
    disabled = false,
    handleAction,
  } = useReportsData(sectionId, contentId);

  const [localValue, setLocalValue] = useState(data);

  useEffect(() => {
    if (JSON.stringify(data) !== JSON.stringify(localValue)) {
      setLocalValue(data);
    }
  }, [data]);

  const handleChange = (e) => {
    const { id = '', value = '' } = e.target || {};
    setLocalValue((prev) => ({ ...prev, [id]: value }));
  };

  const handleSave = () => {
    const { ASSET_INFO } = reportInfoKeys;
    handleAction(reportActions.FIELD_UPDATE, sectionId, ASSET_INFO, localValue);
  };

  const table = [
    {
      header: [
        tableAssetKeys.DATE,
        tableAssetKeys.LOCATION,
        tableAssetKeys.EQUIPMENT,
        tableAssetKeys.ASSET_ID,
        tableAssetKeys.STATUS,
        tableAssetKeys.REASON,
      ],
      rows: [
        [
          <span id="imageDate" key="dateTaken">
            {getDate(DateFormats.LOCAL, localValue.date_taken)}
          </span>,

          <FieldInput
            key={assetFormKeys.SITE_ID}
            id={assetFormKeys.SITE_ID}
            value={localValue[assetFormKeys.SITE_ID]}
            onChange={handleChange}
            onBlur={handleSave}
            disabled={disabled}
          />,
          <FieldInput
            key={assetFormKeys.EQUIPMENT}
            id={assetFormKeys.EQUIPMENT}
            value={localValue[assetFormKeys.EQUIPMENT]}
            onChange={handleChange}
            onBlur={handleSave}
            disabled={disabled}
          />,

          <FieldInput
            key={assetFormKeys.ASSET_TAG}
            id={assetFormKeys.ASSET_TAG_ID}
            value={localValue[assetFormKeys.ASSET_TAG_ID]}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleSave}
          />,
          <span key={assetFormKeys.STATUS_TEXT} title={localValue[assetFormKeys.STATUS_TEXT]}>
            <div style={styles.status}>
              <StatusIcon status={localValue[assetFormKeys.STATUS_CODE]} />
            </div>
          </span>,

          <span id={assetFormKeys.REASON_TEXT} key="reason">
            {localValue[assetFormKeys.REASON_TEXT]}
          </span>,
        ],
      ],
    },
    {
      header: ['Recommendation'],
      rows: [
        [
          <FieldTextArea
            key={assetFormKeys.RECOMMENDATION}
            id={assetFormKeys.RECOMMENDATION}
            value={localValue[assetFormKeys.RECOMMENDATION]}
            rows={3}
            onChange={handleChange}
            onBlur={handleSave}
            disabled={disabled}
          />,
        ],
      ],
    },
  ];

  return (
    <div id={`${sectionId}-assetInfo`} style={styles.container}>
      <TitleContent contentId={contentId} settings={settings} />
      <DataTable data={table} style={styles.table} />
    </div>
  );
}

const styles = {
  title: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
  },
  spanContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px',
  },
  table: {
    table: {
      display: 'table',
      width: '100%',
      // border
      borderCollapse: 'collapse',
      borderSpacing: '0px',
      border: 'solid gray 1px',
      borderRadius: '4px',
      WebkitBorderRadius: '4px',
      MozBorderRadius: '4px',
      // font
      textAlign: 'left',
      fontWeight: 400,
      fontSize: '0.9rem',
    },
    th: {
      padding: '4px',
      backgroundColor: '#e0e0e0',
      border: 'solid gray 1px',
    },
    td: {
      padding: '0 4px',
      border: 'solid gray 1px',
    },
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
  },
};
