import { formatDateTimeWithTimeZone } from '../../../utils/getDateFormat';
import { assetFormKeys } from '../enums';

function getAssetInfo(main = {}, reportInfo = {}) {
  if (!Object.keys(main)?.length || !Object.keys(reportInfo)?.length) return;

  const { image_id, date_taken_milliseconds, assessment, asset_field } = main;

  const {
    IMAGE_ID,
    NAME,
    ASSET_TAG_ID,
    DATE_TAKEN,
    SITE_ID,
    EQUIPMENT,
    RECOMMENDATION,
    STATUS_CODE,
    STATUS_TEXT,
    REASON_CODE,
    REASON_TEXT,
  } = assetFormKeys;

  const {
    site_id = '',
    equipment = '',
    recommendation = '',
    name = '',
  } = reportInfo?.image_options?.[image_id] || {};

  const { status_number, status_text, reason_code_number, reason_code_text } = assessment || {};

  const details = {
    [IMAGE_ID]: image_id,
    [NAME]: name,
    [ASSET_TAG_ID]: asset_field,
    [DATE_TAKEN]: date_taken_milliseconds,
    [SITE_ID]: site_id,
    [EQUIPMENT]: equipment,
    [RECOMMENDATION]: recommendation,
    [STATUS_CODE]: status_number,
    [STATUS_TEXT]: status_text,
    [REASON_CODE]: reason_code_number,
    [REASON_TEXT]: reason_code_text,
  };

  return { name: 'Asset Info', data: details };
}

function getMarkerInfo(marker_info, units = 'C') {
  units = typeof units === 'string' && units.toUpperCase().startsWith('F') ? 'F' : 'C';

  const header = ['Label', `Temperature (°${units})`, `ΔT`, 'Reference'];

  const rows = marker_info?.map((marker) => [
    marker?.markername || '--',
    `${marker?.maxtemperature?.[units]} °${units}` || '--',
    marker?.delta?.[units] || '--',
    marker?.reference ? 'x' : '',
  ]);

  const data = {
    header,
    rows: rows ?? [],
  };

  return { name: 'Marker Info', data };
}

function getImageInfo(image_info, timezone = 'America/Los_Angeles', units = 'C') {
  units = typeof units === 'string' && units.toUpperCase().startsWith('F') ? 'F' : 'C';

  const tempUnits = {
    C: '°C',
    F: '°F',
  };

  const info = [];

  image_info?.forEach((row) => {
    const { type, value } = row;

    if (type === 'temperature') {
      info.push([row.name, value[units] !== null ? `${value[units]} ${tempUnits[units]}` : '--']);
      return;
    }

    if (type === 'temperature_range') {
      // 6/11/2024 - Guide Images don't provide Calibration Range
      //             Therefore, we need account for when the value is null
      const minTemp = value?.['FROM']?.[units];
      const maxTemp = value?.['TO']?.[units];
      info.push([
        row.name,
        minTemp != null && maxTemp != null ? `${minTemp} - ${maxTemp} ${tempUnits[units]}` : '--',
      ]);
      return;
    }

    if (type === 'milliseconds') {
      info.push([row.name, value ? formatDateTimeWithTimeZone(value, timezone) : '--']);
      return;
    }

    // Default case
    info.push([row.name, value || '--']);
  });

  const data = {
    header: [],
    rows: info,
  };

  return { name: 'Image Info', data };
}

function getUserNotes(notes = []) {
  const noteEntries = notes.map((entry) => ({
    user: entry.user,
    date: formatDateTimeWithTimeZone(entry.date),
    text: entry.text,
  }));

  return { name: 'Notes', data: noteEntries };
}

/**
 * This utility function getDataFromImage extracts and returns comprehensive information about an image based on the provided imageId.
 *
 * @param {string} imageId - The ID of the image. Default is an empty string.
 * @param {Object} image - The image object from which to extract data. Default is an empty object.
 * @param {Object} reportInfo - Contains details about the report related to the image. Default is an empty object.
 * @param {string} temperatureUnits - The unit of temperature measurement. Default is Celsius ('C').
 *
 * @returns {Object} An object containing the following keys:
 *   imageId: The ID of the image.
 *   ame: The name of the image.
 *   assetInfo: Information related to the asset.
 *   markerInfo: Information related to the marker.
 *   imageInfo: Additional information related to the image.
 *   userNotes: User notes associated with the image.
 *   sectionTitle: The section in which the image is placed.
 *   imageIR: The infrared thermal image data.
 *   imageVL: The visible light image data.
 *   photonotes: Any notes added to the photo.
 * @note The function returns nothing if either image or imageId is not provided.
 */
export default function getDataFromImage(
  imageId = '',
  image = {},
  reportInfo = {},
  temperatureUnits = 'C',
) {
  if (!image || !imageId) return;

  const { imageJSON = {}, preview, visual, photonotes = [] } = image;
  const { main, marker_info, image_info, notes } = imageJSON;

  const markerInfo = getMarkerInfo(marker_info, temperatureUnits);
  const imageInfo = getImageInfo(image_info, main?.user_timezone, temperatureUnits);
  const assetInfo = getAssetInfo(main, reportInfo);
  const userNotes = getUserNotes(notes);

  return {
    imageId,
    name: image?.name,
    assetInfo,
    markerInfo,
    imageInfo,
    userNotes,
    sectionTitle: {
      name: 'Section Title',
      data: assetInfo?.data?.name,
    },
    imageIR: {
      name: 'Themal Image',
      data: preview,
    },
    imageVL: {
      name: 'Visible Light Image',
      data: visual,
    },
    photonotes: {
      name: 'Photonotes',
      data: photonotes,
    },
  };
}
