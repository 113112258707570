import React from 'react';
import { TextFieldWithLabel } from '@fluke/predator-report-lib';
import TitleContent from './TitleContent';
import { useReportsData } from '../../hooks/useReportsData';

const DUMMY_IMAGE = '/dummy_640x480.jpeg';

export default function Image({ sectionId, contentId, alt = 'Image', settings = {} }) {
  const { data = DUMMY_IMAGE } = useReportsData(sectionId, contentId);
  const { scale = 100, includeCaption = false } = settings[contentId] ?? {};

  return (
    <div id={`${sectionId}-image-${contentId}`} style={styles.container}>
      <TitleContent contentId={contentId} settings={settings} />
      <img src={data} style={{ width: `${scale}%` }} loading="lazy" alt={alt} />
      {includeCaption && <TextFieldWithLabel />}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
};
