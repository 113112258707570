import React, { useEffect, useState } from 'react';
import { Box, IconButton, Avatar, Menu, MenuItem, Typography } from '@common-ui/ui';
import { ManageProfile } from './ManageProfile';
import VersionSettings from './VersionSettings';
import { useNavigate } from 'react-router-dom';
import routerPathEnum from '../../../../enums/routerPathEnum';
import { getCurrentAuthenticatedUser } from '@fluke/baseline-client-apis';

export default function ShowAvatar() {
  const navigate = useNavigate();
  const [anchor, setAnchor] = useState(null);
  const [currentUserName, setCurrentUserName] = useState(null);

  const ShowLogOut = () => (
    <MenuItem onClick={() => navigate(routerPathEnum.LOGOUT)}>Log Out</MenuItem>
  );

  useEffect(() => {
    const getUser = async () => {
      const userData = await getCurrentAuthenticatedUser();
      if (userData != null) {
        const { userName, emailAddr } = userData;
        if (!userName) {
          setCurrentUserName(emailAddr);
          return;
        }
        setCurrentUserName(userName);
      }
    };
    getUser();
  }, [getCurrentAuthenticatedUser]);
  return (
    <Box>
      <IconButton onClick={(event) => setAnchor(event.currentTarget)}>
        <Avatar sx={{ backgroundColor: 'primary.main', color: 'black' }}>
          {currentUserName ? (
            <Typography variant="h6">{currentUserName.at(0).toUpperCase()}</Typography>
          ) : null}
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        <MenuItem>
          <ManageProfile />
        </MenuItem>
        <MenuItem>
          <VersionSettings />
        </MenuItem>
        <ShowLogOut />
      </Menu>
    </Box>
  );
}
