import React from 'react';
import { Slider } from '@mui/material';
import { RemoveCircleOutline, AddCircleOutline, TonalityOutlined } from '@mui/icons-material';
import ButtonPopover from '../ButtonPopover';

export default function ToolFusion({ value = 255, min = 0, max = 255, step = 1, onChange = null }) {
  const handleAlphaSliderUpdate = (e) => {
    const intValue = Math.round(e.target.value);

    if (!isNaN(intValue)) {
      onChange?.(intValue);
    }
  };

  return (
    <ButtonPopover label="Fusion" tooltip="Fusion" Icon={<TonalityOutlined />}>
      <div style={styles.row}>
        <div style={styles.label}>
          <RemoveCircleOutline />
        </div>
        <Slider
          value={value}
          onChange={handleAlphaSliderUpdate}
          min={min}
          max={max}
          step={step}
          sx={{ ...styles.thumb, ...styles.track }}
        />
        <div style={styles.label}>
          <AddCircleOutline />
        </div>
      </div>
    </ButtonPopover>
  );
}

const styles = {
  row: {
    width: '200px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },

  thumb: {
    color: 'white',
    '& .MuiSlider-thumb': {
      width: '6px',
      borderRadius: '1px',
    },
  },
  track: {
    '& .MuiSlider-track': {
      height: '2px',
    },
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    margin: '0 12px',
    cursor: 'pointer',
  },
};
