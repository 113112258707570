import React from 'react';

// components
import EditorTools from './EditorTools';
import { FieldAssetTagId, FieldImageName } from './EditorTools/Fields';
import DoneEditing from '../Buttons/DoneEditing';
import { AccordionGroup, AccordionItem } from './Accordion';
import { ImageRevert } from '../Buttons';

import ButtonTextIcon from '../../../../components/buttons/ButtonTextIcon';
import { GetApp, InsertDriveFile } from '@mui/icons-material';

export default function SidebarEditor({
  settings = {},
  metadata = {},
  batchMode = false,
  setSettings,
  updateImageMetadata,
  onExport,
  onAddToReport,
  onRevert,
}) {
  const { sort_info = {}, asset_info = {} } = metadata;

  const imageName = sort_info.origin_path ?? '';
  const assetTagId = asset_info.assetTagId ?? '';

  return (
    <>
      <div style={styles.reporting}>
        <div style={styles.column}>
          <div style={styles.DoneRevertButtonRow}>
            {false && (
              <ImageRevert
                id="button-revertImage"
                disabled={true}
                style={styles.button}
                onClick={() => onRevert?.()}
              />
            )}
            <DoneEditing style={styles.button} />
          </div>
          <ButtonTextIcon
            id="button-addImagesToReport"
            tooltip="Report"
            icon={<InsertDriveFile />}
            onClick={() => onAddToReport?.()}
          >
            Add To Report
          </ButtonTextIcon>
          <ButtonTextIcon
            id="button-exportImages"
            tooltip="Export Images"
            icon={<GetApp />}
            onClick={() => onExport?.()}
          >
            Export Images
          </ButtonTextIcon>
        </div>
      </div>
      <AccordionGroup>
        <AccordionItem label="Image Settings" expanded={true}>
          <div style={styles.column}>
            <FieldImageName
              value={imageName}
              onChange={(value) => updateImageMetadata('imageName', value)}
              disabled={batchMode}
            />
            <FieldAssetTagId
              value={assetTagId}
              disabled={batchMode}
              onChange={(value) => updateImageMetadata('assetTagId', value)}
            />
            <EditorTools settings={settings} metadata={metadata} onChange={setSettings} />
          </div>
        </AccordionItem>
      </AccordionGroup>
    </>
  );
}

const styles = {
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  DoneRevertButtonRow: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    justifyContent: 'space-between',
  },
  button: {
    flex: 1,
    margin: '0 4px',
  },
  reporting: {
    padding: '18px 8px',
  },
};
