import React, { useState, useMemo } from 'react';

// context
import { useReportsData } from '../../hooks/useReportsData';
// utilities
import buildSidebar from '../../utils/buildSidebar';
// components
import NavSection from './NavSection';
import ProgressBar from '../../../../components/ProgressBar';
import { Box, List } from '@common-ui/ui';

export default function Sidebar() {
  const { reportInfo = {}, images = {} } = useReportsData();
  const [activeItem, setActiveItem] = useState(0);

  const items = useMemo(() => {
    return buildSidebar(reportInfo);
  }, [reportInfo?.images, reportInfo?.image_options]);

  const settledImages = useMemo(() => Object.values(images).filter(Boolean).length, [images]);

  return (
    <Box id="reportSidebar" sx={styles.container}>
      <ProgressBar
        label="Loading Images"
        settled={settledImages}
        total={reportInfo?.images?.length}
      />
      <List>
        {items.map((navItem, index) => (
          <NavSection
            key={index}
            item={navItem}
            navIndex={index}
            active={activeItem === index}
            onClick={() => setActiveItem(index)}
          />
        ))}
      </List>
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    flex: 1,
    minWidth: '22em',
    maxWidth: '22em',
    zIndex: 10,
    backgroundColor: theme.palette.primary.gray,
    borderRight: '2px solid #e0e0e0',
  }),
};
