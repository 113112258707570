import React, { useEffect, useState } from 'react';

// enums
import { settingsKeys } from '@fluke/irviewer';

// components
import { Box, Paper, Typography } from '@common-ui/ui';
import { TextField } from '@mui/material';
import { PhotonotesModal } from '../Modals';

function ImageWithHoverBorder({ src, ...rest }) {
  return (
    <Box
      src={src}
      component="img"
      sx={{
        border: '2px solid transparent', // Set initial border to transparent
        transition: 'border-color 0.3s ease', // Add transition effect to the border
        '&:hover': {
          borderColor: '#ffc20e', // Set the desired border color on hover
          transform: 'scale(1.1)', // Scale the image by 10% on hover
        },
      }}
      {...rest}
    />
  );
}

export default function PhotoNotes({ imageData = {}, settings = {}, setSettings }) {
  const { photonotes = [] } = imageData;

  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [localOCR, setLocalOCR] = useState(settings[settingsKeys.OCR_TEXT] ?? []);

  const updateSettings = () => {
    setSettings?.({ [settingsKeys.OCR_TEXT]: localOCR });
  };

  useEffect(() => {
    const ocrText = settings[settingsKeys.OCR_TEXT] ?? [];

    // Update the state only if the arrays are different
    if (JSON.stringify(ocrText) !== JSON.stringify(localOCR)) {
      setLocalOCR(ocrText);
    }
  }, [settings[settingsKeys.OCR_TEXT]]);

  const handleImageClick = (selectedImageIndex) => {
    setSelectedImageIndex(selectedImageIndex);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImageIndex(-1); // reset selection
    updateSettings();
    setModalOpen(false);
  };

  const handleTextChange = (index, value = '') => {
    if (index == null) return;

    setLocalOCR((prev) => {
      let updatedOCR = [...prev];

      if (prev.length !== photonotes.length) {
        updatedOCR = new Array(photonotes.length).fill('');
      }

      updatedOCR[index] = value;
      return updatedOCR;
    });
  };

  return photonotes && photonotes.length ? (
    <Box component={Paper} p={2} sx={styles.container}>
      <div style={styles.photnoteGrid}>
        {photonotes.map(({ alt, image }, index) => (
          <div key={`${index}-${alt}`}>
            <ImageWithHoverBorder
              key={index}
              src={image}
              alt={alt}
              loading="lazy"
              style={styles.image}
              onClick={() => handleImageClick(index)}
            />
            <TextField
              size="small"
              value={localOCR[index] || ''}
              onChange={(e) => handleTextChange(index, e.target.value)}
              onBlur={updateSettings}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevents newline if multiline is enabled
                  updateSettings();
                }
              }}
              multiline
            />
          </div>
        ))}
      </div>
      {photonotes && (
        <PhotonotesModal
          selectedImageIndex={selectedImageIndex}
          images={photonotes}
          open={modalOpen}
          ocrText={localOCR}
          onChange={handleTextChange}
          onClose={handleCloseModal}
        />
      )}
    </Box>
  ) : (
    <Typography component={Paper} p={2} sx={styles.photonotesText}>
      Photo notes do not exist for this image.
    </Typography>
  );
}

const styles = {
  container: {
    padding: '12px',
    margin: '10px 0',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    gap: 1,
  },
  photnoteGrid: {
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '10px',
  },
  image: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  photonotesText: {
    display: 'flex',
    justifyContent: 'center',
    padding: '5rem',
  },
};
