import React, { useEffect, useMemo, useState } from 'react';

// utils
import { getStatusDescription } from '@fluke/irviewer';

// components
import { Box, MenuItem, TextField } from '@common-ui/ui';
import { Complete, Watch, Problem, Skip } from './StatusButtons';

const statusIcon = [
  { label: 'Complete', Icon: Complete },
  { label: 'Watch', Icon: Watch },
  { label: 'Problem', Icon: Problem },
  { label: 'Skip', Icon: Skip },
];

export default function Status({ value = { code: 0 }, onChange }) {
  const [currentStatusIndex, setCurrentStatusIndex] = useState(0);

  const handleUpdate = (event) => {
    const code = parseInt(event.target.value);
    const description = getStatusDescription(code)[0];
    onChange?.({ code, description });
  };

  const handleButton = (index) => {
    setCurrentStatusIndex(index);
  };

  // Memoize the status options to avoid recalculating on each render
  const statusOptions = useMemo(
    () => getStatusDescription(currentStatusIndex),
    [currentStatusIndex],
  );

  useEffect(() => {
    const index = Math.floor((value.code ?? 0) / 100);
    setCurrentStatusIndex(index);
  }, [value]);

  return (
    <>
      <Box style={styles.statusRow}>
        {statusIcon.map(({ label, Icon }, index) => (
          <div key={`button-status-${index}-${label}`} style={styles.statusIconBox}>
            <Icon
              label={label}
              active={currentStatusIndex === index + 1}
              onClick={() => handleButton(index + 1)}
            />
          </div>
        ))}
      </Box>

      {currentStatusIndex > 0 && (
        <TextField
          select
          label="Select a reason"
          placeholder="Select a reason"
          value={value.code ?? 0}
          onChange={handleUpdate}
          style={{ width: '100%' }}
        >
          {statusOptions.map((description, index) => (
            <MenuItem key={index} value={currentStatusIndex * 100 + index + 1}>
              {description}
            </MenuItem>
          ))}
        </TextField>
      )}
    </>
  );
}

const styles = {
  statusRow: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  statusIconBox: {
    // this width also serves as a spacer (instead of a flex gap)
    width: '100px',
  },
};
