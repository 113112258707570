import React from 'react';
import { getNormalizedValueClamped, round } from '@fluke/irviewer';
import { InputAdornment, TextField, Tooltip } from '@common-ui/ui';

const CHAR_TAU = 'τ';

export default function FieldTransmission({ value = 1, onChange }) {
  const handleChange = (evt) => {
    const inputValue = evt.target.value;
    const normalizedValue = getNormalizedValueClamped(inputValue);

    onChange?.(normalizedValue);
  };

  const transmissionTooltip = (
    <>
      Range between
      <br />
      {`0 < ${CHAR_TAU} < 100`}
    </>
  );

  return (
    <Tooltip title={transmissionTooltip}>
      <TextField
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', step: 0.1 }}
        type="number"
        style={{ width: '100%' }}
        id="transmission_input"
        label="Transmission"
        value={round(value * 100, 3)}
        onChange={handleChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">{`${CHAR_TAU} =`}</InputAdornment>,
          endAdornment: <InputAdornment position="end">%</InputAdornment>,
        }}
      />
    </Tooltip>
  );
}
