import React from 'react';

import { useNavigate } from 'react-router-dom';
import ButtonTextIcon from '../../../components/buttons/ButtonTextIcon';
import { Edit } from '@mui/icons-material';
import routerPathEnum from '../../../enums/routerPathEnum';
import { useBaseline } from '../../../context';

/**
 * Edit the selected images
 * @returns {JSX.Element}
 */
export default function EditImage() {
  const navigate = useNavigate();
  const { baselineImages } = useBaseline();

  return (
    <ButtonTextIcon
      icon={<Edit />}
      disabled={baselineImages.selectedImageIds.length === 0}
      onClick={() => navigate({ pathname: routerPathEnum.IMAGE_EDITOR })}
      tooltip="Edit Images"
    >
      Image Editor
    </ButtonTextIcon>
  );
}
