import React from 'react';

// components
import { MenuItem, TextField, Typography } from '@common-ui/ui';

export default function IRImagePaletteDropDown({
  value = '',
  paletteDisplayData = {},
  onChange = null,
}) {
  const { data = {}, order = [] } = paletteDisplayData;

  return (
    <TextField
      style={styles.palette}
      select
      label="Palette"
      value={value}
      defaultValue={0}
      onChange={(e) => onChange?.(e.target.value)}
      SelectProps={styles.palette}
    >
      {order.map((palette, index) => (
        <MenuItem key={index} value={palette}>
          <div style={styles.menuItem}>
            <Typography> {data?.[palette].name}</Typography>
            <div style={styles.gradientCircle(data?.[palette].gradient)} />
          </div>
        </MenuItem>
      ))}
    </TextField>
  );
}

const styles = {
  palette: {
    style: {
      maxHeight: '25em',
    },
  },
  icon: {
    border: '2px solid #d8d8d8',
    width: '2em',
    height: '2em',
    borderRadius: '50%',
  },

  gradientCircle: (gradient) => ({
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    marginRight: '10px',
    background: gradient,
    border: '1px solid #ccc',
  }),

  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
