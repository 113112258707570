import { imageAPI } from '@fluke/baseline-client-apis';
import { ImageType } from '../enums';

/**
 * This function builds a search string based on the active query and the search text.
 *
 * @param {string} activeQuery - The active query for the image search.
 * @param {string} searchText - The text to be searched.
 *
 * @returns {string} `searchString` - The built search string, or undefined if there is no active query.
 */
export function buildSearchString(activeQuery, searchText) {
  if (!activeQuery) return;

  let searchString = `(${activeQuery})`;
  if (searchText != null && searchText.length > 0) {
    const searchTexts = searchText.trim().split(' ');
    const searchTextConditions = searchTexts.map((text) => `(search_text = ${text})`).join(' or ');
    searchString = `${searchString} and (${searchTextConditions})`;
  }

  return searchString;
}

/**
 * This asynchronous function fetches image results based on certain filters from the API.
 *
 * @param {Object} filter - The filter parameters for the image search.
 *
 * @returns {Promise<Object>} `results` - A Promise that resolves to the image results fetched from the API, or an empty object if the API call fails or there are no images.
 */
export async function fetchImageResults(filter = {}) {
  let results = {};

  const { activeQuery, searchText, currentOrganization = '' } = filter;

  const searchParams = {
    search_string: buildSearchString(activeQuery, searchText),
    sort_field: filter.sort_field ?? 'date_taken',
    sort_direction: filter.sort_direction ?? 'desc',
    search_id: filter.search_id ?? Date.now(),
    page: filter.page ?? 0,
    page_size: filter.previewPageSize ?? 10,
  };

  try {
    const response = await imageAPI.fetchBySearch(searchParams, currentOrganization);
    return response;
  } catch (error) {
    console.error('Error fetching image results:', error);
  }

  return results;
}

/**
 * async fetch all image results based on filter parameters.
 *
 * @param {Object} filter - An object which contains search parameters. It includes activeQuery, searchText, currentOrganization, previewPageCount, previewPageSize, sort_field, sort_direction, search_id, and page. Defaults to an empty object.
 *
 * @returns {Promise<Object>} - A Promise that resolves to the response from the 'imageAPI.fetchBySearch' function. In case of an error during call, returns an empty object.
 */
export async function fetchAllImageResults(filter = {}) {
  const { previewPageCount = 1, previewPageSize = 10 } = filter;

  const estimatedTotalImages = previewPageCount * previewPageSize;

  return await fetchImageResults({ ...filter, previewPageSize: estimatedTotalImages });
}

/**
 * async fetch image previews from a results list of image id's.
 *
 * @param {Object} results - The results object which contains the image list.
 * @param {string} currentOrganization - The organization currently being handled.
 *
 * @returns {Promise<Array>} - A Promise that resolves to the image previews fetched from the API, or an empty array if the API call fails or there are no images.
 */
export async function fetchImagePreviews(results = {}, currentOrganization = '') {
  let data = [];
  const { results: imageList = [] } = results;

  try {
    if (imageList.length > 0) {
      data = await imageAPI.fetchPreviews(imageList, currentOrganization);
    }
  } catch (error) {
    console.error('Error fetching image previews:', error);
  }

  return data;
}

/**
 * Fetches sidebar data for the given organization.
 *
 * @async
 * @param {string} currentOrganization - Organization ID.
 * @returns {Promise<Array>} Resolves to sidebar data or an empty array on error.
 */
export async function fetchSidebarData(currentOrganization = '', imageType = ImageType.THERMAL) {
  try {
    const response = await imageAPI.sidebarDatesService(currentOrganization, imageType);
    return response ?? [];
  } catch (error) {
    console.error(error);
    return [];
  }
}

/**
 * Deletes an image by its ID from the current organization.
 *
 * @param {string} imageId - The ID of the image to be deleted.
 * @param {string} currentOrganization - The ID of the current organization from which the image is deleted.
 *
 * @returns {Promise<any>} - A promise that resolves to the response from the image deletion API call.
 */
export async function deleteImageById(imageId = '', currentOrganization = '') {
  if (imageId === '') return;

  const response = await imageAPI.deleteById(imageId, currentOrganization);
  return response;
}

/**
 * Deletes multiple images from a list based on the selected image IDs.
 * Awaits the completion of all deletions and returns true if successful, false if any deletion fails.
 *
 * @param {string[]} selectedImageIds - An array of IDs of the images to be deleted.
 * @param {string} currentOrganization - The ID of the current organization for context in deletion.
 *
 * @returns {Promise<boolean>} - A promise that resolves to true if all deletions succeed, or false if any deletion fails.
 */
export async function deleteFromImages(selectedImageIds = [], currentOrganization = '') {
  try {
    // Create an array of promises for each delete operation
    const deletePromises = selectedImageIds.map((imageId) =>
      deleteImageById(imageId, currentOrganization),
    );

    await Promise.all(deletePromises);

    return true;
  } catch (error) {
    console.error('Error deleting images:', error);
    // Return false if any deletion fails
    return false;
  }
}

/**
 * Retrieves the session ID for the specified organization.
 *
 * @param {string} currentOrganization - The organization to retrieve the session ID for. Defaults to an empty string.
 * @returns {Promise<string | null>} - The session ID if successful, or null if an error occurs.
 */
export async function getSessionId(currentOrganization = '') {
  try {
    const session_id = await imageAPI.getSessionID(currentOrganization);
    return session_id;
  } catch (error) {
    console.error('Error fetching session ID:', error);
    return null;
  }
}

/**
 * Update and store attributes of an image.
 *
 * @param {string} imageId - The unique identifier of the thermal image to update.
 * @param {object} [payload={}] - The data to update the thermal image with (default is an empty object).
 * @returns {Promise<object|Error>} - The API response object if successful, or an error object if the operation fails.
 */
export async function storeUpdatedThermalImage(imageId, attributes = {}) {
  if (!imageId) {
    throw new Error('imageId is required to update the thermal image.');
  }

  try {
    const response = await imageAPI.storeUpdatedThermalImage(imageId, attributes);

    if (!response || response.status !== 200) {
      throw new Error(`Failed to update thermal image. Status: ${response?.status}`);
    }

    return response;
  } catch (error) {
    console.error('Error updating thermal image:', error);
    throw error; // Re-throw the error to propagate it to the caller
  }
}
