import React, { useState } from 'react';

// components
import { Box, Button, Modal, Typography } from '@common-ui/ui';
import AlertSnackBar from '../utility/AlertSnackBar';
import { ReportEnum } from '../../enums';

/**
 * This modal allows a report to be finalized so that no further edits would be allowed
 *
 * @param {boolean} finalized - A boolean indicating whether the report is finalized
 * @param {function} onAccept - A function to be called when the user accepts the action
 *
 * @returns {JSX.Element}
 */
export default function MarkAsCompleteModal({ finalized = false, onAccept }) {
  const [open, setOpen] = useState(false);

  const handleAccept = () => {
    setOpen(false);
    onAccept?.();
  };

  return (
    <Box>
      <AlertSnackBar handleClose={() => setOpen(false)} autoHideDuration={6000} />
      <Button
        disableElevation
        variant="contained"
        color="info"
        id="markAsCompleteButton"
        onClick={() => setOpen(true)}
        disabled={finalized}
        size="small"
      >
        {ReportEnum.SAVE_AS_COMPLETE_REPORT}
      </Button>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={styles.container}>
          <Typography variant="h6">{ReportEnum.COMPLETE_MODAL_TITLE}</Typography>
          <Typography>{ReportEnum.COMPLETE_MODAL_BODY}</Typography>
          <Box sx={styles.buttons}>
            <Button sx={styles.cancel} onClick={() => setOpen(false)}>
              {ReportEnum.CANCEL}
            </Button>
            <Button onClick={handleAccept} color="secondary">
              {ReportEnum.MARK_AS_COMPLETE}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(3),
    // modal
    width: theme.spacing(50),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // styling
    background: theme.palette.background.paper,
    borderRadius: theme.spacing(0.5),
    boxShadow: theme.shadows[12],
    color: theme.palette.text.primary,
  }),
  buttons: (theme) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(1),
  }),
  cancel: (theme) => ({
    color: theme.palette.common.black,
  }),
};
