import React, { useEffect, useRef, useState } from 'react';
import { ClickAwayListener, ListItemText } from '@mui/material';

/**
 * NavText component for displaying and editing text.
 *
 * Renders either editable input or text display based on the editing state.
 * When in editing mode, it allows users to update the primary text value,
 * invoking the onChange callback upon completion (Enter key press or blur).
 *
 * @param {string} primary - The primary text to display or edit.
 * @param {string} secondary - The secondary text to display.
 * @param {boolean} isEditing - Flag indicating if the component is in editing mode.
 * @param {function} [onChange] - Callback function to handle changes,
 *                                 receives event and updated text as arguments.
 */
export default function NavText({ primary, secondary, isEditing = false, onChange = null }) {
  const inputRef = useRef(null);

  const [editedName, setEditedName] = useState('');

  useEffect(() => {
    if (isEditing) {
      setEditedName(primary);
    }
  }, [isEditing]);

  const handleEditComplete = (event) => {
    if (isEditing === false) return;
    if (event.key === 'Enter' || event.type === 'blur') {
      onChange?.(event, editedName);
    }
  };

  const handleInputChange = (event) => {
    setEditedName(event.target.value);
  };

  // Autofocus on input when editing starts
  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus(); // focus the input
      inputRef.current.select(); // highlight the text
    }
  }, [isEditing]);

  return (
    <>
      {isEditing ? (
        <ClickAwayListener onClickAway={handleEditComplete}>
          <input
            ref={inputRef}
            type="text"
            value={editedName}
            onClick={(e) => e.stopPropagation()}
            onChange={handleInputChange}
            onKeyUp={handleEditComplete}
            onBlur={handleEditComplete}
            style={styles.input}
          />
        </ClickAwayListener>
      ) : (
        <ListItemText
          primary={primary}
          secondary={secondary}
          primaryTypographyProps={{
            noWrap: true,
          }}
        />
      )}
    </>
  );
}

const styles = {
  input: {
    width: '100%',
    padding: '4px 8px',
    fontSize: 'inherit',
  },
};
