import React, { useEffect } from 'react';
import { useBaseline } from '../../context';

// components
import { Box } from '@common-ui/ui';
import '../ImageManagement/styles.css';

import { useNavigate } from 'react-router-dom';
import routerPathEnum from '../../enums/routerPathEnum';

/**
 * Image Management screen
 * @returns {JSX.Element}
 */
export default function MerLin() {
  const { baselineImages, baselineStore } = useBaseline();
  const { merlinSmartSearch } = baselineStore;
  const navigate = useNavigate();

  useEffect(() => {
    if (merlinSmartSearch === false) {
      navigate(routerPathEnum.ROOT);
    }
  }, [merlinSmartSearch]);

  useEffect(() => {
    // clear selected images on mount
    baselineImages.clearSelectedImages();
  }, []);

  return (
    <Box sx={styles.container}>
      {merlinSmartSearch && (
        <>
          <h1 style={{ color: 'white', textAlign: 'center' }}>This is Future of MerLin</h1>
        </>
      )}
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '245px 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      'action action'
      'sidebar content'
    `,
    overflow: 'hidden',
    background: theme.palette.background.default,
  }),
};
