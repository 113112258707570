import React, { useCallback } from 'react';
import { IconButton, ImageListItem, ImageListItemBar } from '@common-ui/ui';
import { Brightness7, BrightnessAuto, Circle } from '@mui/icons-material';
import ImageThumbnail from '../ImageThumbnail';

function BatchIconState({ isBatch = false, isPrimary = false }) {
  if (isPrimary) {
    return <Brightness7 />;
  } else if (isBatch) {
    return <BrightnessAuto style={{ color: 'rgba(255,255,255,1)' }} />;
  } else {
    return <Circle style={{ color: 'rgba(255,255,255,0.5)' }} />;
  }
}

export default function CarouselThumbnail({
  imageData = {},
  imageMetadata = {},
  isPrimary = false,
  isBatch = false,
  disableOverlay = false,
  onPrimary,
  onSecondary,
}) {
  const handlePrimary = (...args) => {
    onPrimary?.(...args);
  };

  const handleCheck = useCallback(
    (e) => {
      e.stopPropagation();
      onSecondary?.();
    },
    [onSecondary],
  );

  if (Object.keys(imageData) < 1) return <></>;

  return (
    <ImageListItem
      onClick={handlePrimary}
      id="carousel-image-preview"
      sx={isPrimary ? styles.imageSelected : styles.imageUnselected}
    >
      <div style={{ position: 'relative' }}>
        <ImageThumbnail
          imageData={imageData}
          imageMetadata={imageMetadata}
          style={styles.image}
          hidden={disableOverlay}
        />
        <ImageListItemBar
          position="top"
          actionPosition="left"
          hidden={disableOverlay}
          actionIcon={
            <IconButton color="primary" onClick={handleCheck}>
              <BatchIconState isBatch={isBatch} isPrimary={isPrimary} />
            </IconButton>
          }
        />
      </div>
    </ImageListItem>
  );
}

const styles = {
  imageSelected: {
    border: 2,
    borderColor: 'primary.main',
    cursor: 'pointer',
  },
  imageUnselected: {
    border: 2,
    borderColor: 'transparent',
    cursor: 'pointer',
  },
  image: {
    width: '100%',
    height: 'auto',
  },
};
