import React, { useState } from 'react';
import { Edit, VisibilityOutlined, MoreHoriz } from '@mui/icons-material';
import { Button, Typography, Menu, MenuItem } from '@mui/material';
import { IconButton } from '@common-ui/ui';

import { ReportEnum } from '../../../enums';
import { DateFormats, getDate } from '../../../utils/getDateFormat';

/**
 * This is a template for the buttons in the grid
 *
 * @param {*} row - The selected row's json data
 * @param {*} id - The selected row's id
 * @param {*} icon - The icon to display on the button
 * @param {*} text - The text to display on the button
 *
 * @returns {JSX.Element}
 */
const GridButton = ({ row, id, icon, text, onNavigation }) => {
  const styles = {
    buttonText: { color: 'black' },
  };

  const handleNavigation = () => {
    onNavigation?.(id, row);
  };

  return (
    <Button startIcon={icon} onClick={handleNavigation}>
      <Typography variant="caption" sx={styles.buttonText}>
        {text}
      </Typography>
    </Button>
  );
};

/**
 * This is the menu for the delete button
 *
 * @param {*} id - The selected row's id
 * @returns {JSX.Element}
 */
const RowMenu = ({ id, onDelete }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDelete = () => {
    onDelete?.(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreHoriz fontSize="small" />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={handleDelete}>{ReportEnum.DELETE}</MenuItem>
      </Menu>
    </div>
  );
};

export default function GridColumns({ onNavigation, onDelete }) {
  return [
    {
      field: ReportEnum.REPORT_NAME_FIELD,
      headerName: ReportEnum.REPORT_NAME_HEADER,
      editable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: ReportEnum.PERFORMED_ON_FIELD,
      headerName: ReportEnum.PERFORMED_ON_HEADER,
      editable: false,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => getDate(DateFormats.LOCAL, params.value),
    },
    {
      field: ReportEnum.COMPANY_FIELD,
      headerName: ReportEnum.COMPANY_HEADER,
      editable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: ReportEnum.SITE_ID_FIELD,
      headerName: ReportEnum.LOCATION_HEADER,
      editable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: ReportEnum.PERFORMED_BY_FIELD,
      headerName: ReportEnum.PERFORMED_BY_HEADER,
      editable: false,
      flex: 1,
      minWidth: 100,
      valueFormatter: (params) => params.value,
    },
    {
      field: ReportEnum.ACTIONS_FIELD,
      headerName: ReportEnum.ACTIONS_HEADER,
      editable: false,
      width: 120,
      renderCell: ({ row, id }) =>
        row.finalized === false ? (
          <div style={styles.editButtons}>
            <GridButton
              row={row}
              id={id}
              icon={<Edit />}
              text={ReportEnum.EDIT}
              onNavigation={onNavigation}
            />
            <RowMenu id={id} onDelete={onDelete} />
          </div>
        ) : (
          <GridButton
            row={row}
            id={id}
            icon={<VisibilityOutlined />}
            text={ReportEnum.REVIEW}
            onNavigation={onNavigation}
          />
        ),
    },
  ];
}

const styles = {
  editButtons: { display: 'flex', flexGrow: 1, justifyContent: 'center' },
};
