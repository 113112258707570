import React, { useEffect, useState } from 'react';
import { TextField, Tooltip } from '@common-ui/ui';

/**
 * This is the component for the Image Name field in the image editor right sidebar.
 *
 * @param {string} id - The ID of the field.
 * @param {function} onChange - The function to update the image name.
 * @param {boolean} disabled - Whether the field is disabled.
 * @param {object} groupImageData - The group image data.
 *
 * @returns {JSX.Element}
 */
export default function ImageName({ value = '', disabled = false, onChange }) {
  const [fieldValue, setFieldValue] = useState(value);

  useEffect(() => {
    setFieldValue(value);
  }, [value]);

  const handleChange = (evt) => {
    setFieldValue(evt.target.value);
  };

  const handleBlur = (evt) => {
    onChange?.(evt.target.value);
  };

  return (
    <Tooltip title={'Edit Image Name'}>
      <TextField
        id="input-image_name"
        label="Image Name"
        value={fieldValue}
        disabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur} // Call handleBlur when the input field blurs
        placeholder="Enter Image Name"
        InputLabelProps={{ shrink: true }}
      />
    </Tooltip>
  );
}
