import React, { useCallback, useEffect, useState } from 'react';
// utilities -->
import {
  buildTreeFromAdjacency,
  buildNextItems,
  deleteById,
  renameById,
  createNewAsset,
  rootTrace,
  createNewFolder,
  getDeletedEntries,
  getAllFiles,
} from './lib/tree';
// enums -->
import { ROOT } from './lib/enum/RootEnum';
import { EntryType } from './lib/enum/EntryTypeEnum';
import { EntryAction } from './lib/enum/EntryActionEnum';
// mock data -->
import MockAssets from './lib/mock/assets';
import MockHierarchy from './lib/mock/hierarchy';
// components -->
import { CreateAssetModal } from './components/Modal';
import { ActionBar, Sidebar, AssetsList, AssetPage, DirectoryPath } from './components';

/**
 * Assets is a functional component that manages and displays a file directory hierarchy and associated assets.
 * It handles the state and functionality for navigation paths, list of assets, selected items, tree structure,
 * hierarchy and file data. The assets can be either files or folders.
 *
 * The component provides functionality for actions such as viewing, renaming, adding folders/assets, and deleting.
 * Depending on the action selected, it changes the state accordingly and refreshes the component.
 * It also handles the opening and closing of a modal for asset creation.
 *
 * @param {Object[]} [assetListItems=[]] - An array of objects representing the assets to be displayed. Each object should have at least `id` and `name` properties.
 * @param {function} [changeDirectoryPath=null] - A function to be called when an asset is clicked. The clicked asset's id is passed as an argument.
 * @param {function} [handleAction=null] - A function to be called when an action is selected from the context menu.
 *                                         This function is called with two arguments: the id of the asset on which the action
 *                                         was selected, and a string representing the action.
 *                                         If the rename action was selected, a third argument representing the new name is also passed.
 * @param {function} [handleCreateAssetModal=null] - A function to be called when the 'Create new asset' modal is opened or closed.
 *
 * @returns {JSX.Element} A JSX component rendering a file explorer interface where users can navigate through directories,
 *                        perform actions on files/folders, and view asset details.
 */
export default function Assets() {
  const [isNewAssetModalOpen, setIsNewAssetModalOpen] = useState(false);

  // persistent states -->
  const [navPath, setNavPath] = useState([]);
  // array of objects representing the assets to be displayed. Each object should have at least `id` and `name` properties.
  const [assetListItems, setAssetListItems] = useState([]);
  const [testPoints, setTestPoints] = useState([]);
  // current selected hierarchy entry object
  const [selectedItem, setSelectedItem] = useState({});

  // mock external state tables -->
  // unpacked adjacency hierarchy without children
  const [hierarchy, setHierarchy] = useState();
  // object record by id of all files (aka assets)
  const [files, setFiles] = useState();
  // packed adjacency tree with nested children attribute
  const [tree, setTree] = useState([]);

  const updateDirectoryItemsAndPath = (nextId) => {
    // get next directory and directory path
    let trace = rootTrace(nextId, hierarchy);

    if (trace.length === 1) {
      nextId = '';
      setSelectedItem({});
    }

    const nextItems = buildNextItems(nextId, hierarchy, files) || [];
    setAssetListItems(nextItems);
    setNavPath(trace);
  };

  const handleTestPoints = useCallback(
    (row) => {
      const { children } = row;
      let tempTestPoints = [];
      // handle te/st points by id -->
      if (children?.length) {
        children.map((key) => {
          if (MockAssets[key]) {
            tempTestPoints.push({ id: key, ...MockAssets[key] });
          }
        });
      }
      setTestPoints(tempTestPoints);
    },
    [testPoints],
  );

  const changeDirectoryPath = useCallback(
    (id) => {
      // handle next directory location by id -->
      const nextSelectedItem = hierarchy.find((item) => item.id === id);
      setSelectedItem(nextSelectedItem);
      updateDirectoryItemsAndPath(nextSelectedItem?.id);
    },
    [hierarchy],
  );

  const handleAction = useCallback(
    (id = '', actionId = '', argv = null) => {
      switch (actionId) {
        case EntryAction.OPEN_ASSET:
        case EntryAction.OPEN_FOLDER:
          {
            changeDirectoryPath(id);
          }
          break;
        case EntryAction.RENAME:
          {
            // rename folder by id
            const [nextHierarchy, nextFiles] = renameById(id, argv, hierarchy, files);
            setHierarchy(nextHierarchy);
            setFiles(nextFiles);
          }
          break;
        case EntryAction.ADD_FOLDER:
          {
            // add folder by parent id
            let parentId = id || '';
            if (selectedItem.tag === EntryType.FILE) {
              // if file, use parent
              parentId = selectedItem?.parent || '';
            }

            const [nextHierarchy] = createNewFolder(parentId, argv, hierarchy);
            setHierarchy(nextHierarchy);
          }
          break;
        case EntryAction.ADD_ASSET:
          {
            // add asset by parent id
            let parentId = id || '';
            if (selectedItem.tag === EntryType.FILE) {
              // if file, use parent
              parentId = selectedItem?.parent || '';
            }

            const [nextHierarchy, nextFiles] = createNewAsset(parentId, argv, hierarchy, files);
            setHierarchy(nextHierarchy);
            setFiles(nextFiles);
          }
          break;
        case EntryAction.DELETE:
          {
            // delete folder by id
            const [nextHierarchy] = deleteById(id, hierarchy);
            setHierarchy(nextHierarchy);
          }
          break;
        case EntryAction.SHOW_ALL:
          {
            // show all assets under provided parent id
            const allItems = getAllFiles(tree, files);
            setNavPath([ROOT]);
            setSelectedItem({});
            setAssetListItems(allItems);
          }
          break;
        case EntryAction.SHOW_DELETED:
          {
            // show all deleted assets
            const deletedItems = getDeletedEntries(hierarchy, files);
            setNavPath([ROOT]);
            setSelectedItem({});
            setAssetListItems(deletedItems);
          }
          break;
        default:
          break;
      }
    },
    [selectedItem, hierarchy, files],
  );

  const handleCreateAssetModal = useCallback(
    (name) => {
      if (name) {
        const parentId = selectedItem.id ?? '';
        handleAction(parentId, EntryAction.ADD_ASSET, name);
      }
      setIsNewAssetModalOpen((prev) => !prev);
    },
    [selectedItem],
  );

  const assetPayload = files?.[selectedItem?.id] || {};

  useEffect(() => {
    // on file directory refresh, build new tree
    const nextTree = buildTreeFromAdjacency(hierarchy);
    setTree(nextTree);

    // hierarchy has updated. Does the current directory still exist?
    updateDirectoryItemsAndPath(selectedItem?.id);
  }, [hierarchy]);

  useEffect(() => {
    // on mock file first load
    setFiles(MockAssets);
    setHierarchy(MockHierarchy);
  }, [MockAssets, MockHierarchy]);

  return (
    <div style={styles.container}>
      <DirectoryPath path={navPath} onClick={changeDirectoryPath} />
      <div style={styles.body}>
        <div style={styles.leftPanel}>
          <Sidebar items={tree} onClick={changeDirectoryPath} onAction={handleAction} />
        </div>

        <div style={styles.rightPanel}>
          {selectedItem?.tag !== EntryType.FILE && (
            <ActionBar onAddNewAsset={handleCreateAssetModal} />
          )}

          <div style={styles.assetView}>
            {selectedItem?.tag === EntryType.FILE ? (
              <AssetPage assetPayload={assetPayload} />
            ) : (
              <AssetsList
                rows={assetListItems}
                path={navPath}
                onPathChange={changeDirectoryPath}
                onAction={(id, ...restArgs) =>
                  handleAction(id || selectedItem?.id || '', ...restArgs)
                }
                onAddNewAsset={handleCreateAssetModal}
                onViewTestPoints={handleTestPoints}
                testPoints={testPoints}
              />
            )}
          </div>
        </div>
        <CreateAssetModal isOpen={isNewAssetModalOpen} onClose={handleCreateAssetModal} />
      </div>
    </div>
  );
}

const styles = {
  container: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
  },
  body: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    borderTop: '1px solid #E0E0E0',
  },
  leftPanel: {
    // sidebar -->
    display: 'flex',
    minWidth: '250px',
    maxWidth: '250px',
    backgroundColor: '#FAFAFA',
  },
  rightPanel: {
    // main content -->
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderLeft: '1px solid #E0E0E0',
  },
  assetView: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'auto',
  },
};
