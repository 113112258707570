import React, { useState } from 'react';
import { Modal, Stepper, Step, StepButton, Button } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import DarkTheme from '../../styles/DarkTheme';
import LightTheme from '../../styles/LightTheme';

export default function ModalStepper({ open = false, setOpen, stepContent = {}, theme = 'dark' }) {
  const [activeStep, setActiveStep] = useState(0);
  const { labels = [], content = [] } = stepContent;

  return (
    <ThemeProvider theme={theme === 'dark' ? DarkTheme : LightTheme}>
      <Modal open={open} onClose={() => setOpen?.(false)}>
        <div style={styles.container}>
          <div style={styles.header}>
            <Stepper nonLinear sx={styles.stepper} activeStep={activeStep}>
              {labels.map((label, index) => (
                <Step key={label} sx={styles.blueStep}>
                  <StepButton onClick={() => setActiveStep(index)}>{label}</StepButton>
                </Step>
              ))}
            </Stepper>
          </div>
          <div style={styles.content}>{content[activeStep]}</div>
          <div style={styles.buttons}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={() => setActiveStep((prevActiveStep) => prevActiveStep - 1)}
            >
              Back
            </Button>
            <Button
              variant="contained"
              sx={{ visibility: activeStep === labels.length - 1 ? 'hidden' : '' }}
              color="secondary"
              onClick={() => setActiveStep(activeStep + 1)}
            >
              Next
            </Button>
          </div>
        </div>
      </Modal>
    </ThemeProvider>
  );
}

const styles = {
  container: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '80vh',
    maxWidth: '960px',
    maxHeight: '700px',
    boxShadow: 24,
    background: '#232323',
    border: '2px solid #000',
    color: 'white',
    display: 'grid',
    gridTemplateRows: 'auto 1fr auto', // 1x3 grid layout
    gap: '8px',
    padding: '36px',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    overflowY: 'auto', // Scrollable if content overflows
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px',
  },
  stepper: {
    width: '500px',
  },
  blueStep: () => ({
    '& .MuiStepIcon-root': {
      fill: '#555',
    },
    '& .MuiStepIcon-root.Mui-active': {
      fill: '#2B7CD3',
    },
    '& .MuiStepIcon-text': {
      fill: 'rgb(255, 255, 255)',
    },
  }),
};
