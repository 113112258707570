import React from 'react';
// mui -->
import { Box, IconButton, Modal, Typography } from '@common-ui/ui';
import { Close, RemoveRedEye } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import AssetTextField from '../AssetTextField';

const columns = [
  { field: 'tagId', headerName: 'Asset Tag ID', flex: 0.5 },
  { field: 'name', headerName: 'Name', flex: 0.5 },
  { field: 'location', headerName: 'Location', flex: 1 },
  {
    field: 'action',
    headerName: 'View',
    flex: 0.15,
    editable: false,
    renderCell: () => (
      <IconButton>
        <RemoveRedEye />
      </IconButton>
    ),
  },
];

export default function TestPointModal({ testPoints, isOpen = false, onSelect = () => {} }) {
  return (
    <Modal open={isOpen} onClose={() => onSelect(false)}>
      <Box sx={styles.container}>
        <Box sx={styles.header}>
          <Typography variant="h5" gutterBottom>
            Asset Information
          </Typography>
          <IconButton onClick={() => onSelect(false)}>
            <Close style={styles.closeIcon} />
          </IconButton>
        </Box>
        <Box sx={{ ...styles.header, gap: 5 }}>
          <img src="/preview.jpeg" alt="placeholder" width="400" />
          <Box>
            <Box sx={styles.header}>
              <AssetTextField label="Asset Tag ID" value={testPoints[0]?.tagId} />
              <AssetTextField label="Asset Name" value={testPoints[0]?.name} />
            </Box>
            <AssetTextField
              label="Asset Location"
              value={testPoints[0]?.location}
              style={styles.input}
            />
            <AssetTextField
              label="Asset Description"
              value={testPoints[0]?.description}
              multiline
              rows={4}
              style={styles.input}
            />
          </Box>
        </Box>
        <Box sx={styles.testPoints}>
          <Typography variant="h5" gutterBottom>
            Test Points
          </Typography>
          <DataGrid
            rows={testPoints}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            sx={{ height: 300 }}
          />
        </Box>
      </Box>
    </Modal>
  );
}

const styles = {
  container: {
    // mui -->
    boxShadow: 3,
    // css -->
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '1000px',
    opacity: 1,
    padding: '24px',
    background: 'white',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
  },

  input: {
    width: 600,
    marginBottom: '0.5em',
  },
  testPoints: { height: 350, width: '100%' },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 1,
  },

  closeIcon: {
    justifySelf: 'flex-end',
  },

  iconInfo: {
    color: 'grey',
  },
};
