import React, { useState } from 'react';

// context
import { useReportsData } from '../../hooks/useReportsData';

// components
import NavRow from './NavRow';

import { ExpandMore, ExpandLess } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import { formatDateTimeWithTimeZone } from '../../../../utils/getDateFormat';

const DUMMY_IMAGE = '/dummy_640x480.jpeg';

/**
 * NavSection component.
 *
 * This component represents a parent-level navigation item in the report sidebar.
 * It can display subitems that expand or collapse when the parent item is toggled.
 * Each subitem is rendered as a `NavRow` with relevant details like name, thumbnail,
 * and date information.
 *
 * @component
 * @param {Object} [item={}] - Object containing report information, including `id`, `name`,
 * and an array of `subitems`.
 * @param {boolean} [active=false] - Indicates if the parent item is currently active.
 * @param {function} [onClick=null] - Callback function triggered when a subitem is clicked.
 *
 * @returns {JSX.Element} Rendered NavSection component with expandable/collapsible subitems.
 */
export default function NavSection({ item = {}, active = false, onClick = null }) {
  const { disabled = false, images = {} } = useReportsData();

  const [open, setOpen] = useState(true);
  const subitems = item?.subitems || [];

  return (
    <div>
      <NavRow
        id={item.id}
        active={active}
        Icon={subitems.length ? (open ? ExpandMore : ExpandLess) : null}
        name={item.name}
        indent={1}
        onIconClick={() => setOpen((prev) => !prev)}
        onClick={onClick}
      />

      {subitems && (
        <Collapse in={open} timeout="auto" unmountOnExit>
          {(item.subitems || []).map(({ id }, index) => (
            <NavRow
              key={`subitem_${index}_${id}`}
              id={id}
              name={images[id]?.name}
              thumbnail={images[id]?.imageIR.data ?? DUMMY_IMAGE}
              datetime={formatDateTimeWithTimeZone(images?.[id]?.assetInfo?.data?.date_taken)}
              indent={2}
              showMenu={disabled === false}
              onClick={onClick}
            />
          ))}
        </Collapse>
      )}
    </div>
  );
}
