import React, { useEffect, useState } from 'react';
import { getCurrentAuthenticatedUser } from '@fluke/baseline-client-apis';

// utilities
import { useNotes } from '@fluke/irviewer';
import { getDateTimeFromMillisecondsTZ } from '../../../../utils/getDateFormat';

// components
import {
  Box,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  Paper,
  IconButton,
} from '@common-ui/ui';
import { Delete } from '@mui/icons-material';

export default function UserNotes({ settings, setSettings }) {
  const [clientNote, setClientNote] = useState('');
  const [currentUser, setCurrentUser] = useState({});

  const { localNotes, handleCreate, handleDeleteById } = useNotes(settings, setSettings);

  useEffect(() => {
    const getUser = async () => {
      const userData = await getCurrentAuthenticatedUser();
      if (userData != null) {
        setCurrentUser(userData);
      }
    };
    getUser();
  }, []);

  const handleCreateNote = () => {
    const { userName: name, emailAddr: email } = currentUser;
    if (clientNote) {
      handleCreate({
        name,
        email,
        text: clientNote,
      });
      setClientNote(''); // reset the textarea
    }
  };

  const handleDelete = (note) => {
    handleDeleteById(note.id);
  };

  return (
    <div style={styles.container}>
      <Box component={Paper} rounded="true" p={2}>
        <TextField
          fullWidth
          value={clientNote}
          onChange={(e) => setClientNote(e.target.value)}
          label="Add a new note..."
          placeholder="Add a new note..."
          multiline
          rows={4}
        />
        <Box display="flex" justifyContent="flex-end" mt={2}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 2 }}
            onClick={handleCreateNote}
            disabled={!clientNote}
          >
            Add
          </Button>
        </Box>
      </Box>

      {localNotes.length > 0 && (
        <Box component={Paper} p={2}>
          <div style={styles.noteHistory}>
            <List style={{ padding: 0 }}>
              {localNotes.map((note, index) => (
                <ListItem key={note.id || index} alignItems="center" style={styles.listItem}>
                  <ListItemText
                    primary={`${note.author || ''} ${getDateTimeFromMillisecondsTZ(
                      note.date,
                      note.timezone,
                    )}`}
                    secondary={note.text}
                    secondaryTypographyProps={{ style: { whiteSpace: "pre-line" } }}
                  />
                  <IconButton onClick={() => handleDelete(note)}>
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
            </List>
          </div>
        </Box>
      )}
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  noteHistory: {
    padding: '4px',
    gap: '12px',
    margin: '10px 0',
    width: '100%',
    maxHeight: '250px',
    overflowY: 'auto',
    position: 'relative',
    top: '0.5em',
  },
  listItem: {
    padding: 0,
    borderBottom: '1px solid #FFFFFF1F',
  },
};
