import React from 'react';

// enums
import imageStatusIconEnum from '../../../../enums/imageStatusIconEnum';

import { IconButton, ImageListItemBar } from '@common-ui/ui';
import unpackThumbnail from './unpackThumbnail';

export default function ImageThumbnail({
  imageData = {},
  imageMetadata = {},
  style = {},
  hidden = false,
}) {
  const image = unpackThumbnail(imageData, imageMetadata);

  return (
    <div>
      <div style={{ position: 'relative' }}>
        <ImageListItemBar
          id="imageListItemBar"
          title={image.name}
          subtitle={image.timestamp}
          hidden={hidden}
          sx={{ visibility: hidden ? 'hidden' : 'visible' }}
          actionIcon={
            <IconButton>
              <img src={imageStatusIconEnum[image.status]} />
            </IconButton>
          }
        />
        <img src={image.src} alt={image.id} {...style} />
      </div>
    </div>
  );
}
