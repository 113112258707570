import React, { useMemo } from 'react';

// utils
import {
  settingsKeys,
  valueToDisplayTemperature,
  useSettings,
  temperatureTypes,
} from '@fluke/irviewer';

import {
  convertToCelsiusMultiDecimal,
  convertToFahrenheitMultiDecimal,
  getTemperatureValue,
} from '../utilities/temperatureUtils';

// components
import { Slider, Typography } from '@mui/material';

export default function LevelSpanBar({
  settings = {},
  disabled = false,
  minDistance = 1,
  setSettings,
}) {
  const [localSettings, handleUpdate] = useSettings(settings, setSettings);

  const {
    [settingsKeys.PALETTE_RANGE_MIN]: paletteRangeMinTempC,
    [settingsKeys.PALETTE_RANGE_MAX]: paletteRangeMaxTempC,
    [settingsKeys.PALETTE_SCHEME]: paletteScheme = '',
    paletteDisplayData = {},
    minDisplayableTempC = 0,
    maxDisplayableTempC = 100,
    [settingsKeys.TEMPERATURE_UNITS]: temperatureUnits,
  } = localSettings;

  const { paletteGradients = {} } = paletteDisplayData;

  const handleSliderInput = (value, index) => {
    const inputRef = React.useRef();

    const isFocused = inputRef?.current === document.activeElement;
    const [previousTemperatureUnit, setPreviousTemperatureUnit] = React.useState(
      temperatureTypes.CELSIUS,
    ); // Default to Celsius to force conversion on first render if needed

    const roundedValue = useMemo(() => {
      setPreviousTemperatureUnit(temperatureUnits);
      if (
        temperatureUnits === temperatureTypes.FAHRENHEIT &&
        previousTemperatureUnit === temperatureTypes.CELSIUS
      ) {
        return convertToFahrenheitMultiDecimal(value).toFixed(1); // Handle Setting Toggling
      }

      return getTemperatureValue(value, temperatureUnits).toFixed(1);
    }, [temperatureUnits, value]);

    if (
      inputRef?.current?.value != null &&
      !isFocused && // Only update if value is not being edited by user
      inputRef.current.value != roundedValue
    ) {
      inputRef.current.value = roundedValue;
    }

    return (
      <input
        className="MuiSlider-input"
        ref={inputRef}
        onBlur={() => {
          // Whenever focus is lost, apply desired input.
          let convertedValue = parseFloat(inputRef.current.value);
          if (temperatureUnits === temperatureTypes.FAHRENHEIT) {
            convertedValue = convertToCelsiusMultiDecimal(convertedValue);
          }

          const newValues = [paletteRangeMinTempC, paletteRangeMaxTempC];
          newValues[index] = convertedValue;

          handleSliderChange(null, newValues, index);
        }}
        onKeyUp={(e) => {
          // Force input events on return (equivalent of focus lost).
          if (e.key === 'Enter') {
            inputRef.current.blur();
          }
        }}
        onMouseDown={(e) => e.stopPropagation()} // This prevents the manual min/max input from being draggable
      />
    );
  };

  const handleSliderChange = (_event, newValues, activeThumb = 0) => {
    let upper = Number.parseFloat(newValues[1]);
    let lower = Number.parseFloat(newValues[0]);

    if (Number.isNaN(upper) || Number.isNaN(lower)) {
      return;
    }

    // Manage adjusting both sliders when they collide within some predefined delta
    if (upper - lower < minDistance) {
      if (activeThumb === 0) {
        upper = Math.min(maxDisplayableTempC, lower + minDistance);
        setSettings?.(settingsKeys.PALETTE_RANGE_MAX, upper);
      } else {
        lower = Math.max(minDisplayableTempC, upper - minDistance);
        setSettings?.(settingsKeys.PALETTE_RANGE_MIN, lower);
      }
    }

    handleUpdate({
      [settingsKeys.PALETTE_RANGE_MAX]: upper,
      [settingsKeys.PALETTE_RANGE_MIN]: lower,
    });
  };

  return (
    <div style={styles.container}>
      <div style={styles.slider}>
        <Typography variant="h6" className="rangeMinMax" style={{ whiteSpace: 'nowrap' }}>
          {valueToDisplayTemperature(maxDisplayableTempC, temperatureUnits)}
        </Typography>

        <Slider
          disabled={disabled}
          disableSwap
          orientation="vertical"
          valueLabelDisplay="on"
          // New versions of MUI may break this:
          // MUI doesn't natively support editable value labels. Therefore,
          // this is a workaround to make the value labels editable using
          // the currently available MUI API.
          valueLabelFormat={handleSliderInput}
          onChange={handleSliderChange}
          value={[paletteRangeMinTempC, paletteRangeMaxTempC]}
          min={minDisplayableTempC}
          max={maxDisplayableTempC}
          sx={styleDoubleSlider({ background: paletteGradients[paletteScheme] })}
          getAriaLabel={() => 'Always visible'}
        />

        <Typography variant="h6" className="rangeMinMax" style={{ whiteSpace: 'nowrap' }}>
          {valueToDisplayTemperature(minDisplayableTempC, temperatureUnits)}
        </Typography>
      </div>
    </div>
  );
}

const styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  slider: {
    flex: 1,
    maxHeight: 480,
    paddingLeft: 52,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sliderInput: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const styleDoubleSlider = (props = {}) => {
  /**
   * Return: the MUI styles object for double slider
   */

  let {
    isAutoScaleChecked,
    background = '#00f, #f00',
    //  shouldShowCenterHandle, sliderBackgroundColor
  } = props;
  // const visibleIfTrue = (booleanValue) => (booleanValue ? 'visible' : 'hidden');

  return {
    color: '#52af77',
    width: 8,
    '& .MuiSlider-rail': {
      border: 'none',
      backgroundImage: background,
      paddingRight: 0,
    },
    '& .MuiSlider-track': {
      border: 'none',
      backgroundImage: background,
      paddingRight: 0,
    },
    '& .MuiSlider-thumb': {
      height: 24,
      width: 24,
      backgroundColor: `${isAutoScaleChecked ? '#adadad' : '#e8e8e8'}`,
      border: '2px solid #C4C4C4',
      '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
        boxShadow: 'inherit',
      },
      '&:before': {
        display: 'none',
      },
    },

    '& .MuiSlider-valueLabel': {
      width: 35,
      border: '1px solid white',
      backgroundColor: '#343434',
      color: 'white',
      '&::before': {
        right: '-10px',
        borderRight: '1px solid white',
        borderTop: '1px solid white',
      },
    },

    '& .MuiSlider-input': {
      width: 35,
      border: 'none',
      backgroundColor: '#343434',
      color: 'white',
      '&::before': {
        right: '-10px',
        borderRight: '1px solid white',
        borderTop: '1px solid white',
      },
    },
  };
};
