import React from 'react';
import ButtonTextIcon from '../../../components/buttons/ButtonTextIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import deleteModalEnum from '../../../enums/deleteModalEnum';
import DeleteModal from '../../../components/modals/DeleteModal';
import { useBaseline } from '../../../context';

/**
 * Delete the selected images
 * @returns {JSX.Element}
 */
export default function Delete() {
  const {
    baselineImages: { selectedImageIds = [], deleteSelectedImages },
  } = useBaseline();

  const handleDelete = () => {
    deleteSelectedImages(selectedImageIds);
  };

  return (
    <span style={{ pointerEvents: selectedImageIds.length ? true : 'none' }}>
      <DeleteModal
        header={deleteModalEnum.IMAGES_HEADER}
        body={deleteModalEnum.IMAGES_BODY}
        notification={deleteModalEnum.IMAGES_NOTIFICATION}
        onDelete={handleDelete}
      >
        <ButtonTextIcon icon={<DeleteIcon />} disabled={!selectedImageIds.length} tooltip="Delete">
          Delete
        </ButtonTextIcon>
      </DeleteModal>
    </span>
  );
}
