import React, { useEffect } from 'react';
import { useBaseline } from '../../context';

// components
import { ActionBar } from './ActionBar';
import SideBar from './Sidebar';
import ImagePreviews from './ImagePreviews/ImagePreviews';
import { Box } from '@common-ui/ui';
import './styles.css';

/**
 * Image Management screen
 * @returns {JSX.Element}
 */
export default function ImageManagement() {
  const { baselineImages } = useBaseline();

  useEffect(() => {
    // clear selected images on mount
    baselineImages.clearSelectedImages();
  }, []);

  return (
    <Box sx={styles.container}>
      <ActionBar />
      <SideBar />
      <ImagePreviews />
    </Box>
  );
}

const styles = {
  container: (theme) => ({
    colorScheme: 'dark',
    height: '100vh',
    display: 'grid',
    gridTemplateColumns: '245px 1fr',
    gridTemplateRows: 'auto 1fr',
    gridTemplateAreas: `
      'action action'
      'sidebar content'
    `,
    overflow: 'hidden',
    background: theme.palette.background.default,
  }),
};
