import React, { useEffect, useMemo, useState } from 'react';

// utils
import {
  temperatureTypes,
  celsiusToFahrenheit,
  fahrenheitToCelsius,
  clamp,
  round,
} from '@fluke/irviewer';

// components
import { InputAdornment, TextField, Tooltip } from '@common-ui/ui';

const CHAR_TEMPERATURE = 'T';

export default function FieldBackgroundTemperature({
  value = 22,
  min,
  max,
  temperatureUnit = 'C',
  onChange = null,
}) {
  const [displayValue, setDisplayValue] = useState(value);

  const conversion = {
    // to display value (C to F)
    to: (value) =>
      temperatureUnit === temperatureTypes.FAHRENHEIT
        ? celsiusToFahrenheit(value, 1)
        : round(value, 1),
    // from display value (F to C)npm
    from: (value) =>
      temperatureUnit === temperatureTypes.FAHRENHEIT
        ? fahrenheitToCelsius(value, 2) // ensure 2 digits when converting F to C
        : round(value, 1),
  };

  useEffect(() => {
    const nextValue = conversion.to(value);
    if (nextValue !== displayValue) {
      setDisplayValue(nextValue);
    }
  }, [value, temperatureUnit]);

  const displayMin = useMemo(() => conversion.to(min), [min, temperatureUnit]);
  const displayMax = useMemo(() => conversion.to(max), [max, temperatureUnit]);

  const displayUnits =
    temperatureUnit === temperatureTypes.FAHRENHEIT
      ? `°${temperatureTypes.FAHRENHEIT}`
      : `°${temperatureTypes.CELSIUS}`;

  const handleCommit = (event) => {
    if (event.type === 'blur' || event.key === 'Enter') {
      const value = clamp(conversion.from(event.target.value), min, max);
      onChange?.(value);
    }
  };

  const handleInputChange = (e) => {
    // Get the input value
    let inputValue = e.target.value;

    // Allow only a single leading minus sign, digits, and one decimal point
    const regex = /^-?\d*\.?\d*$/; // Matches an optional minus sign, followed by any digits, optionally with a decimal point

    if (regex.test(inputValue)) {
      setDisplayValue(inputValue); // Set the valid input value to state
    }
  };

  const temperatureTooltip = (
    <>
      Range between
      <br />
      {`${displayMin} and ${displayMax} ${displayUnits}`}
    </>
  );

  return (
    <div className="input-group overlayTriggerParent">
      <Tooltip title={temperatureTooltip}>
        <TextField
          type="text"
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          value={displayValue}
          min={displayMin}
          max={displayMax}
          onChange={handleInputChange}
          onBlur={handleCommit}
          onKeyUp={handleCommit}
          style={{ width: '100%' }}
          label="Background Temperature"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{`${CHAR_TEMPERATURE} =`}</InputAdornment>
            ),
            endAdornment: <InputAdornment position="end">{displayUnits}</InputAdornment>,
          }}
        />
      </Tooltip>
    </div>
  );
}
