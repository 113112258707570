import React from 'react';
import { PictureInPictureRounded } from '@mui/icons-material';
import ToolButton from '../../../../../components/buttons/ToolButton';

export default function ToolPip({ value, onClick }) {
  return (
    <div style={styles.flexBox}>
      <ToolButton
        id={'tool-pip'}
        tooltip={`Picture in Picture ${(value && '(active)') || ''}`}
        label="PiP"
        onClick={() => onClick?.()}
        active={value}
        disabled={value}
        Icon={(props) => <PictureInPictureRounded {...props} />}
      />
    </div>
  );
}

const styles = {
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
  },
};
