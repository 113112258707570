import React from 'react';

// components
import { Checkbox, List, ListItem, ListItemText, SvgIcon } from '@common-ui/ui';
import ButtonPopover from './ButtonPopover';

/**
 * Custom MUI Button <Icon /> per design for STANDARD MARKER dropdown
 *
 * @param {*} props - any style or action props inherited form parent
 *
 * @returns {JSX.Element} - Custom STANDARD MARKER button icon
 */
function SpotIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <path
        d="M18.7692 4H5.23077C4.55103 4 4 4.55103 4 5.23077V18.7692C4 19.449 4.55103 20 5.23077 20H18.7692C19.449 20 20 19.449 20 18.7692V5.23077C20 4.55103 19.449 4 18.7692 4Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12" cy="12" r="3" fill="currentColor" />
    </SvgIcon>
  );
}

/**
 * Button dropdown that displays the available STANDARD marker tools for IRVIEWER
 *
 * @param {*} onClick - callback when dropdown selection is made
 *
 * @returns {JSX.Element} - Dropdown button for STANDARD marker shapes
 */
export default function StandardMarkers({ value = {}, options = [], onSelect = null }) {
  const handleOptionToggle = (option) => {
    const newValue = { ...value };
    newValue[option] = !newValue[option];
    onSelect(newValue); // Update parent with new selection state
  };

  return (
    <ButtonPopover label="Spots" tooltip={'Standard Markers'} Icon={<SpotIcon />}>
      <List>
        {options.map((option) => (
          <ListItem key={`checkbox-${option}`} dense>
            <Checkbox
              size="small"
              checked={value[option] === true}
              onClick={() => handleOptionToggle(option)}
            />

            <ListItemText>{option}</ListItemText>
          </ListItem>
        ))}
      </List>
    </ButtonPopover>
  );
}
