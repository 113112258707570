import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// context
import { useBaseline } from '../../context';

// enums
import { reportActions, reportEvents } from './enums';
import routerPathEnum from '../../enums/routerPathEnum';
import snackBarStatusEnum from '../../enums/snackBarStatusEnum';

// utilities
import ReportManager from './ReportManager';
import { checkImageURL } from './utils';
import { scrollToSection } from '@fluke/predator-report-lib/lib/utils';

// document
import { Document, TemplateSettings } from '@fluke/predator-report-lib';
import { buildFromTemplate } from '@fluke/predator-report-lib/lib/template';

import {
  CoverPage,
  TitleSection,
  AssetInfo,
  Image,
  ImageInfo,
  MarkerInfo,
  Photonotes,
  UserNotes,
} from './components/Sections';
import Sidebar from './components/Sidebar';
import { LoadingOverlay } from '../../components/Overlays';
import SecondaryNavigation from './components/SecondaryNavigation';
import AlertSnackBar from '../../components/utility/AlertSnackBar';

export const ReportContext = React.createContext({});

export default function ReportDetail() {
  const { id } = useParams(); // report id
  const navigate = useNavigate();

  const [Report] = useState(new ReportManager());

  const {
    baselineStore: { temperatureUnits = 'F', currentOrganization = '' },
    baselineImages: { updateSelectedImages },
    baselineReports: { activeReportItem = {}, setActiveReportById },
  } = useBaseline();

  const [scale, setScale] = useState(1);
  const [modalOpen, setModalOpen] = useState(false);

  // Document
  const [reportInfo, setReportInfo] = useState({});
  const [metadata, setMetadata] = useState({});
  const [images, setImages] = useState({});
  const [sectionOrder, setSectionOrder] = useState([]);
  const [template, setTemplate] = useState({});

  // state indicators
  const [isLoading, setIsLoading] = useState(true);
  const [snackbar, setSnackbar] = useState(snackBarStatusEnum.DEFAULT);

  const handleFieldUpdate = (id, type, attrs = {}) => {
    Report.handleFieldUpdate(id, type, attrs);
  };

  const loadReport = (info) => {
    // bind React useState
    Report.setCallback(reportEvents.REPORT_INFO, setReportInfo);
    Report.setCallback(reportEvents.METADATA, setMetadata);
    Report.setCallback(reportEvents.SECTION_ORDER, setSectionOrder);
    Report.setCallback(reportEvents.IMAGES, setImages);
    Report.setCallback(reportEvents.TEMPLATE, setTemplate);

    Report.loadReport(info);

    setIsLoading(false);
  };

  const handleFinalizeReport = () => {
    Report.setFinalized(true);
  };

  const handleAction = (action, id, ...args) => {
    switch (action) {
      case reportActions.FIELD_UPDATE: {
        const [type, attrs] = args;
        handleFieldUpdate(id, type, attrs);
        break;
      }
      case reportActions.NAV_CLICK: {
        scrollToSection(id, scale);
        break;
      }
      case reportActions.EDIT: {
        updateSelectedImages([id]);
        navigate(routerPathEnum.IMAGE_EDITOR);
        break;
      }
      case reportActions.EDIT_NAME: {
        const [name] = args;
        Report.updatePayloadImageName(id, name);
        break;
      }
      case reportActions.DELETE: {
        Report.deleteImageById(id);
        break;
      }
      default:
        console.warn(`Unknown action: ${action}`);
    }
  };

  useEffect(() => {
    if (id === reportInfo?.options?.id) return;
    setActiveReportById(id);
  }, [id]);

  useEffect(() => {
    const startDocument = async () => {
      let {
        company_logo: logoURL,
        options: { hasCompanyLogo },
      } = activeReportItem;

      if (hasCompanyLogo) {
        const isValidImage = await checkImageURL(logoURL);
        if (!isValidImage) {
          logoURL = null; // Reset logoURL if the image is invalid
        }
      }

      loadReport({ ...activeReportItem, company_logo: logoURL });
    };
    if (activeReportItem?.options?.id) {
      startDocument();
    }
  }, [activeReportItem]);

  useEffect(() => {
    Report.setOrganization(currentOrganization);
    Report.setTemperatureUnits(temperatureUnits);
  }, [currentOrganization, temperatureUnits]);

  useEffect(() => {
    return () => {
      // when page unmounts, save report
      Report.save();
    };
  }, []);

  const Components = (props = {}) => {
    const { contentId } = props;

    const ComponentMap = {
      coverPage: CoverPage,
      sectionTitle: TitleSection,
      assetInfo: AssetInfo,
      imageIR: Image,
      imageVL: Image,
      markerInfo: MarkerInfo,
      imageInfo: ImageInfo,
      userNotes: UserNotes,
      photonotes: Photonotes,
    };

    const SelectedComponent = ComponentMap[contentId];
    return SelectedComponent ? <SelectedComponent {...props} /> : null;
  };

  const contentSections = useMemo(() => {
    if (Object.keys(template).length === 0) return [];
    return buildFromTemplate(sectionOrder, Components, template);
  }, [sectionOrder, JSON.stringify(template.layout)]);

  return (
    <>
      <LoadingOverlay open={isLoading} overlayStyle={{ zIndex: '100' }} />
      <AlertSnackBar alert={snackbar} setAlert={setSnackbar} />
      <TemplateSettings
        sectionData={sectionOrder}
        template={Report.getTemplate()}
        setTemplate={(newTemplate) => Report.setTemplate(newTemplate)}
        open={modalOpen}
        setOpen={setModalOpen}
        onClose={() => {}}
      />
      <div id="reportDetail" style={styles.container}>
        <SecondaryNavigation
          metadata={metadata}
          sectionOrder={sectionOrder}
          sectionData={images}
          template={template}
          onFinalize={handleFinalizeReport}
          openTemplateEditor={() => setModalOpen(true)}
        />
        <div
          style={{
            ...styles.main,
            visibility: isLoading ? 'hidden' : 'visible',
          }}
        >
          <ReportContext.Provider
            value={{
              reportInfo,
              images,
              handleAction,
            }}
          >
            <Sidebar />
            <Document
              sections={contentSections}
              onScale={setScale}
              metadata={metadata}
              config={{
                layout: 'a4',
                differentFirstPage: true,
                margin: 0,
              }}
            />
          </ReportContext.Provider>
        </div>
      </div>
    </>
  );
}

const styles = {
  container: {
    backgroundColor: 'white',
    overflowY: 'hidden',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    gap: 0,
  },

  main: {
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
  },
  row: {
    display: 'flex',
    gap: '1.5em',
  },
};
