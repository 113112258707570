/**
 * Retrieves and parses data from localStorage for the specified key.
 *
 * @param {string} key - The key to retrieve the data from.
 * @returns {object} The parsed data or an empty object if no data exists or an error occurs.
 */
export function getFromLocalStorage(key) {
  try {
    const data = JSON.parse(localStorage.getItem(key));
    return data ?? {};
  } catch (error) {
    console.error(`Error getting data from localStorage for key: ${key}`, error);
    return {};
  }
}

/**
 * Stores data in localStorage under the specified key.
 *
 * @param {string} key - The key to store the data under.
 * @param {object} data - The data to be stored, which will be stringified.
 * @returns {boolean} True if the data is successfully stored, false if an error occurs.
 */
export function setToLocalStorage(key, data) {
  try {
    if (key == null) {
      console.error(`Attempted to set undefined data for key: ${key}`);
      return false;
    }
    localStorage.setItem(key, JSON.stringify(data));
    return true;
  } catch (error) {
    console.error(`Error setting data to localStorage for key: ${key}`, error);
    return false;
  }
}

/**
 * Updates the localStorage entry for the given key by merging it with the provided modified keys.
 *
 * @param {string} key - The localStorage key to update.
 * @param {Object} [modifiedKeys={}] - An object containing key-value pairs to merge into the existing localStorage data.
 * @returns {boolean} - Returns true if the update is successful, or false if validation fails or storage update fails.
 */
export function updateLocalStorage(key, modifiedKeys = {}) {
  if (!areValidParams(key, modifiedKeys)) return false;

  const storedData = getFromLocalStorage(key) || {};
  const nextData = { ...storedData, ...modifiedKeys };
  const success = setToLocalStorage(key, nextData);

  if (!success) {
    console.error(`Failed to update localStorage for key: ${key}`);
    return false;
  }

  return true;
}

/**
 * Removes the specified item from localStorage.
 *
 * @param {string} key - The key of the item to remove.
 * @returns {boolean} True if the item is successfully removed, false if an error occurs.
 */
export function deleteFromLocalStorage(key) {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    console.error(`Error removing data from localStorage for key: ${key}`, error);
    return false;
  }
}

/**
 * Clears all data from local and session storage.
 *
 * @returns {boolean} True if successful, false if an error occurs.
 */
export function clearBrowserStorage() {
  try {
    localStorage.clear();
    sessionStorage.clear();
    return true;
  } catch (error) {
    console.error('Error clearing localStorage', error);
    return false;
  }
}

/**
 * Validates if the provided key and value are acceptable.
 *
 * @param {string} key - The key to check (required).
 * @param {object} value - The value that must be a non-null object.
 * @returns {boolean} True if the parameters are valid, otherwise false.
 * @throws {Warning} Logs a warning if parameters are invalid.
 */
function areValidParams(key, value) {
  const isValid = key && typeof value === 'object' && value !== null;

  if (!isValid) {
    console.warn('Invalid arguments: key is required and value must be a non-null object.');
  }

  return isValid;
}

/**
 * Retrieves and parses data from sessionStorage for the specified key.
 *
 * @param {string} key - The key to retrieve the data from.
 * @returns {object} The parsed data or an empty object if no data exists or an error occurs.
 */
export function getFromSessionStorage(key) {
  try {
    const data = JSON.parse(sessionStorage.getItem(key));
    return data ?? {};
  } catch (error) {
    console.error(`Error getting data from sessionStorage for key: ${key}`, error);
    return {};
  }
}

/**
 * Stores data in sessionStorage under the specified key.
 *
 * @param {string} key - The key to store the data under.
 * @param {object} data - The data to be stored, which will be stringified.
 * @returns {boolean} True if the data is successfully stored, false if an error occurs.
 */
export function updateToSessionStorage(key, data) {
  try {
    if (key == null) {
      console.error(`Attempted to set undefined data for key: ${key}`);
      return false;
    }
    sessionStorage.setItem(key, JSON.stringify(data));
    return true;
  } catch (error) {
    console.error(`Error setting data to sessionStorage for key: ${key}`, error);
    return false;
  }
}
