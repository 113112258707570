import { useState } from 'react';
import {
  deleteFromImages,
  fetchAllImageResults,
  fetchImagePreviews,
  fetchImageResults,
  fetchSidebarData,
} from '../../../apis/imageAPI';
import { sessionKeyStoreEnum } from '../enums/sessionKeyStoreEnum';
import { baselineStoreEnum } from '../enums';

const keysToExclude = [sessionKeyStoreEnum.PREVIEW_PAGE_COUNT, sessionKeyStoreEnum.SIDEBAR_DATES];
const keysToCheck = Object.values(sessionKeyStoreEnum).filter(
  (key) => !keysToExclude.includes(key),
);

export default function useImages(baselineManager, updateBaselineStore) {
  const [imageQueryResults, setimageQueryResults] = useState({});
  const [imagePreviews, setImagePreviews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSidebarData = async () => {
    const skipCheck = true;
    const store = baselineManager.getData();
    const { currentOrganization, imageType } = store;

    const sidebarDates = await fetchSidebarData(currentOrganization, imageType);

    const { SELECTED_YEAR, ACTIVE_QUERY, SIDEBAR_DATES } = sessionKeyStoreEnum;

    updateBaselineStore(
      {
        [SELECTED_YEAR]: store[SELECTED_YEAR] ?? sidebarDates[0]?.name,
        [ACTIVE_QUERY]: store[ACTIVE_QUERY] ?? sidebarDates[0]?.query,
        [SIDEBAR_DATES]: sidebarDates,
      },
      skipCheck,
    );
    return sidebarDates;
  };

  const getImageResults = async () => {
    const skipCheck = true;

    const store = baselineManager.getData();
    const queryResults = await fetchImageResults(store);

    updateBaselineStore(
      {
        [sessionKeyStoreEnum.PREVIEW_PAGE_COUNT]: queryResults.page_count ?? 1,
      },
      skipCheck,
    );

    setimageQueryResults(queryResults);

    return queryResults;
  };

  const getImagePreviews = async (queryResults) => {
    const skipCheck = true;
    const store = baselineManager.getData();
    queryResults = queryResults ?? imageQueryResults ?? {};

    setIsLoading(true);

    const images = await fetchImagePreviews(queryResults, store.currentOrganization);
    setImagePreviews(images);
    setIsLoading(false);

    if (images.length === 0) {
      // reset page count if not images found
      updateBaselineStore(
        {
          [sessionKeyStoreEnum.PREVIEW_PAGE_INDEX]: 0,
        },
        skipCheck,
      );
    }

    return images;
  };

  const refreshImages = async () => {
    await getSidebarData();
    const queryResults = await getImageResults();
    await getImagePreviews(queryResults);
  };

  const updateSelectedImages = (selectedImageIds = []) => {
    // Convert the array to a Set to enforce uniqueness
    const uniqueSelectedImageIds = Array.from(new Set(selectedImageIds));

    // Store unique IDs in session storage and update state
    updateBaselineStore({ [baselineStoreEnum.SELECTED_IMAGES]: uniqueSelectedImageIds });
  };

  const addSelectedImages = (imageIds = []) => {
    const { selectedImageIds = [] } = baselineManager.getData();
    // Convert to Set for efficient toggling
    const nextSelectedImageIds = new Set(selectedImageIds);

    // Ensure imageIds is always an array (for both single and multiple imageIds)
    const idsToToggle = Array.isArray(imageIds) ? imageIds : [imageIds];

    // Toggle each imageId in the Set
    idsToToggle.forEach((imageId) => {
      nextSelectedImageIds.has(imageId)
        ? nextSelectedImageIds.delete(imageId)
        : nextSelectedImageIds.add(imageId);
    });

    // Update with unique IDs
    updateSelectedImages([...nextSelectedImageIds]); // Convert Set to Array
  };

  const selectAllImages = async () => {
    const store = baselineManager.getData();
    const { results = [] } = await fetchAllImageResults(store);

    // Create a set from the selected imageIds and add new results directly
    const selectedImageIds = store?.selectedImageIds ?? [];
    const uniqueSelectedImages = new Set(selectedImageIds);
    results.forEach((id) => uniqueSelectedImages.add(id));

    updateSelectedImages([...uniqueSelectedImages]);
  };

  const refreshImagesIfQueryChanged = async (modifiedKeys = {}) => {
    // Check if any of the keysToCheck exist in the modifiedKeys object
    if (keysToCheck.some((key) => key in modifiedKeys)) {
      await refreshImages();
    }
  };

  const clearSelectedImages = () => {
    updateSelectedImages([]);
  };

  const deleteSelectedImages = async (selectedImageIds = []) => {
    const store = baselineManager.getData();

    await deleteFromImages(selectedImageIds, store.currentOrganization);

    // Filter out the deleted images
    const modifiedImages = imagePreviews.filter(
      (image) => !selectedImageIds.includes(image.image_id),
    );

    setImagePreviews(modifiedImages);
    clearSelectedImages();
    getSidebarData();
  };

  return {
    imageQueryResults,
    imagePreviews,
    isLoading,
    getSidebarData,
    getImageResults,
    refreshImages,
    updateSelectedImages, // set new list of images
    addSelectedImages, // append to list of images
    selectAllImages,
    clearSelectedImages,
    deleteSelectedImages,
    refreshImagesIfQueryChanged,
  };
}
