import React, { useEffect, useState } from 'react';

// state management
import { useBaseline } from '../../context';

// utilities
import { handleImageLoad, handleImageSave } from './utilities';

// irviewer
import { IRViewerManager, IRViewer, handleImageExport, eventEditorTypes } from '@fluke/irviewer';

// components
import { Carousel, EditorToolbar, PanelImageInfo, SidebarEditor } from './components';
import { AddImagesToReport, SaveImagesPrompt } from './components/Modals';

const DEFAULT_ASSET_INFO = { id: 0, assetTagId: '', name: '', description: '' };

export default function ImageEditor() {
  const [irviewerManager] = useState(() => new IRViewerManager());

  const {
    baselineImages: { selectedImageIds = [] },
    baselineStore: { temperatureUnits = 'F', currentOrganization },
  } = useBaseline();

  const [settings, setSettings] = useState({});
  const [imageState, setImageState] = useState({});
  const [firstImage, setFirstImage] = useState({});
  const [imageMetadata, setImageMetadata] = useState({}); // contains asset and sort info
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    irviewerManager.setSettings({ temperatureUnits });
  }, [temperatureUnits]);

  const fieldMap = {
    assetTagId: { section: 'asset_info', field: 'assetTagId' },
    imageName: { section: 'sort_info', field: 'origin_path' },
  };

  const updateImageMetadata = (key, value) => {
    const firstImageId = imageState?.selectedImageIds?.[0];
    if (!firstImageId || !fieldMap[key]) return;

    const { section, field } = fieldMap[key];

    setImageMetadata((prev) => ({
      ...prev,
      [firstImageId]: {
        ...prev[firstImageId],
        [section]: {
          ...(prev[firstImageId]?.[section] || {}),
          [field]: value,
        },
      },
    }));
  };

  const imageActions = {
    save: async () =>
      await handleImageSave(imageState.images, imageMetadata, currentOrganization, 200),
    export: async () => await handleImageExport(imageState.images),
    addToReport: () => setIsModalOpen(true),
    revert: () => {}, // reserved
  };

  const handleIRViewer = {
    vitals: (vitals = {}) => {
      setImageState({
        images: vitals.images,
        order: vitals.imageList,
        selectedImageIds: vitals.selectedImageIds,
      });
    },

    settings: (settings = {}) => {
      setSettings(settings);
    },
  };

  const fetchImages = () => {
    const loadImageById = async (id, currentOrganization) => {
      const {
        file,
        sort_info = {},
        asset_field: assetTagId = '',
      } = await handleImageLoad(id, currentOrganization);

      if (file) {
        setImageMetadata((prev) => ({
          ...prev,
          [id]: {
            sort_info,
            asset_info: { ...DEFAULT_ASSET_INFO, assetTagId },
          },
        }));

        irviewerManager.registerImage(id, file);
      }
    };

    if (selectedImageIds.length > 0) {
      for (const id of selectedImageIds) {
        loadImageById(id, currentOrganization);
      }
    }
  };

  const handleIRViewerMount = () => {
    // Initialize the canvas manager and load the images
    irviewerManager.on(eventEditorTypes.VITALS, handleIRViewer.vitals);
    irviewerManager.on(eventEditorTypes.SETTINGS, handleIRViewer.settings);
    irviewerManager.on(eventEditorTypes.FIRST_IMAGE, setFirstImage);

    fetchImages();
  };

  useEffect(() => {
    if (irviewerManager.renderer) {
      fetchImages();
    }
  }, [selectedImageIds]);

  return (
    <div style={styles.container}>
      <SaveImagesPrompt onAccept={imageActions.save} />
      <AddImagesToReport open={isModalOpen} setOpen={setIsModalOpen} imageState={imageState} />
      <div id="ireditor" style={styles.editorContainer}>
        <Carousel
          imageState={imageState}
          imageMetadata={imageMetadata}
          onChange={(selectedImageIds) => irviewerManager.setBatchSelection(selectedImageIds)}
        />

        <div style={styles.editor}>
          <EditorToolbar
            settings={settings}
            setSettings={(next) => irviewerManager.setSettings(next)}
          />

          <IRViewer
            manager={irviewerManager}
            onMount={handleIRViewerMount}
            style={styles.irviewer}
            showColorbar={true}
            showLevelSpan={true}
          />

          <div style={{ maxWidth: 640, width: '100%' }}>
            <PanelImageInfo
              firstImage={firstImage}
              settings={settings}
              setSettings={(next) => irviewerManager.setSettings(next)}
            />
          </div>
        </div>
        <div style={styles.sidebar}>
          <SidebarEditor
            settings={settings}
            image={firstImage}
            metadata={imageMetadata?.[imageState?.selectedImageIds?.[0]]}
            setSettings={(next) => irviewerManager.setSettings(next)}
            updateImageMetadata={updateImageMetadata}
            onExport={imageActions.export}
            onAddToReport={imageActions.addToReport}
            onRevert={imageActions.revert}
          />
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    colorScheme: 'dark',
    height: '100vh',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    color: '#fff',
    backgroundColor: 'rgb(18, 18, 18)',
  },
  editorContainer: {
    height: '100%',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
  },
  editor: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    padding: '8px',
    overflowY: 'auto',
    alignItems: 'center',
  },
  irviewer: {
    colorbar: {
      color: 'white',
      fontFamily: 'Arial',
    },
  },
  sidebar: {
    overflow: 'auto',
    width: '270px',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'rgba(255, 255, 255,0.05)',
  },
};
